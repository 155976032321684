import { connect } from 'react-redux'
import {MessageDetailsComponent} from './component'
import { setMessageUnreadRequest } from 'application/redux/actions/common/messages'


const mapStateToProps = (state) => {
    return {
        currentUser: state.auth.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSetMessageUnread: (damageId, messageId) => {
            dispatch(setMessageUnreadRequest(damageId, messageId))
        }
    }
}

export const MessageDetails = connect(
    mapStateToProps,
    mapDispatchToProps
)(MessageDetailsComponent)