
import React from 'react'
import { UI_SETTINGS_DATA_TYPE } from 'application/constants/ui_settings'
import { EditDashboardConfiguration } from '../setting_editor_components/edit_dashboard_configuration'
import { SelectUser } from '../setting_editor_components/select_user'
import { SelectCategory } from '../setting_editor_components/select_category'
import { EditKickstartBranchAssignees } from 'application/components/pages/internal/settings/ui_settings/setting_editor_components/edit_kickstart_branch_assignees'
import { EditKickstartDefaultLawfirms } from 'application/components/pages/internal/settings/ui_settings/setting_editor_components/edit_kickstart_default_lawfirms'

export const SettingsChildren = ({
    definition,
    updateSettings,
    getSetting
}) => {
    switch(definition.dataType){
    case UI_SETTINGS_DATA_TYPE.DASHBOARD_CONFIGURATION:
        return <EditDashboardConfiguration
            definition={definition}
            updateSettings={updateSettings}
            getSetting={getSetting}
        />
    case UI_SETTINGS_DATA_TYPE.USER_SELECT:
        return <SelectUser
            definition={definition}
            updateSettings={updateSettings}
            getSetting={getSetting}
        />
    case UI_SETTINGS_DATA_TYPE.CATEGORY_SELECT:
        return <SelectCategory
            definition={definition}
            updateSettings={updateSettings}
            getSetting={getSetting}
        />
    case UI_SETTINGS_DATA_TYPE.KICKSTART_BRANCH_ASSIGNEES:
        return <EditKickstartBranchAssignees
            definition={definition}
            updateSettings={updateSettings}
            getSetting={getSetting}
        />
    case UI_SETTINGS_DATA_TYPE.KICKSTART_DEFAULT_LAWFIRMS:
        return <EditKickstartDefaultLawfirms
            definition={definition}
            updateSettings={updateSettings}
            getSetting={getSetting}
        />
    default: 
        return null
    }
}