import React from 'react'
import {
    StyledDamageManagerBranchInfo
} from './fragments'
import { FontIcon } from '@ec/ui-controls-react'
import { useTheme } from 'styled-components'

export const DamageManagerBranchInfo = ({
    branchInfo
}) => {
    const theme = useTheme()
    if(!branchInfo) {
        return null
    }

    return <StyledDamageManagerBranchInfo>
        <FontIcon
            icon='place'
            fontSize={theme.fontSize.medium18}
        /> {branchInfo.name}
    </StyledDamageManagerBranchInfo>
}