import React from 'react'
import { isPermittedInSpecificBranch } from '../is_permitted_in_specific_branch'
        
export const ForSpecificBranchPermissionsComponent = (
    {
        allBranchPermissions, 
        branchId,
        permissions,
        children , 
        allNeeded = false,
        ignoreCondition = false
    }
) => {
    const branchPermissions = allBranchPermissions?.find(p => p.branchId === branchId)?.permissions || []
    
    return <React.Fragment>
        { 
            (isPermittedInSpecificBranch(
                branchPermissions, 
                permissions, 
                allNeeded
            ) || ignoreCondition)
            && children 
        }
    </React.Fragment>
}