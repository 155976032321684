import styled from 'styled-components'

export const StyledDamageManagerBranchInfo = styled.div`
    flex: 0 0 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    font-size: ${props => props.theme.fontSize.small};
    color: ${props => props.theme.color.anthracite};
`
