import { MODULE_NAME } from 'application/constants'
import { api } from 'application/api'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* lawfirmSwitch(damageId, lawfirmName, webakteId, documentIds = []) {

    if(webakteId === MODULE_NAME.VOIGT_MODULE){
        yield resourceCall(
            api.gateway.damage.updateDamageLawfirmVoigt, 
            {
                damageId:  damageId
            },
            {
                documents: documentIds
            }
        ) 
    }else{
        yield resourceCall(
            api.gateway.damage.updateDamageLawfirmWebakte, 
            {
                damageId:  damageId
            },
            {
                name: lawfirmName,
                webakteId: webakteId
            }
        ) 
        if(documentIds.length > 0){
            for(const documentId of documentIds){
                yield resourceCall(
                    api.gateway.attachments.updateAttachment,
                    {
                        damageId,
                        attachmentId: documentId
                    },
                    {
                        isExportedToLawFirm: true
                    }
                )
            }
        }
    }
}