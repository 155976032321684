import {  StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
    page: { 
        backgroundColor: 'white',
        paddingLeft: 60,
        paddingRight: 60,
    
    },
    headline: { 
        color: 'black', 
        textAlign: 'center', 
        margin:60,
        marginTop:5,
        marginBottom: 20,
        fontSize: 20
    },
    columnsRow: {
        flexDirection: 'row', 
        marginTop: 4,
        paddingTop: 4,
    },
    row: {  
        flexDirection: 'row', 
        marginTop: 4,
        paddingTop: 4,
        justifyContent: 'space-between'
    },
    labelField: {
        width: '48%',
        fontSize: 8
    },
    valueField: {
        borderBottom: '0.5px dotted black',
        height: 10,
        paddingLeft:15,
        paddingRight: 10,
        width: '100%',
        fontSize: 6
    },
    valueTextArea: {
        border: '0.5px dotted black',
        minHeight: 100,
        padding: 5,
        width: '100%',
        fontSize: 6,
        lineHeight: 2
    },
    section: {  
        margin: 15
    },
    signatureField: {
        borderBottom:'0.5px solid black',
        height: '60px',
        justifyContent: 'center',
        label: {
            paddingTop: '4px',
            fontSize: 7
        },
        content: {
            paddingLeft: '10px',
            fontSize: 10
        }
    }
})