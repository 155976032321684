import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from 'application/components/controls/action_button'
import * as Common from 'application/components/pages/_common'
import { BranchManagementFlowchart } from 'application/components/building_blocks/branch_management_flowchart'
import { BrachnmanagementSidebars } from './sidebars'
import { SidebarPlaceholder } from './sidebars'
import { TextButton } from 'application/components/fragments/typography'
import { withTheme } from 'styled-components'
// import { PureCheckBox } from 'application/components/controls/form/check_box/pure_component'
import { LayoutAllBranches } from 'application/components/pages/_layout/layout_all_branches'
import { FlexBox } from 'application/components/fragments/flex_box'
import { ThemedPreloader} from 'application/components/controls/themed_preloader'
import { PermissionChangesInfobox } from 'application/components/controls/permission_changes_infobox'



export const BranchManagementC = withTheme(
    ({
        // onAddRole,
        onAddGroup,
        onAddBranch,
        sidebarVisible,
        helpSidebarVisible,
        onCloseAllSidebars,
        onShowHelpSidebar,
        onNodeClick,
        dataLoaded,
        theme
    }) => {
        const {t} = useTranslation()
        // const [branchesDisabled, setBranchesDisabled] = useState(false)
        const [loaded, setLoaded] = useState(false)
        
        useEffect(()=>{
            if(!loaded){
                setLoaded(true)
                onShowHelpSidebar()
            }
        }, [loaded, onShowHelpSidebar])

        return <LayoutAllBranches
            overflow='visible'
            titleSegments={['Groups & Branches']}
            headlineGeneric={t('Administration')}
            headlineSpecific={
                <React.Fragment>
                    {t('Groups & Branches')}
                    {
                        !helpSidebarVisible && <React.Fragment>
                            <Common.Spacer basis='4px' />
                            <TextButton 
                                onClick={onShowHelpSidebar} 
                                $cssFontSize={theme.fontSize.standard}
                            >
                                {t('Show help')}
                            </TextButton>
                        </React.Fragment>
                    }
                    <Common.Spacer />
                </React.Fragment>
            }
            sidebar={
                sidebarVisible 
                    ? <BrachnmanagementSidebars />
                    : <SidebarPlaceholder />
            }
            mainPaddingRight='12px'
        >
            <Common.VerticalContainer className='commonVerticalContainer'>
                <FlexBox 
                    flexShrink='0'
                    flexBasis='100px'
                    cssRules='overflow: hidden;'
                    flexDirection='column'
                >
                    <FlexBox
                        flexDirection='column'
                    >
                        <Common.PushBottom8>
                            <PermissionChangesInfobox />
                        </Common.PushBottom8>
                    </FlexBox>
                    <FlexBox>
                        {/* <ActionButton 
                        onButtonClick={onAddRole} 
                        buttonText={`+ ${t('Permission collection')}`} 
                    /> */}
                        <ActionButton 
                            onButtonClick={onAddGroup} 
                            buttonText={`+ ${t('User group')}`} 
                        /> &nbsp;
                        <ActionButton 
                            onButtonClick={onAddBranch} 
                            buttonText={`+ ${t('Branch')}`} 
                        />
                    
                        {/* <PureCheckBox 
                        label={t('Show only global permissions')} 
                        checked={branchesDisabled} 
                        onChange={
                            checked=>{
                                setBranchesDisabled(checked)
                            }
                        }  
                    /> */}
                    </FlexBox>
                  
                </FlexBox>
                <FlexBox 
                    flexShrink='0' 
                    flexBasis='calc(100% - 116px)'
                    flexDirection='column'
                >
                    {
                        dataLoaded
                            ? <BranchManagementFlowchart
                                onRemoveNode={onCloseAllSidebars}
                                onNodeClick={onNodeClick}
                                branchesDisabled={false}
                                onLoadInstance={onShowHelpSidebar}
                            />
                            : <ThemedPreloader />
                    }
                </FlexBox>
            </Common.VerticalContainer>
        </LayoutAllBranches>   
    }
)