import { takeLatest, put, select } from 'redux-saga/effects'
import { api } from 'application/api'
import { ACTIONS } from 'application/constants'
import { handleError } from 'application/redux/saga/errors'
import { populateDamage, populateExpert, populateSharedLawfirm } from 'application/redux/actions/pages/damage_manager'
import { refreshDamageManagerDamageSaga } from '../../post_processors'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { showToast } from 'application/redux/actions/notifications'
import { getBranchInfo } from 'application/redux/selectors'


function* damageManagerGetDamageSaga(action) {
    try {

        const damageId = action.damageId

        try {
            const lawfirmResponse = yield resourceCall(
                api.gateway.lawyerCommunication.getLawfirm,
                { damageId }
            )
            yield put(
                populateSharedLawfirm(true, lawfirmResponse)
            )
        } catch (e) {
            if (e.response.status === 404) {
                yield put(populateSharedLawfirm(false, null))
            } else {
                throw (e)
            }
        }


        const damageResponse = yield resourceCall(
            api.gateway.damage.getDamage,
            { damageId }
        )

        const branchId = damageResponse.branchId
        const branchInfo = yield select(getBranchInfo, branchId)

        yield put(
            populateDamage(damageResponse, branchInfo)
        )


        try {
            yield resourceCall(
                api.gateway.expert.getExpert,
                { damageId }
            )
            yield put(
                populateExpert(true)
            )
        } catch (e) {
            yield put(
                populateExpert(false)
            )
        }

    } catch (e) {
        if (e.data.status === 404) {
            yield put(
                populateDamage(null)
            )
        } else {
            yield handleError(e)
        }
    }
}

function* damageManagerUpdateState(action) {
    try {
        yield resourceCall(
            api.gateway.damage.updateDamageState,
            {
                damageId: action.damageId
            },
            {
                id: action.stateId
            }
        )
        yield refreshDamageManagerDamageSaga(action)
        yield put(
            showToast('State updaded')
        )
    } catch (e) {
        yield handleError(e)
    }
}

export function* damageManagerWatcher() {
    yield takeLatest(
        ACTIONS.DAMAGE_MANAGER_REQUEST_DAMAGE,
        damageManagerGetDamageSaga
    )
    yield takeLatest(
        ACTIONS.DAMAGE_MANAGER_UPDATE_STATUS,
        damageManagerUpdateState
    )
}