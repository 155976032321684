import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { WidgetErrorMessage } from 'application/components/widgets/_common'
import { ROUTE_PARAMETERS, ROUTES } from 'application/constants'
import { PushBottom32, PushBottom16 } from 'application/components/pages/_common'
import { TableWrapper, TableHeader, TableRowLink, TableCell } from 'application/components/fragments/table'
import { RoutedPagination } from 'application/components/building_blocks/routed_pagination'
import { TableHeaderLink } from 'application/components/controls/table_header_link'
import { FullName } from 'application/components/controls/full_name'
import { DamageTypeName } from 'application/components/name_mappings/damage_type_name'
import {DamageStatus} from 'application/components/controls/damage_status'
import { WidgetInformation } from 'application/components/widgets/_common'
import { Headline2 } from 'application/components/fragments/typography'
import { VacantPositionsBar } from 'application/components/charts'
import { IconButtonWithLabel } from 'application/components/controls/icon_button_with_label/component'
import styled, { withTheme } from 'styled-components'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'
import {JobNumber} from 'application/components/controls/job_number'
import {DamageLicensePlate} from 'application/components/controls/damage_licenseplate'
import { FlexBox } from 'application/components/fragments/flex_box'
import { ItemsPerPageSelection } from 'application/components/tables/common/_common'
import * as storage from 'application/storage'
import { BranchColumn } from 'application/components/tables/refactored/damages/_common/branch_column'






const StyledTotal = styled.div`
    background: transparent;
    font-size: 12px;
    font-weight: 400;
    padding: 16px 0px;
`


const ControllingVacantPositionsDamagesTableInner = (
    {
        payload, 
        loadingFailed, 
        onRefresh, 
        widgetKey, 
        onOpenDamageInvoicesDialog, 
        theme
    }
) => {
    const {t} = useTranslation()
    const tableName = widgetKey

    const {
        internalAccountLink
    } = useApplicationLinks()

    const pagination = {
        offset: ROUTE_PARAMETERS.WIDGET_CONTROLLING_DAMAGES.OFFSET,
        limit: ROUTE_PARAMETERS.WIDGET_CONTROLLING_DAMAGES.LIMIT,
        itemsPerPageOptions: [],
    }

    const itemsPerPage = storage.tableConfig.getItemsPerPage(tableName)

    if(loadingFailed){
        return <React.Fragment>
            <Headline2>{t('Damages with vacant positions')}</Headline2>
            <PushBottom16 />
            <WidgetErrorMessage>{t('data could not be loaded')}</WidgetErrorMessage>
        </React.Fragment>
    }
    
    const {damages, total} =  payload.response
    const handleRefresh = () => onRefresh(widgetKey)
    const sorting = {
        sortParamKey: ROUTE_PARAMETERS.WIDGET_CONTROLLING_DAMAGES.ORDER_BY.KEY,
        directionParamKey: ROUTE_PARAMETERS.WIDGET_CONTROLLING_DAMAGES.ORDER_BY.DIRECTION,
        defaultSortParamValue: ROUTE_PARAMETERS.WIDGET_CONTROLLING_DAMAGES.ORDER_BY.VALUES.VACANT_POSITIONS,
        defaultDirectionParamValue: ROUTE_PARAMETERS.SORT_DIRECTIONS.DESCENDING
    }



    return <React.Fragment>
        <Headline2>{t('Damages with vacant positions')}</Headline2>
        <PushBottom16 />
        <TableWrapper>
            <TableHeader>
                <TableCell cssWidth='12%'>{t('Job number')}</TableCell>
                <TableCell cssWidth='12%'>
                    <TableHeaderLink {...sorting}
                        sortParamValue={ROUTE_PARAMETERS.WIDGET_CONTROLLING_DAMAGES.ORDER_BY.VALUES.LICENSE_PLATE}
                        onLinkClick={handleRefresh}
                    >{t('License plate')}</TableHeaderLink>
                </TableCell>
                <TableCell cssWidth='11%'>{t('Damage type')}</TableCell>
                <TableCell cssWidth='12%'>
                    <TableHeaderLink {...sorting}
                        sortParamValue={ROUTE_PARAMETERS.WIDGET_CONTROLLING_DAMAGES.ORDER_BY.VALUES.STATUS}
                        onLinkClick={handleRefresh}
                    >{t('Status')}</TableHeaderLink>
                </TableCell>
                <TableCell cssWidth='13%'>{`${t('Assignee')} / ${t('Customer')}`}</TableCell>
                {
                    damages.some( damage => damage.branch ) &&  <TableCell cssWidth='12%'>
                        {t('Branch')}
                    </TableCell>
                }
                <TableCell cssWidth='16%'>
                    <TableHeaderLink {...sorting}
                        sortParamValue={ROUTE_PARAMETERS.WIDGET_CONTROLLING_DAMAGES.ORDER_BY.VALUES.VACANT_POSITIONS}
                        onLinkClick={handleRefresh}
                    >{t('Vacant positions')}</TableHeaderLink>
                </TableCell>
                <TableCell cssWidth='11%'></TableCell>
            </TableHeader>
            {
                damages.map((r, i) => {
                    return  <TableRowLink
                        key={i} 
                        to={
                            internalAccountLink(
                                `${ROUTES.DAMAGE_MANAGER_BASE}${r.id}`
                            )
                        }
                    >                            
                        <TableCell cssWidth='12%'>
                            <JobNumber number={r.jobNumber} />
                        </TableCell>
                        <TableCell cssWidth='12%'><DamageLicensePlate damage={r} /></TableCell>
                        <TableCell cssWidth='11%'><DamageTypeName damageType={r.damageType} /></TableCell>
                        <TableCell cssWidth='12%'><DamageStatus status={r.state} /></TableCell>
                        <TableCell cssWidth='13%'>
                            <FlexBox flexDirection='column' >
                                <FlexBox><FullName person={r.assignee} />  </FlexBox>                          
                                <FlexBox><FullName person={r.vehicleKeeper} /></FlexBox>
                            </FlexBox>
 
                        </TableCell>
                        {
                            damages.some( damage => damage.branch ) && <TableCell cssWidth='12%'>
                                {
                                    r?.branch && <BranchColumn damage={r} />
                                }
                            </TableCell>
                        }

                        <TableCell cssWidth='16%'>
                            <VacantPositionsBar unpaidTotal={r.unpaidTotal} paidTotal={r.paidTotal} />
                        </TableCell>
                        <TableCell cssWidth='11%'>
                            <IconButtonWithLabel 
                                fontSize={theme.fontSize.small}
                                iconKey='keyboard_arrow_right'
                                underlined={true} 
                                cssPadding='0'
                                onButtonClick={(e)=>{e.preventDefault();onOpenDamageInvoicesDialog(r.id, r.vehicle.licensePlate)}} 
                                label={t('Show invoices')} 
                            />
                        </TableCell>
                    </TableRowLink>
                })
            }
            {
                total === 0 && <WidgetInformation>{t('No damages found')}</WidgetInformation>
            }
        </TableWrapper>
        <PushBottom32 />
        <FlexBox>
            <FlexBox>
                <RoutedPagination
                    onPaginationChange={handleRefresh}
                    totalSize={total}
                    itemsPerPage={itemsPerPage}
                    parameterNames={pagination}
                />
            </FlexBox>
            <FlexBox 
                height={'inherit'} 
                justifyContent='center'
                alignItems='center'
            >
                <StyledTotal>{
                    `${t('Total')}: `
                }
                <strong>{total}</strong>
                </StyledTotal> 
            </FlexBox>
            <FlexBox justifyContent='flex-end' paddingRight='32px'>
                <ItemsPerPageSelection 
                    tableName={tableName}
                    offsetKey={pagination.offset}
                    limitKey={pagination.limit}
                    selectedOptionValue={itemsPerPage}
                    onOptionClick={handleRefresh}
                />
            </FlexBox>
        </FlexBox>
    </React.Fragment>
}

ControllingVacantPositionsDamagesTableInner.propTypes = {
    widgetKey: PropTypes.string.isRequired,
    payload: PropTypes.object,
    loadingFailed: PropTypes.bool.isRequired
}

export const ControllingVacantPositionsDamagesTableComponent = withTheme(
    ControllingVacantPositionsDamagesTableInner
)