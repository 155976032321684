import { exists } from 'application/common/data_helpers'

export const getAccountLogo = state => state.accountLogo
export const getCurrentUserId = state => state.auth.user.id
export const thumbnailLoaded = (state, thumbnailId) => state.attachments.thumbnails.filter(t => t.id === thumbnailId && t.isLoaded).length > 0
export const fullAttachmentLoaded = (state, attachmentId) => state.attachments.fullAttachments.filter(t => t.id === attachmentId && t.isLoaded).length > 0
export const attachmentMetaLoaded = (state, metaId) => state.attachments.meta.filter(m => m.id === metaId && m.isLoaded).length > 0
export const damageManagerSelectedTask = state => state.pages.damageManager.tasks.selectedTask
export const getPin = (state) => state.sharedResource.pin || ''
export const getSharedResourceId = (state) => state.sharedResource.id
export const getSharedResourceType = (state) => state.sharedResource.resourceType
export const workflowPanelSelectedTask = state => state.pages.workflowPanel.tasks.selectedTask
export const loggedIn = state => state.auth.loggedIn
export const getAccessToken = state => state.oidc.user.access_token
export const getAccounts = state => state.auth.accounts
export const getAccountsTotal = state => state.auth.accountsTotal
export const accountsLoaded = state => state.auth.accountsLoaded
export const getCurrentAccount = state => state.auth.currentAccount
export const getCurrentAccountId = state => state.auth.currentAccountId
export const isSharedResource = state => exists(state.sharedResource.id)
export const getCurrentBranchId = state => state.auth.currentBranch !== null ?
    state.auth.currentBranch.id :
    state.sharedResource.id !== null ?
        state.sharedResource?.data?.branch?.id : null
export const getCurrentBranchInfo = state => ({currentBranch: state.auth.currentBranch, currentBranchLoaded: state.auth.currentBranchLoaded, currentBranchLoading: state.auth.currentBranchLoading})
export const getUserGroupById = (state, groupId) => state.common.userGroups.find(g => g.id === groupId)
export const getCurrentDamage = state => state.pages.damageManager.shared.damage
export const getCurrentDamageWorkflowPanel = state => state.pages.workflowPanel.shared.damage
export const getAllPermissions = state => state.auth.allPermissions
export const getModules = state => state.modules
export const getFeatureFlags = state => state.featureFlags
export const getDekraFlag = state => {return state.auth.currentAccount?.canCommissionDekra || state.sharedResource?.data?.account?.canCommissionDekra}
export const getContractors = state => state.contractors
export const getComprehensiveDamageTypes = state => ({
    comprehensiveDamageTypes: state.common.comprehensiveDamageTypes,
    comprehensiveDamageTypesLoaded: state.common.comprehensiveDamageTypesLoaded,
    comprehensiveDamageTypesLoading: state.common.comprehensiveDamageTypesLoading,
})

export const getAccessibleBranches = state => ({
    accessibleBranches: state.common.accessibleBranches,
    accessibleBranchesLoaded: state.common.accessibleBranchesLoaded
})

export const getAllBranches = state => ({
    allBranches: state.common.allBranches
})

export const getBranchInfo = (state, branchId) => {
    return state.common.allBranches.find(branch => branch.id === branchId)
}

export const getAccountId = state => {
    const {resourceType, resourceIsLoaded, tokenList} = state.sharedResource
    if(exists(resourceType) && resourceIsLoaded){
        return tokenList[0].tokenPayload.account
    }
    if(exists(state.auth.currentAccount)){
        return state.auth.currentAccount.id
    }
    return null
}
export const getUiSettings = state => state.uiSettings

export const getBranchContractorIds = (branchId) => state => {
    const contractorsList = state.contractors.branchContractorsList
        .find(branch => branch.id === branchId)

    if(!contractorsList){
        return []
    }

    return contractorsList
        .allowedContractors
        .map(relation => relation.id)
}

export const getContractorsListByIds = (contractorIds, contractorType = null) => state => {
    return state
        .contractors
        .contractorsList
        .filter(c => contractorIds.includes(c.id))
        .filter(c => contractorType === null ? true : c.type === contractorType)
}

export const getUpdateConstraint = (state, constraint) => {
    return state.updateScheduler.constraints.find(c => c.updateConstraint === constraint) !== undefined
        ? state.updateScheduler.constraints.find(c => c.updateConstraint === constraint).timeStamp
        : null
}

export const getRegisteredMassOperations = state => state.massOperations.registeredMassOperations




const getCategory = (state, categoryId) => {
    const categories = state.documentsByCategories.categories
    if (categories.length === 0 ) return null
    return  categories.find(
        c => c.id === categoryId
    )
}


//documents by categories
export const getDocumentsByCategory = (categoryId) => state => {
    return getCategory(state, categoryId)
}

export const getDocumentsByCategoryByYieldSelect =  (
    state,
    categoryId
) => {
    return getCategory(state, categoryId)
}


export const getDocsByCatsResourceId = (state) => (state.documentsByCategories?.resourceId || null)