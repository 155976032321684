import styled from 'styled-components'

export const StyledDamageManagerVehicleHeader = styled.div`
    flex: 0 0 72px;
    display: flex;
    flex-direction: column;
`

export const StyledDamageManagerVehicleGradient = styled.div`
    display: flex;
    flex: 0 0 50px;
    background: rgb(14,145,193);
    background: linear-gradient(90deg, rgba(14,145,193,1) 0%, rgba(40,72,152,1) 100%);
    position: relative;
    justify-content: center;

    & > div {
        position: absolute;
        top: 35px;
        box-shadow: 0px 0px 15px 0px #828282;
        outline: 2px solid white;
    }
`

export const StyledDamageManagerVehicleBody = styled.div`
    flex: 0 0 16px;
    display: flex;
    flex-direction: column;

    & .makeModelContainer{
        font-size: ${props => props.theme.fontSize.small};
    }
`
