import { STORAGE, WIDGET_CONTEXTS } from 'application/constants'

import { layoutGlobalControllingDamagesBarChart, widgetsGlobalControllingDamages } from 'application/widget_configuration/contexts/widgets_controlling_global_damages'
import { layoutBranchSpecificControllingStatistics, widgetsBranchSpecificControllingStatistics } from './contexts/widgets_branch_specific_controlling_statistics'
import { layoutBranchSpecificControllingVacantPositions, widgetsBranchSpecificControllingVacantPositions } from './contexts/widgets_branch_specific_controlling_vacant_positions'
import { layoutBranchSpecificDashboard, widgetsBranchSpecificDashboard } from './contexts/widgets_branch_specific_dashboard'
import { layoutGlobalControllingStatistics, widgetsGlobalControllingStatistics } from './contexts/widgets_controlling_global_statistics'
import { layoutControllingStatistics, widgetsControllingStatistics } from './contexts/widgets_controlling_statistics'
import { layoutControllingVacantPositions, widgetsControllingVacantPositions } from './contexts/widgets_controlling_vacant_positions'
import { widgetsDamageManagerAnwaltKommunikation, widgetsDamageManagerAnwaltKommunikationInvoices } from './contexts/widgets_damage_manager_anwalt_kommunikation'
import { widgetsDamageManagerInvoices } from './contexts/widgets_damage_manager_invoices'
import { layoutDamageManagerOverview, widgetsDamageManagerOverview } from './contexts/widgets_damage_manager_overview'
import { widgetsDamageManagerOverviewSidebar } from './contexts/widgets_damage_manager_overview_sidebar'
import { widgetsDamageManagerTasksSidebar } from './contexts/widgets_damage_manager_tasks_sidebar'
import { layoutDashboard, widgetsDashboard } from './contexts/widgets_dashboard'
import { layoutWorkflowPanelForm, widgetsWorkflowPanelForm } from './contexts/widgets_workflow_panel_form'
import { widgetsWorkflowPanelFormSidebar } from './contexts/widgets_workflow_panel_form_sidebar'
import { widgetsWorkflowPanelTasksSidebar } from './contexts/widgets_workflow_panel_tasks_sidebar'


export const getWidgetContextMeta = (contextName) => {
    switch (contextName) {
    case WIDGET_CONTEXTS.DASHBOARD:
        return {
            defaults: {
                layout: layoutDashboard.LARGE,
                list: widgetsDashboard,
            },
            storage: {
                layout: STORAGE.DASHBOARD_WIDGET_LAYOUT,
                list: STORAGE.DASHBOARD_WIDGET_LIST
            },
            boardConfiguration: {
                isStatic: true,
                isEditable: false,
                rowHeight: 140,
                cols: 4,
                defaultWidth: 2,
                defaultHeight: 2,
                className: 'layout'
            }
        }
    case WIDGET_CONTEXTS.BRANCH_SPECIFIC_DASHBOARD:
        return {
            defaults: {
                layout: layoutBranchSpecificDashboard.LARGE,
                list: widgetsBranchSpecificDashboard,
            },
            storage: {
                layout: STORAGE.BRANCH_SPECIFIC_DASHBOARD_WIDGET_LAYOUT,
                list: STORAGE.BRANCH_SPECIFIC_DASHBOARD_WIDGET_LIST
            },
            boardConfiguration: {
                isStatic: true,
                isEditable: false,
                rowHeight: 140,
                cols: 4,
                defaultWidth: 2,
                defaultHeight: 2,
                className: 'layout'
            }
        }
    case WIDGET_CONTEXTS.DAMAGE_MANAGER_OVERVIEW:
        return {
            defaults: {
                layout: layoutDamageManagerOverview.LARGE,
                list: widgetsDamageManagerOverview,
            },
            storage: {
                layout: STORAGE.DAMAGE_MANAGER_WIDGET_LAYOUT,
                list: STORAGE.DAMAGE_MANAGER_WIDGET_LIST
            },
            boardConfiguration: {
                isStatic: true,
                isEditable: false,
                rowHeight: 40,
                cols: 6,
                defaultWidth: 2,
                defaultHeight: 4,
                className: 'layout'
            }
        }
    case WIDGET_CONTEXTS.WORKFLOW_PANEL_FORM:
        return {
            defaults: {
                layout: layoutWorkflowPanelForm.LARGE,
                list: widgetsWorkflowPanelForm,
            },
            storage:  {
                layout: STORAGE.WORKFLOW_PANEL_FORM_LAYOUT,
                list: STORAGE.WORKFLOW_PANEL_FORM_LIST
            },
            boardConfiguration: {
                isStatic: true,
                isEditable: false,
                rowHeight: 50,
                cols: 6,
                defaultWidth: 3,
                defaultHeight: 5,
                className: 'layout'
            }
        }
    case WIDGET_CONTEXTS.CONTROLLING_VACANT_POSTIONS:
        return {
            defaults: {
                layout: layoutControllingVacantPositions.LARGE,
                list: widgetsControllingVacantPositions,
            },
            storage: {
                layout: STORAGE.CONTROLLING_VACANT_POSITIONS_WIDGET_LAYOUT,
                list: STORAGE.CONTROLLING_VACANT_POSITIONS_WIDGET_LIST
            },
            boardConfiguration: {
                isStatic: true,
                isEditable: false,
                rowHeight: 48,
                cols: 6,
                defaultWidth: 2,
                defaultHeight: 4,
                className: 'layout'
            }
        }
    case WIDGET_CONTEXTS.BRANCH_SPECIFIC_CONTROLLING_VACANT_POSTIONS:
        return {
            defaults: {
                layout: layoutBranchSpecificControllingVacantPositions.LARGE,
                list: widgetsBranchSpecificControllingVacantPositions,
            },
            storage: {
                layout: STORAGE.BRANCH_SPECIFIC_CONTROLLING_VACANT_POSITIONS_WIDGET_LAYOUT,
                list: STORAGE.BRANCH_SPECIFIC_CONTROLLING_VACANT_POSITIONS_WIDGET_LIST
            },
            boardConfiguration: {
                isStatic: true,
                isEditable: false,
                rowHeight: 48,
                cols: 6,
                defaultWidth: 2,
                defaultHeight: 4,
                className: 'layout'
            }
        }        
    case WIDGET_CONTEXTS.CONTROLLING_STATISTICS:
        return {
            defaults: {
                layout: layoutControllingStatistics.LARGE,
                list: widgetsControllingStatistics,
            },
            storage: {
                layout: STORAGE.CONTROLLING_STATISTICS_WIDGET_LAYOUT,
                list: STORAGE.CONTROLLING_STATISTICS_WIDGET_LIST
            },
            boardConfiguration: {
                isStatic: true,
                isEditable: false,
                rowHeight: 150,
                cols: 6,
                defaultWidth: 2,
                defaultHeight: 8,
                className: 'layout'
            }
        }
    case WIDGET_CONTEXTS.CONTROLLING_GLOBAL_STATISTICS:
        return {
            defaults: {
                layout: layoutGlobalControllingStatistics.LARGE,
                list: widgetsGlobalControllingStatistics,
            },
            storage: {
                layout: STORAGE.CONTROLLING_GLOBAL_STATISTICS_WIDGET_LAYOUT,
                list: STORAGE.CONTROLLING_GLOBAL_STATISTICS_WIDGET_LIST
            },
            boardConfiguration: {}
        }
    case WIDGET_CONTEXTS.CONTROLLING_GLOBAL_DAMAGES:
        return {
            defaults: {
                layout: layoutGlobalControllingDamagesBarChart.LARGE,
                list: widgetsGlobalControllingDamages,
            },
            storage: {
                layout: STORAGE.CONTROLLING_GLOBAL_DAMAGES_WIDGET_LAYOUT,
                list: STORAGE.CONTROLLING_GLOBAL_DAMAGES_WIDGET_LIST
            },
            boardConfiguration: {}
        }
    case WIDGET_CONTEXTS.BRANCH_SPECIFIC_CONTROLLING_STATISTICS:
        return {
            defaults: {
                layout: layoutBranchSpecificControllingStatistics.LARGE,
                list: widgetsBranchSpecificControllingStatistics,
            },
            storage: {
                layout: STORAGE.BRANCH_SPECIFIC_CONTROLLING_STATISTICS_WIDGET_LAYOUT,
                list: STORAGE.BRANCH_SPECIFIC_CONTROLLING_STATISTICS_WIDGET_LIST
            },
            boardConfiguration: {
                isStatic: true,
                isEditable: false,
                rowHeight: 150,
                cols: 6,
                defaultWidth: 2,
                defaultHeight: 8,
                className: 'layout'
            }
        }
    case WIDGET_CONTEXTS.DAMAGE_MANAGER_OVERVIEW_SIDEBAR:
        return {
            defaults: {
                layout: [],
                list: widgetsDamageManagerOverviewSidebar,
            },
            storage: null,
            boardConfiguration: {
                isStatic: true,
                isEditable: true,
                rowHeight: 40,
                cols: 6,
                defaultWidth: 2,
                defaultHeight: 4,
                className: 'layout'
            }
        }        
    case WIDGET_CONTEXTS.DAMAGE_MANAGER_TASKS_SIDEBAR:
        return {
            defaults: {
                layout: [],
                list: widgetsDamageManagerTasksSidebar,
            },
            storage: null,
            boardConfiguration: {}
        }    
    case WIDGET_CONTEXTS.WORKFLOW_PANEL_TASKS_SIDEBAR:
        return {
            defaults: {
                layout: [],
                list: widgetsWorkflowPanelTasksSidebar,
            },
            storage: null,
            boardConfiguration: {}
        }
    case WIDGET_CONTEXTS.WORKFLOW_PANEL_FORM_SIDEBAR:
        return {
            defaults: {
                layout: [],
                list: widgetsWorkflowPanelFormSidebar,
            },
            storage: null,
            boardConfiguration: {}
        }   
    case WIDGET_CONTEXTS.DAMAGE_MANAGER_ANWALT_KOMMUNIKATION:
        return {
            defaults: {
                layout: [],
                list: widgetsDamageManagerAnwaltKommunikation,
            },
            storage: null,
            boardConfiguration: {}
        }   
    case WIDGET_CONTEXTS.DAMAGE_MANAGER_ANWALT_KOMMUNIKATION_INVOICES:
        return {
            defaults: {
                layout: [],
                list: widgetsDamageManagerAnwaltKommunikationInvoices,
            },
            storage: null,
            boardConfiguration: {}
        }    
    case WIDGET_CONTEXTS.DAMAGE_MANAGER_INVOICES:
        return {
            defaults: {
                layout: [],
                list: widgetsDamageManagerInvoices,
            },
            storage: null,
            boardConfiguration: {}
        }   
        
    default:
        return {
            defaults: null,
            storage: null,
            boardConfiguration: {}
        }
    }
}