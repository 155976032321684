import { buildApiUrl } from 'application/api/helpers'
import { downloadFilePost } from 'application/api/helpers/download_file'

export const downloadDocumentsAsZip = (params) => (
    {
        authenticated: payload => {
            const hiddenFields = payload.documentIds.map((documentId) => ({
                key: 'documentIds',
                value: documentId
            }))
            
            const downloadUrl = buildApiUrl(`damages/${params.damageId}/zip`, false)
            const downloadFileName = payload.documentName
            downloadFilePost(downloadUrl, downloadFileName, hiddenFields)

            return null





        },
        sharedResource: payload => {
            const downloadUrl = buildApiUrl(`external/${params.sharedResourceType}/${params.sharedResourceId}/damage/zip`, false)
            const downloadFileName = payload.documentName
            downloadFilePost(downloadUrl, downloadFileName)

            return null






        },
    }
)






















