import React from 'react'
import { CheckBoxGroupWithFreetextField } from 'application/components/controls/form'
import {TaskUploader} from 'application/components/building_blocks/task_uploader'
import { REASONS_FOR_PAYMENT_REDUCTION } from 'application/constants/payment'
import { useTranslation } from 'react-i18next'

export const PaymentReductionPartialForm = ({
    register,
    errors,
    watch,
    files = [],
    setFiles = () => {},
    onDataChange = () => {},
    checkBoxGroupName,
    freeTextName,
    setValue,
    trigger
}) => {
    const {t} = useTranslation()

    const reasonForReductionOptions = [
        {
            label: t(REASONS_FOR_PAYMENT_REDUCTION.SHIPMENT),
            value: REASONS_FOR_PAYMENT_REDUCTION.SHIPMENT
        },
        {
            label: t(REASONS_FOR_PAYMENT_REDUCTION.RETAIL_PRICE_SURCHARGE),
            value: REASONS_FOR_PAYMENT_REDUCTION.RETAIL_PRICE_SURCHARGE
        },
        {
            label: t(REASONS_FOR_PAYMENT_REDUCTION.SURVEYING),
            value: REASONS_FOR_PAYMENT_REDUCTION.SURVEYING
        },
        {
            label: t(REASONS_FOR_PAYMENT_REDUCTION.CLEANING),
            value: REASONS_FOR_PAYMENT_REDUCTION.CLEANING
        },
        {
            label: t(REASONS_FOR_PAYMENT_REDUCTION.DISINFECTION),
            value: REASONS_FOR_PAYMENT_REDUCTION.DISINFECTION
        },
        {
            label: t(REASONS_FOR_PAYMENT_REDUCTION.FINISHING),
            value: REASONS_FOR_PAYMENT_REDUCTION.FINISHING
        },
        {
            label: t(REASONS_FOR_PAYMENT_REDUCTION.TEST_DRIVE),
            value: REASONS_FOR_PAYMENT_REDUCTION.TEST_DRIVE
        },
        {
            label: t(REASONS_FOR_PAYMENT_REDUCTION.CALIBRATION),
            value: REASONS_FOR_PAYMENT_REDUCTION.CALIBRATION
        }
    ]

    return <React.Fragment>
        <CheckBoxGroupWithFreetextField 
            options={reasonForReductionOptions}
            register={register} 
            errors={errors}
            watch={watch}
            onDataChange={onDataChange}
            checkBoxGroupName={checkBoxGroupName}
            freeTextName={freeTextName}
            setValue={setValue}
            trigger={trigger}
        />
        <TaskUploader
            files={files}
            setFiles={setFiles}
        />
    </React.Fragment>
}