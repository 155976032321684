export const defaultStore = {
    auth: {
        loggedIn: false,
        loggedOut: true,
        manualLoggedOut: false,
        accessToken: '',
        returnUrl: '',
        previousAction: {},
        user: {},
        permissionsFound: true,

        //current branch
        currentBranch: null,
        currentBranchLoaded: false,
        currentBranchLoading: false,
        defaultBranchId: null,
        hasMultipleBranches: false,
        permissions: [], //user permissions in the currently selected branch
        allPermissions: [], //user permissions of all branches

        //preferred account
        preferredAccount: {
            data: null,
            loaded: false,
            found: false
        },

        //current account data, is loaded after account selection or redirect
        currentAccount: null,
        currentAccountLoaded: false,
        currentAccountLoading: false,
        sharedResource: {
            loaded: false,
            data: null,
            tokenList: []
        },

        //list of accounts for account selection

        accounts: {
            data: [],
            total: 0,             //total accounts to decide if change account option should be shown
            found: true,
            loading: false,
            loaded: false,
            firstAccountId: null,   //first account id in list to redirect to dashboard if only one account is present
            firstAccountLocked: true //set to true to prevent hasty redirect
        },

        //needed for token validation
        sessionExpired: false,
        currentAccountId: null
    },
    dialogs: {
        registeredDialogs: [],
        dialogsOpen: false
    },
    notifications: {
        toast: {
            message: '',
            messageType: ''
        }
    },
    pages: {
        branchManagement: {
            roleSidebarVisible: false,
            roleSidebarDataId: null,
            groupSidebarVisible: false,
            groupSidebarDataId: null,
            branchSidebarVisible: false,
            branchSidebarDataId: null,
            helpSidebarVisible: true,
            groupMembers: {
                loadedGroupId: null,
                groupMembersList: [],
                groupMembersListLoading: false,
                groupMembersListLoaded: false,
                groupMembersTotal: null
            }
        },
        dashboard: {
        },
        damageManager: {
            overview: {

            },
            documentCenter: {
                documents: [],
                documentsLoading: false,
                documentsLoaded: false,
                archiveDownloading: false,
                damageId: null,
                attachmentDetails: {
                    visible: false,
                    attachmentId: null,
                    attachmentName: '',
                    damageId: null,
                    category: {
                        loaded: false,
                        id: null
                    }
                }
            },
            inbox: {
                messages: [],
                messagesTotal: 0,
                messagesLoading: false,
                messagesLoaded: false,
                detailsLoaded: false,
                detailsLoading: false,
                details: null,
                deletedMessages: [],
            },
            tasks: {
                tasksList: [],
                tasksLoaded: false,
                tasksLoading: false,
                tasksListDamageId: null,
                selectedTask: null,
                selectedTaskLoading: false,
                selectedTaskLoaded: false,
                selectedTaskDocuments: {
                    documents: [],
                    total: null
                },
                selectedTaskUserList: {
                    users: [],
                    total: 0
                },
                selectedTaskUserListLoading: false,
                attachmentDetails: {
                    visible: false,
                    attachmentId: null,
                    attachmentName: '',
                    damageId: null,
                    taskId: null,
                    category: {
                        loaded: false,
                        id: null
                    }
                }
            },
            shared: {
                damage: null,
                branchInfo: null,
                damageLoaded: false,
                damageLoading: false,
                categories: [],
                categoriesLoaded: false,
                categoriesLoading: false,
                hasLawfirm: false,
                lawfirm: null,
                lawfirmLoaded: false,
                expertFound: false
            }
        },
        damagesOverview: {
            allDamages: {
                damagesList: [],
                damagesListLoading: false,
                damagesListLoaded: false,
                damagesListTotal: 0
            }
        },
        myTasks: {
            allTasks: {
                tasksList: [],
                tasksListLoading: false,
                tasksListTotal: 0,
                tasksListLoaded: false
            },
            userTasks: {
                tasksList: [],
                tasksListLoading: false,
                tasksListTotal: 0,
                tasksListLoaded: false,
            }
        },
        customers: {
            customersList: [],
            customersListLoading: false,
            customersListTotal: 0,
            customersListLoaded: false,
            batchImport: {
                running: false,
                complete: false,
                amount: 0,
                progress: 0
            }
        },
        users: {
            usersList: {
                users: [],
                total: 0
            },
            loaded: false,
            loading: false
        },
        activityLog: {
            activitiesList: [],
            activitiesListLoading: false,
            activitiesListTotal: 0,
            activitiesListLoaded: false
        },
        inbox: {
            messages: [],
            messagesTotal: 0,
            messagesLoading: false,
            messagesLoaded: false,
            detailsLoaded: false,
            detailsLoading: false,
            details: null,
            deletedMessages: [],
        },
        inboxBranch: {
            messages: [],
            messagesTotal: 0,
            messagesLoading: false,
            messagesLoaded: false,
            detailsLoaded: false,
            detailsLoading: false,
            details: null,
            deletedMessages: [],
        },
        workflowPanel: {
            form: {

            },
            documentCenter: {
                documents: [],
                documentsLoading: false,
                documentsLoaded: false,
                sharedResourceId: null,
                archiveDownloading: false,
                attachmentDetails: {
                    visible: false,
                    attachmentId: null,
                    attachmentName: '',
                    damageId: null,
                    sharedResourceId: null,
                    taskId: null,
                }
            },
            inbox: {
                messages: [],
                messagesTotal: 0,
                messagesLoading: false,
                messagesLoaded: false,
                detailsLoaded: false,
                detailsLoading: false,
                details: null,
                deletedMessages: [],
            },
            tasks: {
                tasksList: [],
                tasksLoaded: false,
                tasksLoading: false,
                selectedTask: null,
                selectedTaskLoading: false,
                selectedTaskLoaded: false,
                selectedTaskDocuments: null,
                selectedTaskUserList: {
                    users: [],
                    total: 0
                },
                selectedTaskUserListLoading: false,
                taskLoadedAndNotFound: false,
                attachmentDetails: {
                    visible: false,
                    attachment: null,
                    damageId: null,
                    sharedResourceId: null,
                    taskId: null
                }
            },
            shared: {
                account: null,
                branch: null,
                damage: null,
                damageLoaded: false,
                invoices: [],
                lawfirm: {},
                lawfirmLoaded: false
            },
            activityLog: {
                isLoaded: false,
                isLoading: false,
                activities: [],
                total: 0
            },
            userData: {}
        },
        extCreateDamage: {
            damageDataSending: false,
            damageDataSent: false,
            damageId: null,
            documentsSending: false,
            documentsSent: false
        },
        extCreateDamageKickstart: {
            damageDataSending: false,
            damageDataSent: false
        },
        changelog: {
            announcements: {
                loaded: false,
                loading: false,
                data: null
            }
        }
    },
    widgets: {
        registeredWidgets: []
    },
    widgetContexts: {
        contexts: []
    },
    common: { //for common content + sagas
        usersList: {
            users: [],
            total: 0
        },
        usersListLoaded: false,
        usersListLoading: false,
        categories: [],
        categoriesLoaded: false,
        categoriesLoading: false,
        categoriesAccountId: null,
        accessibleBranches: [],
        allBranches: [],
        accessibleBranchesLoaded: false,
        accessibleBranchesLoading: false,
        comprehensiveDamageTypes: [],
        comprehensiveDamageTypesLoaded: false,
        comprehensiveDamageTypesLoading: false,
        userGroups: [],
        userGroupsLoaded: false,
        userGroupsLoading: false,
        roles: [],
        rolesLoaded: false,
        rolesLoading: false,
        statusList: {
            data: [],
            loaded: false,
            loading: false,
        },
        insurance: {
            companies: [],
            companiesLoaded: false,
            companiesLoading: false
        },
        legalProtectionInsurance: {
            companies: [],
            companiesLoaded: false,
            companiesLoading: false
        },
    },
    attachments: {
        meta: [],
        thumbnails: [],
        fullAttachments: [],
    },
    waitingScreen: {
        active: false,
        inDialog: true,
        message: '',
        subMessages: []
    },
    gallery: {
        isVisible: false,
        damageId: null,
        selectedAttachmentId: null,
        selectedAttachmentName: '',
        match: null,
    },
    registration: {
        userConnected: false,
        ssoAccessToken: null,
        accountCreated: false,
        accountId: null
    },
    realtimeNotifications: {
        sidebarVisible: false,
        hasNewNotifications: false,
        notifications: [],
        previousNotifications: [],
        previousNotificationsLoaded: false,
        previousNotificationsLoading: false
    },
    massOperations: {
        registeredMassOperations: []
    },
    sharedResourceValidation: {
        sharedResourceLoaded: false,
        sharedResource: null
    },
    mainNavigationSidebar: {
        sideBarOpen: true,
        registeredAccordionMenus: []
    },
    updateScheduler: {
        constraints: []
    },
    errors: {
        storedErrors: [],
        currentErrorId: null
    },
    sharedResource: {
        resourceType: null,
        resourceIsValid: false,
        resourceIsLoaded: false,
        resourceData: null,
        id: null,
        pin: null,
        pinValidationPending: false,
        pinIsValid: false,
        pinIsValidated: false,
        returnUrl: null,
        loggedIn: false,
        data: {},
        dataLoaded: false,
        tokenList: []
    },
    dialogStepsWaitingScreen: {
        show: false,
        untranslatedMessage: ''
    },
    accountLogo: {
        blobUrl: null,
        loaded: false,
        loading: false,
        accountId: null,
        accountName: ''
    },
    modules: {
        accountModules: [],
        branchModules: [],
        loaded: false,
        loading: false,
        accountId: null
    },
    featureFlags: {
        loaded: false,
        loading: false,
        activeFeatures: []
    },
    damagesSearch: {
        results: {
            list: [],
            total: 0
        },
        loading: false,
        loaded: false
    },
    uiSettings: {
        accountId: null,
        accountSettings: {
            version: 4,
            preferExistingCustomerOnDamageCreation: true,
            addRvgToPowerOfAttorneyPdfs: false,
            emergencyUser: '',
            kickstartFormRendererStructure: '[]',
            externalDamageCategoryId: null,
            externalDamageBranchAssignees: '',
            kickstartDefaultLawfirms: null,
            externalKickstartSimple: false
        },
        accountSettingsLoaded: false,
        userSettings: {
            version: 2,
            hideFeedbackNotice: false,
            lastSeenAnnouncement: 0,
            genericDashboardConfig: 'dashboardConfigurationDamages',
            branchDashboardConfig: 'dashboardConfigurationDamages',
            useLegacyDamageTables: false
        },
        userSettingsLoaded: false,
    },
    contractors: {
        contractorsList: [],
        contractorsLoading: false,
        contractorsLoaded: false,
        branchContractorsList: [],
        branchContractorsLoading: false,
        branchContractorsLoaded: false,
        accountId: null
    },
    documentsByCategories: {
        resourceId: null,
        categories: []
    },
    lawfirms: {
        loaded: false,
        loading: false,
        accountId: null,
        lawfirmsList: []
    }
}