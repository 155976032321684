import { exists } from 'application/common/data_helpers'
import { useMemo } from 'react'

export const useLawfirmOptions = (webakteAccounts, branchId = null) => {
    const urlBase = process.env.REACT_APP_WEBAKTE_ACCOUNT_LOGO_URL_BASE

    const lawfirmOptions = useMemo(()=>{
        return webakteAccounts.map(
            wa => ({
                value: wa.webakteId,
                label: wa.name,
                imageSrc: urlBase + wa.logoUrl,
                hasLogo: exists(wa.logoUrl)
            })
        )
    }, [webakteAccounts, urlBase])

    return {
        lawfirmOptions
    }
}