import { useSelector, useDispatch } from 'react-redux'
import { getCurrentAccountId, getSharedResourceId } from 'application/redux/selectors'
import { getLawfirmsRequest } from 'application/redux/actions/lawfirms'
import { useCallback, useEffect, useMemo } from 'react'
import { useReduxStoreLookUp } from './use_redux_store_lookup'
import { useModules } from 'application/components/hooks/use_modules'
import { MODULE_NAME } from 'application/constants'

export const useLawfirms = (branchId = null) => {
    const dispatch = useDispatch()
    const {
        lookUp
    } = useReduxStoreLookUp()

    const currentAccountId = useSelector(getCurrentAccountId)
    const sharedResourceId = useSelector(getSharedResourceId)

    const {
        isBranchModuleApplied
    } = useModules()

    const {
        loaded,
        loading,
        accountId,
        lawfirmsList: webakteLawfirms
    } = useSelector(state => state.lawfirms)

    const getLawfirms = useCallback(() => {
        dispatch(getLawfirmsRequest(currentAccountId, branchId))
    }, [branchId, currentAccountId, dispatch])
    
    useEffect(()=>{
        const currentLawfirmState = lookUp(state => state.lawfirms)
        if(!currentLawfirmState.loaded && !currentLawfirmState.loading && (currentAccountId !== accountId || sharedResourceId !== null)){
            getLawfirms()
        }

    }, [accountId, currentAccountId, getLawfirms, loaded, loading, lookUp, sharedResourceId])

    const lawfirmsList = useMemo(()=>{
        if(branchId !== null && isBranchModuleApplied(MODULE_NAME.VOIGT_MODULE, branchId)){
            return [
                {
                    webakteId: MODULE_NAME.VOIGT_MODULE,
                    name: 'Kanzlei Voigt',
                    imageSrc: ''
                },
                ...webakteLawfirms
            ]
        }
        return webakteLawfirms
    }, [branchId, isBranchModuleApplied, webakteLawfirms])

    return {
        loaded,
        loading,
        lawfirmsList
    }
}