import React from 'react'
// import { Grid } from '@material-ui/core'

import { useStatefulForm } from 'application/modules/dynamic-form/hooks/use_stateful_form'
import { FlexColumns, FORM } from './util'

import { TextField } from './text_filed'
import { CheckBox } from './check_box'
import { TextArea } from './text_area'
import { InsuranceFields } from './insurance_fields'
import { GermanDateInput } from './german_date_input'
import { DatePicker } from './date_picker'
import { Select } from './select'
import { Switch } from './switch'
import { Group } from './group'
import { Submit } from './submit'
import { PhoneNumber } from './phone_number'
import { YesNoSelect } from './yes_no_select'
import { WhosAtFaultSelect } from './who_is_at_fault_select'
import { VehicleStateSelect } from './vehicle_state_select'


export const Form = React.forwardRef((
    {
        descriptions,
        onSubmit,
        showSubmitButton,
        defaultValues
    },
    ref
) => {
    const formHook = useStatefulForm({defaultValues})
    formHook.nameRequired = true
    return <form style={{ width: '100%' }} onSubmit={formHook.handleSubmit((data) => {
        data.type = 'Liability'
        onSubmit(data)
    })}>
        <FlexColumns columns={1}>
            {descriptions?.map(description => buildElement(description, formHook, showSubmitButton, ref))}
        </FlexColumns>
    </form>
})

const buildElement = (description, formHook, showSubmitButton, ref) => {
    switch (description.type) {
    case FORM.TEXT_FIELD:
        return <TextField key={description.name} description={description} formHook={formHook} />
    case FORM.TEXT_AREA:
        return <TextArea key={description.name} description={description} formHook={formHook} />
    case FORM.CHECK_BOX:
        return <CheckBox key={description.name} description={description} formHook={formHook} />
    case FORM.INSURANCE_FIELDS:
        return <InsuranceFields
            key={`insurance-${description.nameSelect}`}
            description={description}
            formHook={formHook}
        />
    case FORM.GERMAN_DATE_INPUT:
        return <GermanDateInput key={description.name} description={description} formHook={formHook} />
    case FORM.DATE_PICKER:
        return <DatePicker key={description.name} description={description} formHook={formHook} />
    case FORM.PHONE_NUMBER:
        return <PhoneNumber key={description.name} description={description} formHook={formHook} />
    case FORM.SELECT:
        return <Select key={description.name} description={description} formHook={formHook} />
    case FORM.YES_NO_SELECT:
        return <YesNoSelect key={description.name} description={description} formHook={formHook} />
    case FORM.WHO_IS_AT_FAULT:
        return <WhosAtFaultSelect key={description.name} description={description} formHook={formHook} />
    case FORM.VEHICLE_STATE:
        return <VehicleStateSelect key={description.name} description={description} formHook={formHook} />
    case FORM.SWITCH:
        return <Switch key={`condition-${description.observe}`} description={description} formHook={formHook} buildElement={buildElement} />
    case FORM.GROUP:
        return <Group key={description.label} description={description} formHook={formHook} buildElement={buildElement} showSubmitButton={showSubmitButton} />
    case FORM.SUBMIT:
        return <Submit key={description.label} description={description} formHook={formHook} showSubmitButton={showSubmitButton} ref={ref} />
    default:
        return ''
    }
}