import {
    StaticFilter,
    StringEqualsFilter,
} from '@ec/filter-sort-page-query-js'

export const messages = {
    onlyUnreadMessages:{
        filterName: 'messages_unread',
        filterKey: 'mur',
        label: 'Only unread messages',
        defaultValue: null,
        isStatic: true,
        getFilterQuery: () => ([
            new StaticFilter('readStatus == null')
        ])
    },
    filterByOrigin:{
        filterName: 'messages_origin',
        filterKey: 'mo',
        label: 'Filter by origin',
        defaultValue: null,
        isStatic: false,
        getFilterQuery: (value) => ([
            new StringEqualsFilter('origin', value, true)
        ])
    },
}