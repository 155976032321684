import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Fragments from 'application/components/controls/notification_card/meta_content/fragments'
import { Link } from 'application/components/controls/hyperlink'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'
import { mapStatusName } from 'application/components/controls/casefile_status_name/component'

export const CaseFileInfoUpdatedMetaContent = (
    {
        notification,
        inline,
        onLinkClick
    }
) => {
    const {t} = useTranslation()

    const {
        internalAccountLink
    } = useApplicationLinks()

    const {
        newCaseFileInfo,
        damage
    } = notification.payload

    const {
        referenceId = '',
        note = '',
        status
    } = newCaseFileInfo

    const damageLink =  internalAccountLink(
        `/damages/${damage.id}`
    )

    const mappedStatus = mapStatusName(status)

    return <Fragments.MetaContentContainer inline={inline}>
        <Fragments.ActivityDescription
            label='Damage'
            value={damage.vehicle.licensePlate}
        />
        <Fragments.ActivityDescription
            label='Casefile status'
            value={t(mappedStatus)}
        />
        {
            referenceId !== '' && <Fragments.ActivityDescription
                label='File number'
                value={ referenceId }
            />
        }
        {
            note !== '' && <Fragments.ActivityDescription
                label='Note'
                value={ note }
            />
        }
        <Fragments.ActivityLink inline={inline}>
            <Link
                onClick={onLinkClick}
                to={damageLink}
            >
                {t('jump to damage')}
            </Link>
        </Fragments.ActivityLink>
    </Fragments.MetaContentContainer>
}