import { ACTIONS } from 'application/constants'

export const setMessageUnreadRequest = (damageId, messageId) => ({
    type: ACTIONS.COMMON_SET_MESSAGE_UNREAD_REQUEST,
    damageId, messageId
})

export const markMessageAsUnread = (messageId) => ({
    type: ACTIONS.COMMON_MARK_MESSAGE_UNREAD,
    messageId
})
