import React from 'react'
import { useLawfirmOptions } from 'application/components/hooks'
import { useLawfirms } from 'application/components/hooks/use_lawfirms'
import { useTranslation } from 'react-i18next'
import { validationMethods } from 'application/common'
import { RadioImageButtonGroup } from 'application/components/controls/form/checkboxes/radio_image_button_group'
import { ThemedPreloader } from '../../themed_preloader'

export const LawfirmSelect = ({
    formhook,
    initialLawfirmOption,
    onDataChange = () => {},
    name = 'lawfirm',
    branchId = null
}) => {
    const {t} = useTranslation()
    const {
        register,
        errors,
        setValue,
        updateFormState
    } = formhook

    const {
        loaded,
        lawfirmsList
    } = useLawfirms(branchId)

    const {
        lawfirmOptions
    } = useLawfirmOptions(lawfirmsList, branchId)

    const addSelectedLawfirm = (lawfirm) => {
        setValue(name, lawfirm.id)
        updateFormState()
    }

    if(!loaded){
        return <ThemedPreloader />
    }

    return <RadioImageButtonGroup 
        options={ lawfirmOptions }
        register={register}
        validate={{notEmpty: validationMethods.notEmpty}}
        name='lawfirm'
        error={errors.lawfirm}
        errorMessageOverride={t('Please select a lawfirm')}
        onChange={
            (id)=>{
                onDataChange()
                setValue(name, id)
                addSelectedLawfirm({
                    name: lawfirmsList.find(
                        l => l.webakteId === id
                    )?.name,
                    id
                })
            }
        }
    />
}