import React from 'react'
import { useTranslation } from 'react-i18next'
import { StepsLayoutFooter } from 'application/components/dialogs/_common/steps_layout'
import { IconButtonWithLabel } from 'application/components/controls/icon_button_with_label/component'
import { ActionButton } from 'application/components/controls/action_button'
import { useCreateDamageUrl } from './use_create_damage_url'
import { QrCode } from 'application/components/building_blocks/qr_code'
import { useQrCode } from 'application/components/hooks'
import { CreateZyrullQrStickerButton } from './zyrull_sticker'
import { FlexBox } from 'application/components/fragments/flex_box'
import { useModules } from 'application/components/hooks/use_modules'
import { MODULE_NAME } from 'application/constants'


export const Step1 = ({
    goToPreviousStep,
    dialog
}) => {
    const {t} = useTranslation()

    const {
        isBranchModuleApplied
    } = useModules()

    const {
        licensePlate,
        make,
        model,
        chassisNumber,
        customer,
        proxyShareId,
        branchId
    } = dialog.payload

    const {
        createDamageUrl
    } = useCreateDamageUrl(
        licensePlate, 
        make,
        model,
        chassisNumber,
        proxyShareId
    )

    const {
        qrCodeProps,
        onDownloadQrCode
    } = useQrCode(
        createDamageUrl,
        `QR-Code-Schadenabwicklung-${licensePlate}.png` 
    )


    const QRCodeStickerProps = {
        ...qrCodeProps,
        showImage: false,
        showClearTextUrl: false,
        edge: '240px'
    }


    return <React.Fragment>
        <QrCode 
            {
                ...qrCodeProps
            }
        />

        <StepsLayoutFooter>
            <IconButtonWithLabel
                iconKey='arrow_back'  
                label={t('Back')} 
                onButtonClick={goToPreviousStep} 
            />
            <ActionButton
                buttonText={t('Download')} 
                onButtonClick={onDownloadQrCode}
            />
            {
                isBranchModuleApplied(MODULE_NAME.ZYRULL_QR_CODE_STICKER, branchId) && <React.Fragment>
                    <FlexBox width='8px' flexBasis='8px' />
                    <CreateZyrullQrStickerButton
                        qrCodeProps={QRCodeStickerProps}
                        customer={customer}
                        licensePlate={licensePlate}
                    />
                </React.Fragment>
            }            
        </StepsLayoutFooter>
    </React.Fragment>
}