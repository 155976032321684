import { ACTIONS } from 'application/constants'

export const requestDamage = (damageId) => ({
    type: ACTIONS.DAMAGE_MANAGER_REQUEST_DAMAGE,
    damageId
})

export const resetDamage = () => ({
    type: ACTIONS.DAMAGE_MANAGER_SHARED_RESET_DAMAGE
})

export const populateDamage = (damage, branchInfo) => ({
    type: ACTIONS.DAMAGE_MANAGER_POPULATE_DAMAGE,
    damage, branchInfo
})

export const populateSharedLawfirm = (hasLawfirm, lawfirm) => ({
    type: ACTIONS.DAMAGE_MANAGER_POPULATE_SHARED_LAWFIRM,
    hasLawfirm,
    lawfirm
})

export const updateDamage = (damageId, damageData) => ({
    type: ACTIONS.DAMAGE_MANAGER_UPDATE_DAMAGE,
    damageId,
    damageData
})

export const populateExpert = (expertFound) => ({
    type: ACTIONS.DAMAGE_MANAGER_POPULATE_EXPERT,
    expertFound
})