import { DamageLicensePlate } from 'application/components/controls/damage_licenseplate'
import { DamageStatus } from 'application/components/controls/damage_status'
import { DateComponent } from 'application/components/controls/date'
import { EditableDamageStatus } from 'application/components/controls/editable_damage_state'
import { FullName } from 'application/components/controls/full_name'
import { RepairAuthorizationIndicator } from 'application/components/controls/repair_authorization_indicator'
import { FlexBox } from 'application/components/fragments/flex_box'
import { LawFirmName } from 'application/components/name_mappings/lawfirm_name'
import { RowDetails } from 'application/components/tables/refactored/damages/_common/row_details'
import { MASS_OPERATIONS, ROUTES, ROUTE_PARAMETERS, STORAGE } from 'application/constants'
import * as filters from 'application/filters'
import { ListFilter } from 'application/filters/filter_class'
import * as storage from 'application/storage'
import { theme } from 'application/style/theme'
import React from 'react'
import { DamageTableContextMenu } from '../../_common/context_menu'
import { CustomerOwnerBranchColumn } from '../_common/customer_owner_branch_column'




const tableName = STORAGE.TABLE_NAME.DAMAGES.MY

export const configuration = {
    name: tableName,
    filters: {
        match: [
            new ListFilter(filters.common.branchId)
        ],
        query: [
            new ListFilter(filters.damages.state),
            new ListFilter(filters.damages.customState),
            new ListFilter(filters.damages.creationTimeRange),
            new ListFilter(filters.damages.idleTime),
            new ListFilter(filters.damages.type)
        ],
        property: [
            new ListFilter(filters.damages.assigneeCurrentUserId)
        ],
        static: []
    },
    sorting: {
        sortParamKey: ROUTE_PARAMETERS.DAMAGES_OVERVIEW_MINE.ORDER_BY.KEY,
        directionParamKey: ROUTE_PARAMETERS.DAMAGES_OVERVIEW_MINE.ORDER_BY.DIRECTION,
        defaultSortParamValue: ROUTE_PARAMETERS.DAMAGES_OVERVIEW_MINE.ORDER_BY.VALUES.CREATED_AT,
        defaultDirectionParamValue: ROUTE_PARAMETERS.SORT_DIRECTIONS.DESCENDING
    },
    pagination: {
        offset: ROUTE_PARAMETERS.DAMAGES_OVERVIEW_MINE.OFFSET,
        limit: ROUTE_PARAMETERS.DAMAGES_OVERVIEW_MINE.LIMIT,
        getItemsPerPage: () => {
            return storage.tableConfig.getItemsPerPage(tableName)
        }
    },
    columns: [
        {
            width: 15,
            label: 'License plate',
            hasSorting: true,
            sortParamValue: ROUTE_PARAMETERS.DAMAGES_OVERVIEW_ALL.ORDER_BY.VALUES.LICENSE_PLATE,
            CellContent: ({element}) => <DamageLicensePlate damage={element}/>
        },
        {
            width: 20,
            label: 'Status',
            hasSorting: false,
            sortParamValue: null,
            CellContent: (
                {
                    element, 
                    isSelected,
                    moIsActive,
                    showDetails
                }
            ) => moIsActive 
                ? <DamageStatus
                    status={element.state} 
                    isSelected={isSelected} 
                /> 
                :<EditableDamageStatus
                    element={element}
                    isSelected={isSelected}
                    showDetails={showDetails}
                />
        },
        {
            width: 20,
            label: 'Customer / owner',
            secondLabel: 'branch',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element}) => <CustomerOwnerBranchColumn damage={element} />
        },
        {
            width: 20,
            label: 'Assignee',
            secondLabel: 'Lawfirm',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element}) => <FlexBox flexDirection='column'>
                <FullName person={element.assignee} />
                <LawFirmName lawFirm={element.lawFirm} />
            </FlexBox>
        },
        {
            width: 15,
            label: 'Creation date',
            hasSorting: true,
            sortParamValue: ROUTE_PARAMETERS.ALL_TASKS.ORDER_BY.VALUES.CREATION_DATE,
            CellContent: ({element}) => <DateComponent 
                date={element.createdAt} 
            />,
            breakpoint: theme.breakpoint.xLarge
        },
        {
            width: 15,
            label: 'Last change',
            hasSorting: true,
            sortParamValue: ROUTE_PARAMETERS.ALL_TASKS.ORDER_BY.VALUES.MODIFIED_AT,
            CellContent: ({element}) => <DateComponent 
                date={element.modifiedAt} 
            />,
            breakpoint: theme.breakpoint.xLarge
        },
        {
            width: 10,
            label: 'Authorization',
            hasSorting: false,
            sortParamValue: null,
            CellContent: ({element}) => <RepairAuthorizationIndicator 
                repairAuthorization={element.repairAuthorization} 
            />,
            breakpoint: theme.breakpoint.large
        }
    ],
    ContextMenu: ({element, moIsActive}) => moIsActive 
        ? <React.Fragment />
        : <DamageTableContextMenu damage={element} />,
    contextMenuWidth: 34,
    rows: {
        linked: null,
        selectable: null,
        details: {
            ContentComponent: ({element, isSelected, moIsActive}) =>  moIsActive  
                ? <React.Fragment />
                : <RowDetails
                    element={element} 
                    isSelected={isSelected}
                /> 
        },
    },
    massOperations: {
        available: true,
        name: MASS_OPERATIONS.NAME.DAMAGES_OVERVIEW_MY_DAMAGES,
        type: MASS_OPERATIONS.TYPE.DAMAGES_OVERVIEW,
    },
    //backwards compatibility:::
    href: (element, applicationLinksHook) => applicationLinksHook.damageManagerLink(
        ROUTES.DAMAGE_MANAGER,
        element.id
    ),
    isSelectable: false
}
