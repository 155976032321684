import { api } from 'application/api'
import { ACTIONS } from 'application/constants'
import { takeLatest } from 'redux-saga/effects'
import { handleError } from '../../errors'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* downloadAttachmentsAsZipSaga(action) {
    try {
        yield resourceCall(
            api.gateway.attachments.downloadDocumentsAsZip,
            {
                damageId: action.damageId //in wfp, this is undefined but handled by the resourceCall method
            },
            {
                documentIds: action.documentIds,
                documentName: action.fileName
            }
        )
    } catch (e) {
        yield handleError(e, action)
    }
}

export function* downloadAttachmentsAsZipWatcher() {
    yield takeLatest(ACTIONS.DOWNLOAD_ATTACHMENT_AS_ZIP_REQUEST, downloadAttachmentsAsZipSaga)
}