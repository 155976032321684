import { put, select } from 'redux-saga/effects'
import { api } from 'application/api'
import { updateWidget } from 'application/redux/actions/widgets'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { getCurrentDamage } from 'application/redux/selectors'
import { CONTRACTOR_TYPE } from 'application/constants/contractors'

export function* widgetDamageCommissionedLawfirmSaga(action) {
    const damageId = action.match.params.damageId

    let data = {
        lawfirmFound: false,
        lawfirm: null
    }

    try {
        const currentDamage = yield select(getCurrentDamage)
        const branchId = currentDamage.branchId

        const contractorsResponse = yield resourceCall(
            api.gateway.contractors.getBranchContractors,
            {
                branchId
            }
        )

        const webakteAccounts = contractorsResponse.allowedContractors.filter(c => c.type === CONTRACTOR_TYPE.LAWYER).map(c => ({
            ...c,
            webakteId: c.externalId ?? c.id
        }))


        const response = yield resourceCall(
            api.gateway.damage.getLawfirm,
            {
                damageId
            }
        )

        data = {
            lawfirmFound: true,
            lawfirm: {
                ...response,
                ...webakteAccounts.find(wa => wa.webakteId === response.webakteId)
            }
        }
    } catch (error) {
    
    }
    yield put(updateWidget(action.key, action.dependentWidgetKeys, data))
}