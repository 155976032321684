import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from 'application/components/controls/action_button'
import { PureSelect } from 'application/components/controls/form'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { DIALOG, USER_PERMISSONS } from 'application/constants'
import { isPermittedInSpecificBranch } from 'application/components/context/permissions'
import { useMO } from 'application/components/hooks'


const SelectWrapper = styled.div`
  & .css-yk16xz-control {
      max-height: 35px;
      height: 35px;
      min-height: 35px;
    width: 200px;
  }

  & .css-1pahdxg-control {
    max-height: 35px;
    height: 35px;
    min-height: 35px;
    width: 200px;
}
`

const HoSpacer = styled.div`
    width: ${props => props.space ? props.space : '8px'};
    min-width: ${props => props.space ? props.space : '8px'};
`



const MassOperationsDocumentSelect = (
    props
) => {
    const {
        moName,
        onOpenMoDialog,
        downloadAttachments,
        damageId,
        categories,
        permissions = []
    } = props
    const { t } = useTranslation()

    const {
        moSelectedItems
    } = useMO(moName)

    const basePayload = {
        selectedItems: moSelectedItems,
        moName: moName,
        damageId,
    }

    const moOperations = [
        {
            dialogName: DIALOG.NAME.MO_DOCUMENTS_DELETE,
            initiallyLoaded: true,
            dialogTitle: t('Delete'),
            dialogPayload: basePayload,
            permitted: isPermittedInSpecificBranch(
                permissions,
                [
                    USER_PERMISSONS.DOCUMENT.DELETE
                ],
                true
            )
        },
        {
            dialogName: DIALOG.NAME.MO_DOCUMENTS_CHANGE_CATEGORY,
            initiallyLoaded: true,
            dialogTitle: t('Change category'),
            dialogPayload: {
                ...basePayload,
                categories
            },
            permitted: isPermittedInSpecificBranch(
                permissions,
                [USER_PERMISSONS.DOCUMENT.UPDATE],
                true
            )
        },
        {
            dialogName: DIALOG.NAME.MO_DOCUMENTS_IS_EXPORTED_TO_LAWFIRM,
            initiallyLoaded: true,
            dialogTitle: t('Make available for legal software'),
            dialogPayload: basePayload,
            permitted: isPermittedInSpecificBranch(
                permissions,
                [USER_PERMISSONS.DOCUMENT.UPDATE],
                true
            )
        }
    ]

    const options = [...moOperations
        .filter(
            o => o.permitted
        ).map(
            o => ({
                label: o.dialogTitle,
                value: o.dialogName
            })
        ), {
        label: t('Download as .zip'),
        value: 'download'
    }]


    const getDialogParams = (selectedDialogName) => {
        return moOperations.find(
            o => o.dialogName === selectedDialogName
        )
    }

    const openMoDialog = () => {
        if (selectedOption.value === 'download') {
            const zipFileName = `${t('Documents')} ${props.damage.vehicle.licensePlate}.zip`
            downloadAttachments(damageId, moSelectedItems.map(i => i.id), zipFileName)
        } else {
            const {
                dialogName,
                initiallyLoaded,
                dialogTitle,
                dialogPayload
            } = getDialogParams(selectedOption.value)

            onOpenMoDialog(
                dialogName,
                initiallyLoaded,
                dialogPayload,
                dialogTitle
            )
        }

    }

    const [selectedOption, setselectedDialogName] = useState(null)
    const selectEnabled = moSelectedItems.length > 0
    const actionEnabled = selectEnabled && selectedOption !== null

    const handleSelect = (dialogName) => {
        setselectedDialogName(dialogName)
    }

    return <React.Fragment>
        <SelectWrapper>
            <PureSelect
                options={options}
                value={selectedOption}
                onChange={handleSelect}
                isClearable={true}
                placeholder={t('Choose action')}
                isDisabled={!selectEnabled}
                cssWidth='200px'
            />
        </SelectWrapper>
        <HoSpacer />
        <ActionButton
            buttonText={t('Apply')}
            onButtonClick={openMoDialog}
            disabled={!actionEnabled}
            cssHeight={'50px'}
        />
    </React.Fragment>
}

MassOperationsDocumentSelect.propTypes = {
    moName: PropTypes.string.isRequired,
    onOpenMoDialog: PropTypes.func.isRequired,
    damageId: PropTypes.string.isRequired,
    categories: PropTypes.array.isRequired,
    permissions: PropTypes.array
}

export { MassOperationsDocumentSelect }