import React from 'react'
import { Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// import { TabsLinks } from 'application/components/building_blocks/tabs'
import { ROUTES } from 'application/constants'
import { LayoutCurrentBranch } from 'application/components/pages/_layout'
// import ControllingStatistics from './statistics'
import {ControllingInvoices} from './invoices'
// import { PERMISSION_SCOPE } from 'appmatchparamlication/constants/permissions'
import { USER_PERMISSONS } from 'application/constants'
import { ForCurrentBranchPermissions } from 'application/components/context/permissions'
import { useMatch } from 'react-router-dom'
import { notNull } from 'application/common/data_helpers'
import {withRouter} from 'application/components/higher_order_components/with_router'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'


const Controlling = ({ currentBranch }) => {
    const {t} = useTranslation()
    const currentBranchId = currentBranch !== null 
        ? currentBranch.id 
        : null
    const {branchLink} = useApplicationLinks()

    const matchBranchSpecificControlling = notNull(
        useMatch(ROUTES.BRANCH_SPECIFIC_CONTROLLING)
    )

    if(currentBranchId === null){
        return null
    }

    if (matchBranchSpecificControlling) {
        return <Navigate 
            to={
                branchLink(
                    ROUTES.BRANCH_SPECIFIC_CONTROLLING_INVOICES, 
                    currentBranchId
                )
            }
            replace={true}
        />
    }

    return <LayoutCurrentBranch
        titleSegments={['Controlling']}
        headline={t('Controlling')}
    >

        <ForCurrentBranchPermissions
            permissions={[USER_PERMISSONS.CONTROLLING.READ]} 
            allNeeded={false}
        >
            <ControllingInvoices />
        </ForCurrentBranchPermissions>

        {/* <TabsLinks
            items={[
                {
                    label: t('Invoices'),
                    link: branchLink(ROUTES.BRANCH_SPECIFIC_CONTROLLING_INVOICES, currentBranchId),
                    isActive: match.path === ROUTES.BRANCH_SPECIFIC_CONTROLLING_INVOICES, //useMatch needed
                    permissions: [USER_PERMISSONS.CONTROLLING.READ],
                    permissionScope: PERMISSION_SCOPE.CURRENT_BRANCH,
                    allNeeded: true
                },
                {
                    label: t('Created damages'),
                    link: branchLink(ROUTES.BRANCH_SPECIFIC_CONTROLLING_STATISTICS, currentBranchId),
                    isActive: match.path === ROUTES.BRANCH_SPECIFIC_CONTROLLING_STATISTICS, //useMatch needed
                    permissions: [ USER_PERMISSONS.CONTROLLING.READ],
                    permissionScope: PERMISSION_SCOPE.CURRENT_BRANCH,
                    allNeeded: true
                }
            ]}
        />
        <Switch>
            <Route path={ROUTES.BRANCH_SPECIFIC_CONTROLLING_INVOICES}>
                <ForCurrentBranchPermissions
                    permissions={[USER_PERMISSONS.CONTROLLING.READ]} 
                    allNeeded={false}
                >
                    <ControllingInvoices />
                </ForCurrentBranchPermissions>
            </Route>
            <Route path={ROUTES.BRANCH_SPECIFIC_CONTROLLING_STATISTICS}>
                <ForCurrentBranchPermissions
                    permissions={[USER_PERMISSONS.CONTROLLING.READ]} 
                    allNeeded={false}
                >
                    <ControllingStatistics />
                </ForCurrentBranchPermissions>
            </Route>
        </Switch> */}
    </LayoutCurrentBranch>
}

export const ControllingComponent = withRouter(Controlling)