import React, { useMemo, useState } from 'react'
import { FlexBox } from 'application/components/fragments/flex_box'
import { TextInput, DatePicker } from 'application/components/controls/form'
import { validationMethods } from 'application/common'
import { useTranslation } from 'react-i18next'
import { AddressDataPartialForm } from 'application/components/forms/_partial_forms'
import { FormHeadline } from 'application/components/fragments/typography'
import { TwoColumns } from 'application/components/forms/_common'
import { SubHeadline } from 'application/components/fragments/dialog'
import { ContractorSelect } from 'application/components/controls/contractor_select'
import { ActionButton } from 'application/components/controls/action_button'
import { PureCheckBox } from 'application/components/controls/form/checkboxes/check_box/pure_component'
import { PushBottom16, PushBottom32, Spacer } from 'application/components/pages/_common'
import { PdfSignature } from 'application/components/building_blocks/pdf_signature'
import {  pdfBuilders } from 'application/components/dynamic_pdfs'
import { TaskUploader } from 'application/components/building_blocks/task_uploader'




export const CommissionOtherExpertPartialForm = ({
    formHook,
    commissionOtherFiles,
    setCommissionOtherFiles,
    pdfValues,
    branchId = null,
    onDataChange = () => {},
    usedInDialog = false,
    includePdf = true
}) => {
    const {t} = useTranslation()
    const [addCancellationPolicy, setAddCancellationPolicy] = useState(includePdf)
    const [showSignablePdf, setShowSignablePdf] = useState(false)
    const { notEmpty, mustBeEmail } = validationMethods

    const {
        register, 
        errors, 
        control,
        updateFormState,
        formStateValues,
        setValue,
        trigger,
        handleSubmit
    } = formHook

    const signatureFields = useMemo(
        ()=>{
            const baseFields = [
                {
                    label: 'Assignment',
                    description: ''
                }, 
                {
                    label: 'Data protection',
                    description: ''
                }
            ]

            return addCancellationPolicy 
                ? [
                    ...baseFields,
                    {
                        label: 'Revocation',
                        description: ''
                    }
                ]
                : baseFields
        },[addCancellationPolicy]
    )


    const handleCallBack = (expertOption) => {
        if(expertOption === null){
            setValue('name', '')
            setValue('email', '')
        }else{
            const name = expertOption.name 
            const email = expertOption.email
            setValue('name', name)
            setValue('email', email)
        }
        updateFormState()
    }

    const onSubmitSuccess = () => {
        setShowSignablePdf(!showSignablePdf)
    }

    return <FlexBox flexDirection='column'>
        <SubHeadline>{t('Inspection date')}</SubHeadline>
        <TwoColumns 
            columnLeft={
                <DatePicker
                    label={t('Date')}
                    name='inspectionDate'
                    validate={null}
                    error={errors.inspectionDate}
                    onDateChange={updateFormState}
                    control={control}
                    register={register}
                    defaultValue={formStateValues.inspectionDate}
                    setValue={setValue}
                    trigger={trigger}
                />
            }
        />
        <SubHeadline>{t('Select expert')}</SubHeadline>

        <TwoColumns
            columnLeft={        
                usedInDialog && <ContractorSelect
                    onContractorChange = {handleCallBack}
                    label = {t('Search expert')}
                    branchId={branchId}
                />
            }        
        />       
 
        <TwoColumns 
            columnLeft={
                <TextInput
                    label={t('Name')} 
                    name='name'
                    register={register}
                    validate={{notEmpty}}
                    error={errors.name}
                    autoFocus={true}
                    onChange={onDataChange}
                />
            }
            columnRight={
                <TextInput
                    label={t('E-Mail')} 
                    name='email'
                    register={register}
                    validate={{notEmpty, mustBeEmail}}
                    error={errors.email}
                    onChange={onDataChange}
                />
            }
        />              
        <FormHeadline>{t('Inspection site')}</FormHeadline>
        <TextInput
            label={t('Site name')}
            name='inspectionInfoName'
            register={register}
            validate={{ notEmpty: validationMethods.notEmpty }}
            error={errors.inspectionInfoName}
            onChange={updateFormState}
        />
        <AddressDataPartialForm
            register={register}
            disabled={false}
            nameStreet = 'inspectionSite.line1'
            namePostalCode = 'inspectionSite.postalCode'
            nameCity = 'inspectionSite.locality'
            setValue={formHook.setValue}
            validate={{ notEmpty: validationMethods.notEmpty }}
            validatePostalCode={{ notEmpty: validationMethods.notEmpty }}
            errorNameStreet={errors.inspectionSite?.line1}
            errorNamePostalCode={errors.inspectionSite?.postalCode}
            errorNameCity={errors.inspectionSite?.locality}
        />
        {
            includePdf && <React.Fragment>
                <FlexBox>
                    <ActionButton
                        buttonText={t('Generate declaration of assignment')}
                        onButtonClick={
                            handleSubmit(onSubmitSuccess)
                        }
                        
                    /> 
                    <Spacer basis='16px'  />
                    <PureCheckBox 
                        label={t('Add cancellation policy')}
                        checked={addCancellationPolicy}
                        onChange={setAddCancellationPolicy}
                    />
                </FlexBox>
                <PushBottom16 />
                <TaskUploader
                    onFileAdded={() => { }}
                    disableActiveState={true}                   
                    files={commissionOtherFiles}
                    setFiles={setCommissionOtherFiles}
                    title={t('Documents for the appraiser')}
                />  
            </React.Fragment> 
        }
        <PushBottom32 />
        {
            includePdf
            && showSignablePdf 
            && <PdfSignature
                pdfBuilder={pdfBuilders.declarationOfAssignmentPdfBuilder}
                pdfValues={pdfValues} 
                pdfFileName={`Abtretungserklaerung-${pdfValues.client.firstName}-${pdfValues.client.lastName}-${pdfValues.client.vehicle.licensePlate}.pdf`}
                onSave={
                    (url, attachment)=>{
                        setCommissionOtherFiles( [attachment] )
                        setShowSignablePdf(false)
                    }
                }
                abort={
                    ()=>{ setShowSignablePdf(false) }
                }
                headline='Declaration of assignment'
                saveButtonText='Done'
                signatureFields={signatureFields}
            />
        }
    </FlexBox>
}