import { api } from 'application/api'
import { ACTIONS, MESSAGE_ORIGIN } from 'application/constants'
import { handleError } from 'application/redux/saga/errors'
import { 
    // call, 
    put, 
    takeEvery 
} from 'redux-saga/effects'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { populateDamageManagerMessagesList } from 'application/redux/actions/pages/damage_manager/inbox'
import { composeFilterQuery } from 'application/common/filter_helpers'
import { ListFilter } from 'application/filters'
import * as filters from 'application/filters'

function* getDamageManagerMessageListRequestSaga(action) {
    try {
        const filterQuery = action.onlyUnreadMessages
            ? composeFilterQuery([
                new ListFilter(filters.messages.onlyUnreadMessages),
                new ListFilter(filters.messages.filterByOrigin).setValue(MESSAGE_ORIGIN.LAWFIRM)
            ])
            : composeFilterQuery([])

        const response = yield resourceCall(
            api.gateway.messages.getDamageManagerAllMessageList, 
            {...action, filterQuery}
        )
        yield put(
            populateDamageManagerMessagesList(response.messages, response.total)
        )
    } catch (e) {
        handleError(e, action)
    }
}

export function* damageManagerMessageListWatcher() {
    yield takeEvery(
        ACTIONS.INBOX_DAMAGE_MANAGER_GET_MESSAGES_REQUEST, 
        getDamageManagerMessageListRequestSaga
    )
}