import React from 'react'
import { FormHeadline, Paragraph } from 'application/components/fragments/typography'
import { PushBottom16 } from 'application/components/pages/_common'
import { AnimatedCheckmark } from 'application/components/controls/animated_checkmark'
import { useTranslation } from 'react-i18next'
import { FlexBox } from 'application/components/fragments/flex_box'
import {Collapsable} from 'application/components/controls/collapsable'
import { BulletList, BulletListItem, BulletListLabel } from 'application/components/widgets/_common'
import { ThemedPreloader } from 'application/components/controls/themed_preloader'
import { useUiSettings } from 'application/components/hooks/use_ui_settings'
import { exists } from 'application/common/data_helpers'
import { UI_SETTINGS } from 'application/constants/ui_settings'

export const Step3 = ({
    damageData,
    commission,
    accountName,
    damageDataSent
}) => {
    const {t} = useTranslation()

    const {
        getSetting
    } = useUiSettings()

    const confirmationText = exists(getSetting(UI_SETTINGS.ACCOUNT.EXTERNAL_KICKSTART_CONFIRMATION_TEXT))
        ? getSetting(UI_SETTINGS.ACCOUNT.EXTERNAL_KICKSTART_CONFIRMATION_TEXT)
        : `Ein Sachbearbeiter von ${accountName} setzt sich in Kürze mit Ihnen in Verbindung.`

    return <FlexBox flexDirection='column' alignItems='center'>
        <PushBottom16 />
        {
            damageDataSent ? <AnimatedCheckmark /> : <ThemedPreloader />
        }
        
        <FormHeadline>
            {t('Accident report sent')}
        </FormHeadline>
        <Paragraph cssAlign='center' $wordBreak='break-all'>
            {confirmationText}
        </Paragraph>

        <PushBottom16 />
        <Collapsable
            headline={t('Summary of your details')}
            initiallyCollapsed={true}
            cssOnly={false}
        >
            <BulletList>
                <BulletListItem>
                    <BulletListLabel>{t('License plate')}</BulletListLabel>
                    {
                        damageData?.vehicle?.licensePlate
                    }
                </BulletListItem>
            </BulletList>

            {
                commission.commissionExpert && <BulletList>
                    <BulletListItem>
                        <BulletListLabel cssWidth='120px'>{t('Expert')}</BulletListLabel>
                        {
                            commission.data.name
                        }
                    </BulletListItem>
                </BulletList>
            }

            {
                commission.commissionLawfirm && <BulletList>
                    <BulletListItem>
                        <BulletListLabel cssWidth='120px'>{t('Lawfirm')}</BulletListLabel>
                        {
                            commission.data?.selectedLawfirm?.name
                        }
                    </BulletListItem>
                </BulletList>
            }

        </Collapsable>

    </FlexBox>
}