



export const repairScheduleValuesMapping = (
    damageIn,
    repairScheduleIn,
    customerIn
) => {
    const customerName = customerIn.isCompany 
        ? customerIn?.companyName ?? ''
        : `${customerIn?.firstName  ?? '' } ${customerIn?.lastName  ?? ''}`

    const createCustomerAdressString = (customer) => {
        const line1 = customer?.address?.line1 
            ? `${customer?.address?.line1}, ` 
            : ''
        const locality = `${customer?.address?.postalCode ?? ''} ${customer?.address?.locality  ?? ''}`
        return `${line1} ${locality}`
    }

    const  customer =  {
        externalReferenceId: customerIn?.externalReferenceId ?? '',
        name: customerName,
        address: createCustomerAdressString(customerIn)
    }
        
    const vehicle =  {
        licensePlate: damageIn?.vehicle?.licensePlate ?? '',
        make: damageIn?.vehicle?.make ?? '',
        model: damageIn?.vehicle?.model ?? '',
        chassisNumber: damageIn?.vehicle?.chassisNumber ?? '',
    }

    const repairSchedule = {
        sparePartsOrderedAt: repairScheduleIn?.sparePartsOrderedAt ?? '',
        sparePartsReceivedAt: repairScheduleIn?.sparePartsReceivedAt ?? '',
        shippedToBodyShopAt: repairScheduleIn?.shippedToBodyShopAt ?? '',
        returnedFromBodyShopAt: repairScheduleIn?.returnedFromBodyShopAt ?? '',
        shippedToPaintShopAt: repairScheduleIn?.shippedToPaintShopAt ?? '',
        returnedFromPaintShopAt: repairScheduleIn?.returnedFromPaintShopAt ?? '',
        notes: repairScheduleIn?.notes ?? '',
        notNullMarker: repairScheduleIn?.notNullMarker ?? 0,
        appraisalReceivedAt: repairScheduleIn?.appraisalReceivedAt ?? '',
        inspectedAt: repairScheduleIn?.inspectedAt ?? '',
    }

    const damage = {
        vehicleReceivedAt: damageIn?.vehicleReceivedAt ?? '',
        repairCompletedAt: damageIn?.repairCompletedAt ?? '',
        vehicleReturnedAt: damageIn?.vehicleReturnedAt ?? '',
        repairStartedAt: damageIn?.repairStartedAt ?? '',
        finalRepairStartedAt: damageIn?.finalRepairStartedAt ?? '',
    }    

    const assignee = {
        id: damageIn?.assignee?.userId ?? '',
        name: `${damageIn?.assignee?.firstName} ${damageIn?.assignee?.lastName}`
    }

    return {
        damage,
        repairSchedule,
        customer,
        vehicle,
        assignee
    }
}