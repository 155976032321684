import { api } from 'application/api'
import { ACTIONS, MESSAGE_ORIGIN } from 'application/constants'
import { populateMessagesList } from 'application/redux/actions/pages/inbox'
import { handleError } from 'application/redux/saga/errors'
import { 
    // call, 
    put, 
    takeEvery 
} from 'redux-saga/effects'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { composeFilterQuery } from 'application/common/filter_helpers'
import { ListFilter } from 'application/filters'
import * as filters from 'application/filters'

//overall inbox
function* getMessageListRequestSaga(action) {
    try {
        const filterQuery = action.onlyUnreadMessages
            ? composeFilterQuery([
                new ListFilter(filters.messages.onlyUnreadMessages),
                new ListFilter(filters.messages.filterByOrigin).setValue(MESSAGE_ORIGIN.LAWFIRM)
            ])
            : composeFilterQuery([])

        const response = yield resourceCall(
            api.gateway.messages.getAllMessageList,
            {...action, filterQuery}
        )
        yield put(
            populateMessagesList(response.messages, response.total
            )
        )
    } catch (e) {
        handleError(e, action)
    }
}

export function* messageListWatcher() {
    yield takeEvery(
        ACTIONS.INBOX_GET_MESSAGES_REQUEST, 
        getMessageListRequestSaga
    )
}