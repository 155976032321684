import React from 'react'
import { TextInput } from 'application/components/controls/form'
import PropTypes from 'prop-types'
import { useLimitedCharsValidation } from 'application/components/hooks/use_limited_chars_validation'



export const LimitedCharsTextInput = (
    {
        name,
        register,
        errors,
        watch,
        onChange = () => {},
        label = '',
        limit = 50,
        additionalValidationRules = null,
        defaultValue='',
        showNegativeRemainingChars = true
    }
) => {
    const {
        limitedCharsValidationRules,
        limitedFeedbackLabel
    } = useLimitedCharsValidation(
        name,
        watch,
        label,
        limit,
        showNegativeRemainingChars
    )

    return <TextInput
        label = {limitedFeedbackLabel}
        name = {name}
        register = {register}
        validate = {
            {    
                ...additionalValidationRules,
                ...limitedCharsValidationRules
            }
        }
        error = {errors[name]}
        onChange = {onChange} 
        defaultValue={defaultValue}
        maxLength={limit}
    /> 
}

LimitedCharsTextInput.propTypes = {
    name: PropTypes.string.isRequired,
    register: PropTypes.func.isRequired,
    watch: PropTypes.func.isRequired,  
    errors: PropTypes.object.isRequired,  
    onChange: PropTypes.func,
    label: PropTypes.string,
    limit: PropTypes.number,
    additionalValidationRules: PropTypes.object,
    defaultValue: PropTypes.string,
    showNegativeRemainingChars: PropTypes.bool
}