import styled from 'styled-components'

export const MessageWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-decoration:none;
    padding: 12px 16px;
    background: ${(props) => props.$isSelected ? props.theme.color.gray10 : props.theme.color.white};
    border: 1px solid ${props => props.theme.color.gray15};
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 12px;
    

    &:hover {
        background: ${(props) => props.theme.color.gray5};
    }
`

export const MessageSender = styled.p`
    margin: 0;
    color: ${(props) => props.theme.color.gray70};
    font-size: ${(props) => props.theme.fontSize.standard};
    font-weight: ${(props) => props.theme.fontWeight.normal};
`

export const MessageSenderAndTimeWrapper = styled.div`
    padding: 0 0 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const MessageSubjectAndAttachmentWrapper = styled.div`
    padding: 0;
    display: flex;
    align-content: center;
    align-items: flex-start;
    justify-content: ${(props) => props.attachment ? 'space-between' : 'flex-start'};
    margin-bottom: 8px;
`

export const MessageAttachment = styled.div`
    display: flex;
    align-items: center;
    color: black;
`

export const MessageTime = styled.p`
    margin: 0;
    color: ${(props) => props.theme.color.gray70};
    font-size: ${(props) => props.theme.fontSize.small};
    font-weight: ${(props) => props.theme.fontWeight.small};
`