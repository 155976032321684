import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'application/components/higher_order_components/with_router'
import { WorkflowPanelInboxComponent } from './component'
import { ROUTE_PARAMETERS, ROUTES } from 'application/constants'
import { useQuery } from 'application/components/hooks'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'
import { buildQueryString } from 'application/common/route_helpers'
import { useNavigate } from 'react-router-dom'

const WorkflowPanelInboxEffectsComponent = (props) => {
    const { getMessages, getMessageDetails, match, inbox } = props
    const { messageId } = match.params
    const navigate = useNavigate()
    const query = useQuery()
    const offset = query.getParsedParam(ROUTE_PARAMETERS.WORKFLOW_PANEL.INBOX.OFFSET, 0)
    const limit = query.getParsedParam(ROUTE_PARAMETERS.WORKFLOW_PANEL.INBOX.LIMIT, 10)
    const onlyUnreadMessages = query.getParsedParam(ROUTE_PARAMETERS.WORKFLOW_PANEL.INBOX.ONLY_UNREAD_MESSAGES, 0) === 1

    useEffect(() => {
        getMessages(offset, limit, onlyUnreadMessages)
    }, [getMessages, inbox.deletedMessages, limit, offset, onlyUnreadMessages])

    useEffect(() => {
        if(messageId){
            getMessageDetails(messageId)
        }
    }, [getMessageDetails, messageId])
 
    //handle the unread message filter change
    const {
        internalWorkflowPanelLink
    } = useApplicationLinks()
    
    const handleUnreadMessageFilterChange = (onlyUnread) => {
        const queryString = buildQueryString(onlyUnread 
            ? [{
                key: ROUTE_PARAMETERS.WORKFLOW_PANEL.INBOX.ONLY_UNREAD_MESSAGES,
                value: 1
            }] : [])
    
        const newUrl = internalWorkflowPanelLink(ROUTES.EXT_WORKFLOW_PANEL_MESSAGE) + queryString
        navigate(newUrl)
    }

    return <WorkflowPanelInboxComponent 
        {...props} 
        onUnreadMessageFilterChange={handleUnreadMessageFilterChange}
    />
}

WorkflowPanelInboxEffectsComponent.propTypes = {
    getMessages: PropTypes.func.isRequired,
}

export const WorkflowPanelInboxEffects = withRouter(WorkflowPanelInboxEffectsComponent)