import { put, takeLeading } from 'redux-saga/effects'
import { ACTIONS, MODULE_SCOPE } from 'application/constants'
import { populateModules } from 'application/redux/actions/modules'
import { populateFeatureFlags } from 'application/redux/actions/feature_flags'
import { resourceCall } from '../../helper/resource_call_decorator'
import { api } from 'application/api'

export function* requestModulesSaga(action) {
    try {
        const {accountId} = action

        //feature flags
        const activeFeatures = []

        yield put(populateFeatureFlags(activeFeatures))

        //modules
        const moduleResponse = yield resourceCall(
            api.gateway.modules.getModules
        )


        const accountModules = Object.entries(moduleResponse.modules).reduce((previousBranchModules, currentBranches) => {
            const [, currentBranchModules] = currentBranches

            const moreAccountModules = currentBranchModules
                .filter(module => module.scope === MODULE_SCOPE.ACCOUNT)
                .filter(module => !previousBranchModules.find(prevModule => prevModule.id === module.id))

            return [...previousBranchModules, ...moreAccountModules]
        }, [])

        const branchModules = Object.entries(moduleResponse.modules).map(branchObject => {
            const [branchId, modules] = branchObject
            return {
                branchId,
                modules
            }
        })

        yield put(
            populateModules(
                accountId,
                accountModules,
                branchModules
            )
        )
    } catch (e) {

    }
}

export function* modulesWatcher() {
    yield takeLeading(
        ACTIONS.REQUEST_MODULES,
        requestModulesSaga
    )
}
