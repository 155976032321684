import { combineReducers } from 'redux'
import {dashboardReducer} from './dashboard'
import {damageManagerReducer} from './damage_manager'
import {damagesOverviewReducer} from './damages_overview'
import {inboxReducer} from './inbox'
import {myTasksReducer} from './my_tasks'
import {workflowPanelReducer} from './workflow_panel'
import {customersReducer} from './customers'
import {branchManagementReducer} from './branch_management'
import {extCreateDamageReducer} from './ext_create_damage'
import {extCreateDamageKickstartReducer} from './ext_create_damage_kickstart'
import {activityLogReducer} from './activity_log'
import {usersReducer} from './users'
import {changelogReducer} from './changelog'
import { inboxBranchReducer } from 'application/redux/reducers/pages/inbox_branch'

export const pagesReducer = combineReducers({
    dashboard: dashboardReducer,
    damageManager: damageManagerReducer,
    damagesOverview: damagesOverviewReducer,
    inbox: inboxReducer,
    inboxBranch: inboxBranchReducer,
    myTasks: myTasksReducer,
    workflowPanel: workflowPanelReducer,
    customers: customersReducer,
    branchManagement: branchManagementReducer,
    extCreateDamage: extCreateDamageReducer,
    activityLog: activityLogReducer,
    extCreateDamageKickstart: extCreateDamageKickstartReducer,
    users: usersReducer,
    changelog: changelogReducer
})