import { connect } from 'react-redux'
import { EditBranchComponent } from './component'
import { sendPayloadRequest } from 'application/redux/actions/dialogs'

const mapStateToProps = state => {
    return {
        currentAccount: state.auth.currentAccount
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onEditBranch: (
            dialogName, 
            payload,
            metaData,
        ) => {
            dispatch(
                sendPayloadRequest(
                    dialogName, 
                    payload, 
                    metaData
                )
            )
        }
    }
}

export const EditBranch = connect(
    mapStateToProps,
    mapDispatchToProps
)(EditBranchComponent)