  
import React, {useState} from 'react'
import { ActionButton } from 'application/components/controls/action_button'
import { CallbackComponent } from 'redux-oidc'
import { useTranslation } from 'react-i18next'
import { validationMethods } from 'application/common'
import { TextInput } from 'application/components/controls/form'
import { defaultUserManager } from 'application/common/user_manager'
import {RegistrationLayout} from 'application/components/pages/_registration_layout'
import { ROUTES } from 'application/constants'
import { Navigate, useNavigate } from 'react-router-dom'
import { useStatefulForm } from 'application/components/hooks'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'

export const AccountRegistrationCallbackComponent = ({accountCreated, accountId, onCreateAccount}) => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [accessToken, setAccessToken] = useState()
    const [idToken, setIdToken] = useState()
    const [sharedResourceId, setSharedResourceId] = useState()
    const {register, errors, getValues, handleSubmit} = useStatefulForm()

    const {
        accountLink
    } = useApplicationLinks()

    const onSubmitSuccess = () => {
        onCreateAccount(getValues(), sharedResourceId, idToken, accessToken)
    }

    if(accountCreated){
        const accountRedirect = accountLink(
            accountId, 
            ROUTES.ACCOUNT_PRELOADER
        )

        return <Navigate to={accountRedirect} />
    }

    return (
        <CallbackComponent
            userManager={defaultUserManager}
            successCallback={(user) => {
                setAccessToken(user.access_token)
                setIdToken(user.id_token)
                setSharedResourceId(user.state.sharedResourceId)
            }}
            errorCallback={error => {
                console.error(error)
                navigate('/')
            }}
        >
            <RegistrationLayout>
                <TextInput 
                    label={t('Name of car dealership')}
                    name='accountName'
                    register={register}
                    validate={{notEmpty: validationMethods.notEmpty}}
                    error={errors.accountName}
                />
                <ActionButton buttonText={t('Done')} onButtonClick={handleSubmit(onSubmitSuccess)} />
            </RegistrationLayout>
        </CallbackComponent>
    )
}