import { fetchRequestBuilderPin, HTTP_METHOD } from 'application/api/request_builders'
import { apiCall } from 'application/api/helpers'

export const validatePin = (params) => {
    return {
        authenticated: () => null,
        sharedResource: () => {
            const request = fetchRequestBuilderPin(
                `sharedresources/${params.sharedResourceId}/auth`, 
                params.pinToValidate, 
                HTTP_METHOD.POST,
                undefined,
                false,
                false,
                true
            )
            return apiCall(request)
        }
    }
}