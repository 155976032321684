import { api } from 'application/api'
import { ACTIONS, MESSAGE_ORIGIN } from 'application/constants'
import { handleError } from 'application/redux/saga/errors'
import { 
    // call, 
    put, 
    takeEvery 
} from 'redux-saga/effects'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { ListFilter } from 'application/filters'
import * as filters from 'application/filters'
import { populateBranchMessagesList } from 'application/redux/actions/pages/inbox/branch_inbox'
import { composeFilterQuery } from 'application/common/filter_helpers'

function* getBranchMessageListRequestSaga(action) {
    try {

        const filterQuery = action.onlyUnreadMessages
            ? composeFilterQuery([
                new ListFilter(filters.common.branchId).setValue(action.branchId),
                new ListFilter(filters.messages.onlyUnreadMessages),
                new ListFilter(filters.messages.filterByOrigin).setValue(MESSAGE_ORIGIN.LAWFIRM)
            ])
            : composeFilterQuery([
                new ListFilter(filters.common.branchId).setValue(action.branchId)
            ])

        const response = yield resourceCall(
            api.gateway.messages.getAllMessageList, 
            {
                offset: action.offset, 
                limit: action.limit, 
                filterQuery
            }
        )
        yield put(
            populateBranchMessagesList(response.messages, response.total
            )
        )
    } catch (e) {
        handleError(e, action)
    }
}

export function* messageBranchListWatcher() {
    yield takeEvery(
        ACTIONS.INBOX_BRANCH_GET_MESSAGES_REQUEST, 
        getBranchMessageListRequestSaga
    )
}