import { call, put, takeLeading } from 'redux-saga/effects'
import { ACTIONS } from 'application/constants'
import { handleError } from 'application/redux/saga/errors'
import { showToast } from 'application/redux/actions/notifications'
import { lawfirmSwitch } from 'application/redux/saga/sagas/common/lawfirm_switch'

function* updateLawfirmSaga(action) {
    try {
        const lawfirm = action.payload
        const damageId = action.damageId
    
        yield call(lawfirmSwitch, damageId, lawfirm.name, lawfirm.webakteId)
        yield put(showToast('Commission updated'))
    } catch (e) {
        yield handleError(e, action)
    }
}

export function* updateLawfirmWatcher() {
    yield takeLeading(ACTIONS.COMMON_UPDATE_LAWFIRM, updateLawfirmSaga)
}