import React from 'react'
import { DialogMainContent } from 'application/components/fragments/dialog'
import { Footer } from 'application/components/dialogs/_common/footer'
import { FlexBox } from 'application/components/fragments/flex_box'
import { PartialForms } from 'application/components/forms'
import { GeoCodingControl } from 'application/components/controls/geocoding_control'
import { PushBottom24 } from 'application/components/pages/_common'
import { useStatefulForm } from 'application/components/hooks'
import { useGeoDataSubmit} from 'application/components/hooks'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'
import { FontIcon } from '@ec/ui-controls-react'
import { PushBottom16, Spacer } from 'application/components/pages/_common'
import { InfoBox } from 'application/components/controls/info_box'
import { IMAGES, INFOBOX, MODULE_NAME } from 'application/constants'
import { useModules } from 'application/components/hooks/use_modules'
import { useTranslation } from 'react-i18next'
import { Image } from 'application/components/controls/image'
import { Paragraph, TextLink } from 'application/components/fragments/typography'

const CreateBranch = (
    { 
        abort, 
        dialog, 
        onAddBranch,
        currentAccount
    }
) => {
    const {t} = useTranslation()
    const { 
        register, 
        setValue,
        errors,
        control,
        handleSubmit, 
        getValues,
        formStateValues, 
        updateFormState
    } = useStatefulForm()

    const handleSubmitWithCoordinates = (lat = null, lon = null) => {
        onAddBranch(
            dialog.name, 
            {
                latitude: lat,
                longitude: lon,
                ...getValues()
            }
        )
    }

    const {
        onSubmitSuccess, 
        submitDisabled,
        useGeoCoding,
        setUseGeoCoding
    } =  useGeoDataSubmit(
        formStateValues.address,
        setValue,
        handleSubmitWithCoordinates
    )

    const handleOnAddressChange = () => {
        updateFormState({
            ...getValues()
        })
    }

    const {
        isBranchModuleAppliedInAnyBranch
    } = useModules()

    const supportEmail = process.env.REACT_APP_ECONSULT_SUPPORT_EMAIL
    const subject = `Neue Filiale in ${currentAccount.name}: DEKRA Beauftragung`

    return <React.Fragment>
        <DialogMainContent>
            <FlexBox
                flexDirection='column'
            >
                {
                    isBranchModuleAppliedInAnyBranch(MODULE_NAME.DEKRA_COMMISSION) && <React.Fragment>
                        <PushBottom16 />
                        <InfoBox type={INFOBOX.TYPES.WARNING} direction='row' cssMargin='0'>
                            <FontIcon icon='info_outline' />
                            <Spacer basis='10px' />
                            <Image data={IMAGES.DEKRA_LOGO} />
                            <Spacer basis='10px' />
                            <Paragraph>
                                <span>{t('Expert commissioning via DEKRA is not active in this branch!')}</span>
                                <br />
                                <FlexBox>{t('If you want to activate this Feature, please contact e.Consult via: ')}&nbsp;<strong><TextLink href={`mailto:${supportEmail}?subject=${subject}`}>{supportEmail}</TextLink></strong></FlexBox>
                            </Paragraph>
                            
                            <Spacer />
                        </InfoBox>
                    </React.Fragment>
                }
                {
                    isBranchModuleAppliedInAnyBranch(MODULE_NAME.ZYRULL_QR_CODE_STICKER) && <React.Fragment>
                        <PushBottom16 />
                        <InfoBox type={INFOBOX.TYPES.WARNING} direction='row' cssMargin='0'>
                            <FontIcon icon='info_outline' />
                            <Spacer basis='10px' />
                            <Paragraph>
                                <span>{t('The QR-Code sticker module is not active in this branch!')}</span>
                                <br />
                                <FlexBox>{t('If you want to activate this Feature, please contact e.Consult via: ')}&nbsp;<strong><TextLink href={`mailto:${supportEmail}?subject=${subject}`}>{supportEmail}</TextLink></strong></FlexBox>
                            </Paragraph>
                            
                            <Spacer />
                        </InfoBox>
                    </React.Fragment>
                }
                <PushBottom16 />
                <PartialForms.BranchDataPartialForm 
                    register={register}
                    errors={errors}
                    onChange={handleOnAddressChange}
                    control={control}
                    setValue={setValue}
                    defaultValuePhoneNumber={formStateValues.phoneNumber}
                />
                <PushBottom24 />
                <GeoCodingControl
                    register={register}
                    setValue={setValue}
                    errors={errors}
                    setUseGeoCoding={setUseGeoCoding}
                    useGeoCoding={useGeoCoding}
                /> 
            </FlexBox>
        </DialogMainContent>
        <Footer
            onAbort={abort}
            onSubmit={handleSubmit(onSubmitSuccess)} 
            submitText='Add branch'
            disabled={submitDisabled}
        />
    </React.Fragment>
}

export const CreateBranchComponent = withDialogDataLoader(CreateBranch)