import { fetchRequestBuilderWithToken, fetchHandler, tokenLimiter, HTTP_METHOD } from 'application/api/request_builders'
import { handleApiResponse } from 'application/api/response_handlers'

export const getApplicationToken = (accessToken, accountId) => {
    const request = fetchRequestBuilderWithToken(
        'token',
        HTTP_METHOD.POST,
        accessToken,
        { accountId },
        false,
        false,
        true
    )

    return tokenLimiter.schedule(() => fetchHandler(request.url, request.data))
        .then(handleApiResponse)
}