import { api, dataMappers } from 'application/api'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { all, call } from 'redux-saga/effects'

export function* kickstartCommissionOtherExpertSubSaga (expert, createDamageResponse, files = []) {
    const postAttachmentsServerCalls = files.map(
        file => function* () {        

            const response = yield resourceCall(
                api.gateway.attachments.postAttachment, 
                {
                    damageId: createDamageResponse.id
                },
                {
                    file: file.metaData
                }
            )
            return response
        }
    )
    const attachmentsResponses = yield all(
        postAttachmentsServerCalls.map(
            sc => call(sc)
        )
    )

    const commissionExpertPayload = dataMappers.exportMappers.expert.commissionOtherExpertDataMapper(
        expert.email, 
        expert.name, 
        expert.inspectionSite, 
        expert.inspectionDate, 
        expert.inspectionInfoName,
        attachmentsResponses
    )

    yield resourceCall(
        api.gateway.expert.updateCommissionExpertOther,
        {
            damageId: createDamageResponse.id
        },
        commissionExpertPayload
    )
}