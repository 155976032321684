import React from 'react'
import { useTranslation } from 'react-i18next'
import { CheckBox as CB } from 'application/modules/dynamic-form/controls/checkboxes/check_box'

export const CheckBox = ({ description, formHook }) => {
    const {
        register,
        setValue,
        updateFormState,
    } = formHook

    const { t } = useTranslation()

    return <div style={description._config?.css}>
        <CB
            label={t(description.label)}
            name={description.name}
            register={register}
            setValue={setValue}
            onChange={() => updateFormState()}
        />
    </div>
}
