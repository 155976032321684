import React from 'react'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import { TabsLinks } from 'application/components/building_blocks/tabs'
import { 
    PERMISSION_SCOPE, 
    ROUTES, USER_PERMISSONS } from 'application/constants'
import { LayoutAllBranches } from 'application/components/pages/_layout/layout_all_branches'
import {AdministrationSettingsCategories} from './categories'
import {AdministrationSettingsDamageStates} from './damage_status'
import {AdministrationSettingsAccountData} from './account_data'
import { matchRoute } from 'application/common/route_helpers'
import { ForAnyBranchPermissions } from 'application/components/context/permissions'
import { RedirectStrategy } from './redirect_strategy'
import { useMatch } from 'react-router-dom'
import { HookRoute } from 'application/components/building_blocks/hook_route'
import { AdministrationSettingsAccountLogo } from './account_logo'
import { AdministrationSettingsUiSettings } from './ui_settings'
import { AdministrationSettingsContractors } from './contractors'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'
import { PushBottom48 } from '../../_common'
import { AdministrationSettingsComprehensiveDamageTypes } from './comprehensive_damage_types'

const Settings = () => {
    const {t} = useTranslation()

    const {
        internalAccountLink
    } = useApplicationLinks()

    const adminSettings = matchRoute(
        ROUTES.ADMINISTRATION_SETTINGS,
        useMatch
    )
    const adminSettingsAccountData = matchRoute(
        ROUTES.ADMINISTRATION_SETTINGS_ACCOUNT_DATA,
        useMatch
    )
    const adminSettingsAccountLogo = matchRoute(
        ROUTES.ADMINISTRATION_SETTINGS_ACCOUNT_LOGO,
        useMatch
    )
    const adminSettingsCategories = matchRoute(
        ROUTES.ADMINISTRATION_SETTINGS_CATEGORIES,
        useMatch
    )
    const adminSettingsDamageStates = matchRoute(
        ROUTES.ADMINISTRATION_SETTINGS_DAMAGE_STATES,
        useMatch
    )

    const adminSettingsUiSettings = matchRoute(
        ROUTES.ADMINISTRATION_SETTINGS_UI_SETTINGS,
        useMatch
    )

    const adminSettingsContractors = matchRoute(
        ROUTES.ADMINISTRATION_SETTINGS_CONTRACTORS_UI,
        useMatch
    )

    const adminSettingsComprehensiveDamageTypes = matchRoute(
        ROUTES.ADMINISTRATION_SETTINGS_COMPREHENSIVE_DAMAGE_TYPES,
        useMatch
    )

    if (adminSettings) {
        return <RedirectStrategy />
    }

    return <LayoutAllBranches 
        titleSegments={['Settings']}
        headlineGeneric={t('Administration')} 
        headlineSpecific={t('Settings')}
    >
        <TabsLinks
            items={[
                {
                    label: t('Account data'),
                    link: internalAccountLink( 
                        ROUTES.ADMINISTRATION_SETTINGS_ACCOUNT_DATA
                    ),
                    isActive: adminSettingsAccountData,
                    permissionScope: PERMISSION_SCOPE.ALL_BRANCHES,
                    permissions: [ USER_PERMISSONS.ACCOUNT.UPDATE ]
                },
                {
                    label: t('Account logo'),
                    link: internalAccountLink(
                        ROUTES.ADMINISTRATION_SETTINGS_ACCOUNT_LOGO
                    ),
                    isActive: adminSettingsAccountLogo,
                    permissionScope: PERMISSION_SCOPE.ALL_BRANCHES,
                    permissions: [ USER_PERMISSONS.ACCOUNT.UPDATE ]
                },
                {
                    label: t('Document categories'),
                    link: internalAccountLink( 
                        ROUTES.ADMINISTRATION_SETTINGS_CATEGORIES
                    ),
                    isActive: adminSettingsCategories,
                    permissionScope: PERMISSION_SCOPE.ALL_BRANCHES,
                    permissions: [
                        USER_PERMISSONS.DOCUMENT_CATEGORY.CREATE,
                        USER_PERMISSONS.DOCUMENT_CATEGORY.UPDATE,
                        USER_PERMISSONS.DOCUMENT_CATEGORY.DELETE
                    ]
                },
                {
                    label: t('Damage state'),
                    link: internalAccountLink(
                        ROUTES.ADMINISTRATION_SETTINGS_DAMAGE_STATES
                    ),
                    isActive: adminSettingsDamageStates,
                    permissionScope: PERMISSION_SCOPE.ALL_BRANCHES,
                    permissions: [
                        USER_PERMISSONS.DAMAGE_STATE.CREATE,
                        USER_PERMISSONS.DAMAGE_STATE.UPDATE,
                        USER_PERMISSONS.DAMAGE_STATE.DELETE
                    ]
                },
                {
                    label: t('Comprehensive damage types'),
                    link: internalAccountLink(
                        ROUTES.ADMINISTRATION_SETTINGS_COMPREHENSIVE_DAMAGE_TYPES
                    ),
                    isActive: adminSettingsComprehensiveDamageTypes,
                    permissionScope: PERMISSION_SCOPE.ALL_BRANCHES,
                    permissions: [
                        USER_PERMISSONS.COMPREHENSIVE_DAMAGE_TYPE.CREATE,
                        USER_PERMISSONS.COMPREHENSIVE_DAMAGE_TYPE.UPDATE,
                        USER_PERMISSONS.COMPREHENSIVE_DAMAGE_TYPE.DELETE
                    ]
                },
                {
                    label: t('Contractors'),
                    link: internalAccountLink(
                        ROUTES.ADMINISTRATION_SETTINGS_CONTRACTORS_UI
                    ),
                    isActive: adminSettingsContractors,
                    permissionScope: PERMISSION_SCOPE.ALL_BRANCHES,
                    permissions: [
                        USER_PERMISSONS.BRANCH.UPDATE,
                        USER_PERMISSONS.BILLING.UPDATE_BRANCH_CONTRACTORS
                    ]
                },
                {
                    label: t('User interface'),
                    link: internalAccountLink(
                        ROUTES.ADMINISTRATION_SETTINGS_UI_SETTINGS
                    ),
                    isActive: adminSettingsUiSettings,
                    permissionScope: PERMISSION_SCOPE.ALL_BRANCHES,
                    permissions: [

                    ]
                }

            ]}
        />
        <HookRoute path={ROUTES.ADMINISTRATION_SETTINGS_ACCOUNT_DATA}>
            <ForAnyBranchPermissions
                permissions={[ USER_PERMISSONS.ACCOUNT.UPDATE ]}
            >
                <AdministrationSettingsAccountData />
            </ForAnyBranchPermissions>
        </HookRoute>
        <HookRoute path={ROUTES.ADMINISTRATION_SETTINGS_ACCOUNT_LOGO}>
            <ForAnyBranchPermissions
                permissions={[ USER_PERMISSONS.ACCOUNT.UPDATE ]}
            >
                <AdministrationSettingsAccountLogo />
            </ForAnyBranchPermissions>
        </HookRoute>
        <HookRoute path={ROUTES.ADMINISTRATION_SETTINGS_CATEGORIES}>
                
            <ForAnyBranchPermissions
                permissions={[
                    USER_PERMISSONS.DOCUMENT_CATEGORY.CREATE,
                    USER_PERMISSONS.DOCUMENT_CATEGORY.UPDATE,
                    USER_PERMISSONS.DOCUMENT_CATEGORY.DELETE
                ]}
            >
                <AdministrationSettingsCategories />
            </ForAnyBranchPermissions>
        </HookRoute>
        <HookRoute path={ROUTES.ADMINISTRATION_SETTINGS_DAMAGE_STATES}>
            <ForAnyBranchPermissions
                permissions={[
                    USER_PERMISSONS.DAMAGE_STATE.CREATE,
                    USER_PERMISSONS.DAMAGE_STATE.UPDATE,
                    USER_PERMISSONS.DAMAGE_STATE.DELETE
                ]}
            >
                <AdministrationSettingsDamageStates />
            </ForAnyBranchPermissions>
        </HookRoute>
        <HookRoute path={ROUTES.ADMINISTRATION_SETTINGS_UI_SETTINGS}>
            <ForAnyBranchPermissions
                permissions={[
                ]}
            >
                <AdministrationSettingsUiSettings />
            </ForAnyBranchPermissions>
        </HookRoute>
        <HookRoute path={ROUTES.ADMINISTRATION_SETTINGS_CONTRACTORS_UI}>
            <ForAnyBranchPermissions
                permissions={[
                    USER_PERMISSONS.BRANCH.UPDATE
                ]}
            >
                <AdministrationSettingsContractors />
            </ForAnyBranchPermissions>
        </HookRoute>
        <HookRoute path={ROUTES.ADMINISTRATION_SETTINGS_COMPREHENSIVE_DAMAGE_TYPES}>
            <ForAnyBranchPermissions
                permissions={[
                    USER_PERMISSONS.COMPREHENSIVE_DAMAGE_TYPE.CREATE,
                    USER_PERMISSONS.COMPREHENSIVE_DAMAGE_TYPE.UPDATE,
                    USER_PERMISSONS.COMPREHENSIVE_DAMAGE_TYPE.DELETE
                ]}
            >
                <AdministrationSettingsComprehensiveDamageTypes />
            </ForAnyBranchPermissions>
        </HookRoute>
        <PushBottom48/>
    </LayoutAllBranches>
}

export const SettingsComponent = withTheme(Settings)