import { useModules } from 'application/components/hooks/use_modules'
import { ROUTES } from 'application/constants'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Navigate, useParams } from 'react-router-dom'
import { PagePreloader } from 'application/components/building_blocks/page_preloader'
import { requestCurrentAccountById } from 'application/redux/actions/auth'
import { useFeatureFlag } from 'application/components/hooks/use_feature_flag'

export const ModuleRedirect = ({
    requiredModule = null,
    requiredFeature = null,
    children
}) => {
    const dispatch = useDispatch()
    const {
        accountId
    } = useParams()

    const {
        isAccountModuleApplied,
        modulesLoaded,
        modulesLoading
    } = useModules()

    const {
        isFeatureActive
    } = useFeatureFlag()

    //the <ModuleRedirect /> component is used above our usual account loader
    //-> load account data if not already loaded
    useEffect(()=>{
        if(!modulesLoaded && !modulesLoading){
            dispatch(requestCurrentAccountById(accountId))
        }
    })

    if(!modulesLoaded){
        return <PagePreloader />
    }

    if(!isAccountModuleApplied(requiredModule) && !isFeatureActive(requiredFeature)){
        return <Navigate to={ROUTES.GENERIC_ERROR} />
    }

    return <React.Fragment>{children}</React.Fragment>
}