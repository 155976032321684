import { put, call } from 'redux-saga/effects'
import { showToast } from 'application/redux/actions/notifications'
import { showDialogStepsWaitingScreen, hideDialogStepsWaitingScreen} from 'application/redux/actions/dialog_steps_waiting_screen'
import { 
    kickstartCreateDamageSubSaga, 
    kickstartCommissionLawfirmSubSaga,
    kickstartUploadFilesSubSaga
} from 'application/redux/saga/sagas/sub_sagas/kickstart_sub_sagas'
import { kickstartCommissionOtherExpertSubSaga } from '../sub_sagas/kickstart_sub_sagas/kickstart_commission_other_subsaga'
import { closeAllDialogs, openDialogByName } from 'application/redux/actions/dialogs'
import { DIALOG } from 'application/constants'

export function* dialogCreateDamageKickstartCommissionDekraSaga(action) {


    yield put(
        showDialogStepsWaitingScreen(
            'Creating damage'
        )
    )

    const {
        damageData,
        commissionOtherExpert,
        lawfirm
    } = action.payload
    
    const {createDamageResponse} = yield call(
        kickstartCreateDamageSubSaga, 
        action
    )
    
    //Gutachterbeauftragung
    yield commissionOtherExpert.commissionExpert && call(
        kickstartCommissionOtherExpertSubSaga,
        commissionOtherExpert.expert,
        createDamageResponse,
        commissionOtherExpert.files
    )

    //upload files
    const allFiles = [
        ...damageData.files,
        ...lawfirm.files
    ]
        
    let documentIds = []
        
    if(allFiles.length > 0){
        documentIds = yield call(
            kickstartUploadFilesSubSaga,
            allFiles,
            createDamageResponse,
            lawfirm
        )
    }
    
    //Anwalt beauftragung
    yield lawfirm.commissionLawfirm && call(
        kickstartCommissionLawfirmSubSaga,
        lawfirm,
        createDamageResponse,
        documentIds
    )
    
    yield put(
        hideDialogStepsWaitingScreen()
    )
    yield put(showToast('Damage created'))
    
    yield put(closeAllDialogs())

    yield put(openDialogByName(
        DIALOG.NAME.COMMISSION_DEKRA_EXPERT, 
        false, 
        { damageId: createDamageResponse.id }, 
        'Commission expert'
    ))
}