import React, { useState } from 'react'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'
import { withCategoriesLoader, withUploadSteps } from 'application/components/higher_order_components'
import { CategorySelectWithUploaderStep0 } from './component_step_0'
import { CategorySelectWithUploaderStep1 } from './component_step_1'
import { compose } from 'redux'
import {withExportToLawfirmConfirmationDialog} from 'application/components/higher_order_components'
import { DialogStepsLayout } from 'application/components/dialogs/_common/steps_layout'


const CategorySelectWithUploader = (({
    getStepsState, 
    goToNextStep,
    goToPreviousStep,
    getCurrentStep,
    dialog,
    setAbortBlocked, 
    setDataChanged,
    //from withExport... hoc
    flaggedFiles,
    setFlaggedFiles,
    onSubmitWithExportToLawfirmConfirmation,
    categories = []
})=>{
    const [taskId, setTaskId] = useState(null)
    //take category from payload (if uploaded from document center) or use default category (null for workflow panel)
    const initialCategoryId = dialog.payload.categoryId !== undefined ? dialog.payload.categoryId : categories.find(category => category.isDefault)?.id ?? null
    const [categoryId, setCategoryId] = useState(initialCategoryId)

    const onDataChange = () => {
        setAbortBlocked(true)
        setDataChanged(true)
    }

    const uploadFiles = () => {

        const payload = {files:flaggedFiles}
        const metaData = {
            damageId: dialog.payload.damageId, 
            mainId: dialog.payload.damageId,
            invoiceId: dialog.payload.invoiceId || null,
            categoryId, 
            taskId,
            dialogToOpenData: dialog.payload?.dialogToOpenData || null,
            shouldOpenDialog: dialog.payload?.shouldOpenDialog || false
        }
        
        onSubmitWithExportToLawfirmConfirmation(
            payload,
            metaData
        )
    }



    return <DialogStepsLayout  getStepsState={getStepsState}>
        {
            getCurrentStep().id === 0 && <CategorySelectWithUploaderStep0
                goToNextStep={goToNextStep}
                files={flaggedFiles}         
                setFiles={setFlaggedFiles}
                onDataChange={onDataChange}
            />
        }

        {
            getCurrentStep().id === 1 && <CategorySelectWithUploaderStep1
                goToPreviousStep={goToPreviousStep}
                files={flaggedFiles}
                setFiles={setFlaggedFiles}
                setTaskId={setTaskId}
                setCategoryId={setCategoryId}
                initialCategoryId={initialCategoryId}
                damageId={dialog.payload.damageId}
                onSubmit={uploadFiles}
                useDefaultCategory={dialog.payload.useDefaultCategory}
            />
        }        
    </DialogStepsLayout>
})

export const CategorySelectWithUploaderComponent = compose(
    withUploadSteps(),
    withDialogDataLoader,
    withCategoriesLoader,
    withExportToLawfirmConfirmationDialog
)(CategorySelectWithUploader)