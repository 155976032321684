import { DatePicker } from 'application/components/controls/form'
import { TwoColumns } from 'application/components/forms/_common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { LimitedCharsTextArea } from 'application/components/controls/form/limited_chars/limited_chars_text_area'


export const RepairSchedulePartialForm = ({
    watch,
    register,
    errors,
    disabled = false,
    setValue,
    trigger,
    formStateValues,
    updateFormState,
    control
}) => {
    const { t } = useTranslation()

    return <React.Fragment>
        <TwoColumns
            breakPoint='1854'
            columnLeft={
                <TwoColumns
                    columnLeft={
                        <DatePicker
                            label={t('Inspected at')}
                            name='inspectedAt'
                            error={errors.inspectedAt}
                            control={control}
                            onChange={updateFormState}
                            register={register}
                            defaultValue={formStateValues.inspectedAt}
                            setValue={setValue}
                            trigger={trigger}
                            disabled={disabled}
                        />
                    }
                    columnRight={
                        <DatePicker
                            label={t('Appraisal received at')}
                            name='appraisalReceivedAt'
                            error={errors.appraisalReceivedAt}
                            control={control}
                            //disabledDays={{before: formStateValues.inspectedAt ? new Date(formStateValues.inspectedAt) : null}}
                            onChange={updateFormState}
                            register={register}
                            defaultValue={formStateValues.appraisalReceivedAt}
                            setValue={setValue}
                            trigger={trigger}
                            disabled={disabled}
                        />
                    }
                />
            }
            columnRight={
                <TwoColumns
                    columnLeft={
                        <DatePicker
                            label={t('Spare parts ordered at')}
                            name='sparePartsOrderedAt'
                            error={errors.sparePartsOrderedAt}
                            control={control}
                            onChange={updateFormState}
                            register={register}
                            defaultValue={formStateValues.sparePartsOrderedAt}
                            setValue={setValue}
                            trigger={trigger}
                            disabled={disabled}
                        />
                    }
                    columnRight={
                        <DatePicker
                            label={t('Spare parts received at')}
                            name='sparePartsReceivedAt'
                            error={errors.sparePartsReceivedAt}
                            control={control}
                            onChange={updateFormState}
                            register={register}
                            defaultValue={formStateValues.sparePartsReceivedAt}
                            setValue={setValue}
                            trigger={trigger}
                            disabled={disabled}
                        />
                    }
                />
            }
        />   
        <TwoColumns
            breakPoint='1854'
            columnLeft={
                <TwoColumns
                    columnLeft={
                        <DatePicker
                            label={t('Shipped to bodyshop at')}
                            name='shippedToBodyShopAt'
                            error={errors.shippedToBodyShopAt}
                            control={control}
                            onChange={updateFormState}
                            register={register}
                            defaultValue={formStateValues.shippedToBodyShopAt}
                            setValue={setValue}
                            trigger={trigger}
                            disabled={disabled}
                        />
                    }
                    columnRight={
                        <DatePicker
                            label={t('Returned from bodyshop at')}
                            name='returnedFromBodyShopAt'
                            error={errors.returnedFromBodyShopAt}
                            control={control}
                            onChange={updateFormState}
                            register={register}
                            defaultValue={formStateValues.returnedFromBodyShopAt}
                            setValue={setValue}
                            trigger={trigger}
                            disabled={disabled}
                        />
                    }
                />
            }
            columnRight={
                <TwoColumns 
                    columnLeft={
                        <DatePicker
                            label={t('Shipped to paintshop at')}
                            name='shippedToPaintShopAt'
                            error={errors.shippedToPaintShopAt}
                            control={control}
                            onChange={updateFormState}
                            register={register}
                            defaultValue={formStateValues.shippedToPaintShopAt}
                            setValue={setValue}
                            trigger={trigger}
                            disabled={disabled}
                        />
                    }
                    columnRight={
                        <DatePicker
                            label={t('Returned from paintshop at')}
                            name='returnedFromPaintShopAt'
                            error={errors.returnedFromPaintShopAt}
                            control={control}
                            onChange={updateFormState}
                            register={register}
                            defaultValue={formStateValues.returnedFromPaintShopAt}
                            setValue={setValue}
                            trigger={trigger}
                            disabled={disabled}
                        />
                    }
                />
            }
        />
        <LimitedCharsTextArea
            name='notes'
            register={register}
            errors={errors}
            watch={watch}
            onChange={updateFormState}
            label={t('Notes (e.g. delays)')}
            limit={1400}
            disabled={disabled}
        />

    </React.Fragment>
}