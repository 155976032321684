import { lawfirmSwitch } from 'application/redux/saga/sagas/common/lawfirm_switch'
import { call } from 'redux-saga/effects'

export function* kickstartCommissionLawfirmSubSaga (lawfirm, createDamageResponse, documentIds = []) {
    if(lawfirm.commissionLawfirm){
        const {selectedLawfirm} = lawfirm
        yield call(lawfirmSwitch, createDamageResponse.id, selectedLawfirm.name, selectedLawfirm.webakteId, documentIds)
    }else{
        yield
    }
}