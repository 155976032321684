import React from 'react'

import { TextInputGermanDate } from 'application/modules/dynamic-form/controls/text_input_date'
import { _get } from './util'
import { useTranslation } from 'react-i18next'

export const GermanDateInput = ({ description, formHook }) => {
    const {
        register,
        errors,
        updateFormState
    } = formHook

    const { t } = useTranslation()

    return <TextInputGermanDate
        placeholder={'TT.MM.JJJJ'}
        label={t(description.label)}
        name={description.name}
        register={register}
        validate={description.validate}
        error={_get(errors, description.name)}
        onChange={updateFormState}
    />
}
