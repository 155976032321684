import { takeLeading, put, call } from 'redux-saga/effects'
import { ACTIONS } from 'application/constants'
import { handleError } from 'application/redux/saga/errors'
import { createDamageKickstartSuccess } from 'application/redux/actions/ext_create_damage'
import { 
    kickstartCreateDamageSubSaga, 
    kickstartCommissionLawfirmSubSaga, 
    kickstartUploadFilesSubSaga 
} from 'application/redux/saga/sagas/sub_sagas/kickstart_sub_sagas'
import { kickstartCommissionOtherExpertSubSaga } from '../../sub_sagas/kickstart_sub_sagas/kickstart_commission_other_subsaga'
import { kickstartCommissionDekraSubSaga } from 'application/redux/saga/sagas/sub_sagas/kickstart_sub_sagas/kickstart_commission_dekra_subsaga'
import { exists } from 'application/common/data_helpers'







function* sendCreateDamageKickstartExternalSaga(action) {
    try {

        const {
            damageData
        } = action.payload
    

        const commissionDekra = exists(action.payload.commissionDekra) ? action.payload.commissionDekra : null
        const commissionOtherExpert = exists(action.payload.commissionOtherExpert) ? action.payload.commissionOtherExpert : null
        const lawfirm = exists(action.payload.lawfirm) ? action.payload.lawfirm : null

        const createDamageAction = {
            ...action,
            payload: {
                ...action.payload,
                damageData: {
                    ...action.payload.damageData,
                    customer: commissionDekra ? commissionDekra.commissionDekraData.customer : damageData.customer
                }
            }
        }

        const {
            createDamageResponse
        } = yield call(
            kickstartCreateDamageSubSaga, 
            createDamageAction,
            action.payload.assigneeId
        )
    
        //Gutachterbeauftragung
        yield commissionOtherExpert?.commissionExpert && call(
            kickstartCommissionOtherExpertSubSaga,
            commissionOtherExpert.expert,
            createDamageResponse,
            commissionOtherExpert.files
        )

        //Dekra beauftragung
        yield commissionDekra?.commissionDekra && call(
            kickstartCommissionDekraSubSaga,
            commissionDekra.commissionDekraData,
            createDamageResponse,
            commissionDekra.files
        )

        //upload files
        const allFiles = [
            ...damageData.files,
            ...lawfirm.files
        ]

        let documentIds = []
        
        if(allFiles.length > 0){
            documentIds = yield call(
                kickstartUploadFilesSubSaga,
                allFiles,
                createDamageResponse,
                lawfirm
            )
        }
        
    
        //Anwalt beauftragung
        yield lawfirm?.commissionLawfirm && call(
            kickstartCommissionLawfirmSubSaga,
            lawfirm,
            createDamageResponse,
            documentIds
        )
    
        
        yield put(
            createDamageKickstartSuccess(createDamageResponse.id)
        )
    } catch (e) {
        yield handleError(e)
    }
}

export function* sendCreateDamageExternalKickstartWatcher() {
    yield takeLeading(ACTIONS.EXT_CREATE_DAMAGE_KICKSTART_REQUEST, sendCreateDamageKickstartExternalSaga)
}