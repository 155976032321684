import { exists } from 'application/common/data_helpers'
import { ACCOUNT_BASE, BRANCH_BASE, ROUTES } from 'application/constants/routes'
import { useParams } from 'react-router-dom'


export const useApplicationLinks = () => {
    const params = useParams()

    /**
     * gets value by checking the route or directly
     * @param {string} urlValue 
     * @param {string} urlKey 
     * @return {string} value
     */
    const getURLValue = (
        urlValue, 
        urlKey
    ) => {
        return exists(urlValue) 
            ? urlValue 
            : params[urlKey]
    }

    /**
     * generates urlKey by urlKeyName for react-router in the form "/:urlKeyName"
     * @param {string} urlKeyName name of variable to replace with value from URL "/:urlKeyName"
     */
    const getURLKey = (urlKeyName) => {
        return exists(urlKeyName) 
            ? `/:${urlKeyName}`
            : ''
    }


    const setURLValue = (
        urlKeyName = null,
        urlValue = null,
        href,
        params
    ) => {
        const urlKey = getURLKey(urlKeyName)
    
        const value = getURLValue(
            urlValue, 
            params, 
            urlKey
        )
    
        return href.includes(urlKey) 
            ? href.replace(
                urlKey, 
                `/${value}`
            )
            : href
    }

    /**
     * Link to an wfp specific route, if account id is not part of route.
     * @param {string} sharedResourceId the account Id
     * @param {string} href  URL string, eg ROUTES.ALL_BRANCHES_INBOX
     * @return {string} Url with interpolated account Id
    */
    const workfowPanelLink = (
        sharedResourceId, 
        href
    ) => {
        if(!exists(sharedResourceId) || typeof sharedResourceId !== 'string'){
            console.error('Application Error::: workfowPanelLink() was invoked without proper sharedResourceId in signature:', href)
            return ROUTES.GENERIC_ERROR
        }

        if(href.includes(`${ROUTES.EXT_WORKFLOW_PANEL}`)){
            href = href.replace(`${ROUTES.EXT_WORKFLOW_PANEL}`, `${ROUTES.EXT_WORKFLOW_PANEL_BASE}${sharedResourceId}`)
        }else if(href.startsWith('/')){ //fallback, hrefs should always include the complete route
            href = href.substring(1)
        }

        return href
    }

    /**
     * Internal links within an wfp, shared resource id is taken from route.
     * This should be the standard case, 9/10 times we have an shared resource id given in the route.
     * @param {string} href  URL string, eg ROUTES.ALL_BRANCHES_INBOX
     * @return {string} Url with interpolated account Id
    */
    const internalWorkflowPanelLink = (
        href
    ) => {

        if(!exists(params.sharedResourceId)){
            console.error('Application Error::: internalWorkflowPanelLink() was invoked without proper sharedResourceId in route:', href)
            return ROUTES.GENERIC_ERROR
        }

        return workfowPanelLink(params.sharedResourceId, href)
    }


    /**
     * Link to an account specific route, if account id is not part of route.
     * Can be used in a list of account to link the accounts.
     * @param {string} accountId the account Id
     * @param {string} href  URL string, eg ROUTES.ALL_BRANCHES_INBOX
     * @return {string} Url with interpolated account Id
    */
    const accountLink = (
        accountId, 
        href
    ) => {
        if(!exists(accountId) || typeof accountId !== 'string'){
            console.error('Application Error::: getAccountLink() was invoked without proper account id in signature:', href)
            return ROUTES.GENERIC_ERROR
        }

        let url = `${ACCOUNT_BASE}/${accountId}/${href}`
    
        if(href.includes(`${ACCOUNT_BASE}/:accountId`)){
            url = href.replace(`${ACCOUNT_BASE}/:accountId`, `${ACCOUNT_BASE}/${accountId}`)
        }else if(href.startsWith('/')){ //fallback, hrefs should always include the complete route
            href = href.substring(1)
            url = `${ACCOUNT_BASE}/${accountId}/${href}`
        }
    
        return url
    }

    /**
     * Internal links within an account, account id is taken from route.
     * This should be the standard case, 9/10 times we have an account id given in the route.
     * @param {string} href  URL string, eg ROUTES.ALL_BRANCHES_INBOX
     * @return {string} Url with interpolated account Id
    */
    const internalAccountLink = (
        href
    ) => {
        if(!exists(params.accountId)){
            console.error('Application Error::: getInternalAccountLink() was invoked without proper account id in route:', href)
            return ROUTES.GENERIC_ERROR
        }

        return accountLink(params.accountId, href)
    }

    /**
     * internal link to a branch, account id is taken from route
     * @param {string} href  URL string, eg ROUTES.ALL_BRANCHES_INBOX
     * @param {string} branchId the branch Id, can be omitted if present in route
     * @param {string} accountId the account Id, can be omitted if present in route
     * @return {string} Url with interpolated Ids
    */
    const branchLink = (
        href, 
        branchId = null,
        accountId = null
    ) => {
        if(!exists(branchId)){
            console.error('Application Error::: getInternalBranchLink() was invoked without proper branch id in signature:', href)
            return ROUTES.GENERIC_ERROR
        }

        return accountLink(
            accountId || params.accountId, 
            href
        ).replace(
            `${BRANCH_BASE}/:branchId`, 
            `${BRANCH_BASE}/${branchId}`
        )
    }

    /**
     * link to a damage manager sub page, tries to get account id / damage id from route if not given
     * @param {string} href  URL string, eg ROUTES.ALL_BRANCHES_INBOX
     * @param {string} damageId the damage id, can be omitted if present in route
     * @param {string} accountId the account id, can be omitted if present in route
     * @return {string} Url with interpolated Ids
    */
    const damageManagerLink = (
        href, 
        damageId = null,
        accountId = null
    ) => {
        let url = accountLink(
            accountId || params.accountId, //route params as fallback
            href
        )    
        return setURLValue(
            'damageId', 
            damageId || params.damageId, //route params as fallback
            url,
            params
        )
    }

    /**
     * link to a specific task in damage manager, tries to get account id / damage id / task id from route if not given
     * @param {string} href  URL string, eg ROUTES.ALL_BRANCHES_INBOX
     * @param {string} taskId the task id, can be omitted if present in route
     * @param {string} damageId the damage id, can be omitted if present in route
     * @param {string} accountId the account id, can be omitted if present in route
     * @return {string} Url with interpolated Ids
    */
    const damageManagerTaskLink = (
        href, 
        taskId = null,
        damageId = null,
        accountId = null
    ) => {
        let damageManagerURL = damageManagerLink(
            href,
            damageId,
            accountId
        )
    
        return setURLValue(
            'taskId', 
            taskId || params.taskId, //route params as fallback
            damageManagerURL,
            params
        )
    }

    

    return {
        accountLink,
        internalAccountLink,
        branchLink,
        damageManagerLink,
        damageManagerTaskLink,
        workfowPanelLink,
        internalWorkflowPanelLink,
        params
    }
}