import { all } from 'redux-saga/effects'
import { showNotificationWatcher } from './sagas/notifications'
import * as dialogs from './sagas/dialogs'
import * as widgets from './sagas/widgets'
import * as damageManager from './sagas/pages/damage_manager'
import * as workflowPanel from './sagas/pages/workflow_panel'
import * as damagesOverview from './sagas/pages/damages_overview'
import * as common from './sagas/common'
import * as attachments from './sagas/attachments'
import * as inbox from './sagas/pages/inbox'
import * as inboxBranch from './sagas/pages/inbox_branch'
import * as tasksOverview from './sagas/pages/tasks_overview'
import * as customers from './sagas/pages/customers'
import * as users from './sagas/pages/users'
import * as registration from './sagas/registration'
import * as administration from './sagas/pages/administration'
import * as realtimeNotifications from './sagas/realtime_notifications'
import * as activityLog from './sagas/pages/activity_log'
import * as sharedResource from './sagas/shared_resource'
import * as auth from './sagas/auth'
import * as branchManagement from './sagas/pages/branch_management'
import { sendCreateDamageExternalWatcher } from './sagas/pages/ext_create_damage'
import { sendCreateDamageExternalKickstartWatcher } from './sagas/pages/ext_create_damage_kickstart'
import {accountLogoWatcher} from './sagas/account_logo'
import {getDamageResultsWatcher} from './sagas/damages_search'
import { qrCodeDownloadWatcher } from './sagas/qr_code'
import { modulesWatcher } from './sagas/modules'
import { customerBatchImportWatcher } from './sagas/pages/customers_batch_import'
import {getAssigneeResultsWatcher} from './sagas/dialogs/task/dialog_task_assign_get_users'
import * as uiSettings from './sagas/ui_settings'
import { changelogWatcher } from './sagas/pages/changelog'
import { contractorsWatcher } from './sagas/contractors'
import { getLawfirmsWatcher } from './sagas/lawfirms'


export  function* rootSaga() {
    yield all([
        showNotificationWatcher(),
        auth.ssoWatcher(),
        auth.accountsWatcher(),
        auth.branchesWatcher(),
        dialogs.sendPayloadWatcher(),
        dialogs.fetchDataDialogWatcher(),
        dialogs.switchDialogWatcher(),
        widgets.fetchWidgetDataWatcher(),
        damageManager.damageManagerWatcher(),
        damageManager.damageManagerTasksWatcher(),
        damageManager.damageManagerSelectedTaskWatcher(),
        damageManager.damageManagerDocumentsWatcher(),
        damageManager.updateTaskWatcher(),
        damageManager.assignTaskWatcher(),
        damageManager.changeTaskStateWatcher(),
        damageManager.changeCategoryOfDocumentWatcher(),
        damageManager.createCommentWatcher(),
        damageManager.loadMoreCommentsWatcher(),
        damageManager.updateWorkflowPanelFormWatcher(),
        damageManager.updateDamageWatcher(),
        damageManager.updateAccidentReportWatcher(),
        damageManager.updateOpponentWatcher(),
        damageManager.updatePoliceWatcher(),
        damageManager.updateWitnessWatcher(),
        damageManager.loadDocumentsByCategoryWatcher(),
        damageManager.damageManagerMessageDetailsWatcher(),
        damageManager.damageManagerMessageListWatcher(),
        damageManager.shared.updateVehicleWatcher(),
        damageManager.shared.updateDocumentWatcher(),
        damageManager.shared.refreshSharedLawfirmSagaWatcher(),
        damagesOverview.getAllDamagesWatcher(),
        damagesOverview.updateDamageStateWatcher(),
        common.getUsersWatcher(),
        common.updateAccountWatcher(),
        common.categories.getCategoriesWatcher(),
        common.categories.moveDocumentCategoryWatcher(),
        common.getInsurancesWatcher(),
        common.getCustomersWatcher(),
        common.getAccessibleBranchesWatcher(),
        common.getComprehensiveDamageTypeWatcher(),
        common.statusListWatcher(),
        common.updateCustomStatusListWatcher(),
        common.updateLawfirmWatcher(),
        common.markMessageUnreadWatcher(),
        inbox.messageDetailsWatcher(),
        inbox.messageListWatcher(),
        inboxBranch.messageBranchDetailsWatcher(),
        inboxBranch.messageBranchListWatcher(),
        attachments.fetchAttachmentMetaByIdWatcher(),
        attachments.fetchThumbnailByIdWatcher(),
        attachments.downloadAttachmentWatcher(),
        attachments.downloadDamageDocumentsWatcher(),
        attachments.downloadTaskDocumentsWatcher(),
        attachments.fetchFullAttachmentByIdWatcher(),
        attachments.downloadAttachmentsAsZipWatcher(),
        customers.getCustomersWatcher(),
        users.getUsersWatcher(),
        activityLog.getActivityLogWatcher(),
        workflowPanel.workflowPanelTasksWatcher(),
        workflowPanel.workflowPanelSelectedTaskWatcher(),
        workflowPanel.workflowPanleActivityLogWatcher(),
        workflowPanel.workflowPanelDocumentsWatcher(),
        workflowPanel.workflowPanelCategoriesWatcher(),
        workflowPanel.workflowPanelVehicleWatcher(),
        workflowPanel.workflowPanelLawfirmWatcher(),
        workflowPanel.wfpMessageDetailsWatcher(),
        workflowPanel.wfpMessageListWatcher(),
        registration.registrationConnectUserWatcher(),
        registration.createAccountWatcher(),
        administration.sendInvitationWatcher(),
        realtimeNotifications.realtimeNotificationsWatcher(),
        tasksOverview.getAllTasksWatcher(),
        tasksOverview.getMyTasksWatcher(),
        sharedResource.validatePinWatcher(),
        sharedResource.fetchSharedResourceWatcher(),
        sharedResource.fetchSharedResourceDataWatcher(),
        common.getUserGroupsWatcher(),
        common.getRolesWatcher(),
        branchManagement.getGroupMembersWatcher(),
        branchManagement.connectedGroupBranchesWatcher(),
        branchManagement.connectedGroupRolesWatcher(),
        branchManagement.removeGroupRolesConnectionWatcher(),
        branchManagement.removeGroupBranchesConnectionWatcher(),
        branchManagement.updateGroupWatcher(),
        sendCreateDamageExternalWatcher(),
        sendCreateDamageExternalKickstartWatcher(),
        qrCodeDownloadWatcher(),
        dialogs.getExpertsFromSearchWatcher(),
        accountLogoWatcher(),
        getDamageResultsWatcher(),
        modulesWatcher(),
        customerBatchImportWatcher(),
        getAssigneeResultsWatcher(),
        uiSettings.accountSettingsWatcher(),
        uiSettings.userSettingsWatcher(),
        uiSettings.saveAccountSettingsWatcher(),
        uiSettings.saveUserSettingsWatcher(),
        changelogWatcher(),
        contractorsWatcher(),
        getLawfirmsWatcher()
    ])
}