export const WIDGET_TYPES = {
    LAST_TASKS: 'LAST_TASKS',
    LAST_REPAIR_AUTHORIZATIONS: 'LAST_REPAIR_AUTHORIZATIONS',
    BRANCH_SPECIFIC_LAST_REPAIR_AUTHORIZATIONS: 'BRANCH_SPECIFIC_LAST_REPAIR_AUTHORIZATIONS',
    LAST_DAMAGES: 'LAST_DAMAGES',
    BRANCH_SPECIFIC_LAST_DAMAGES: 'BRANCH_SPECIFIC_LAST_DAMAGES',
    DAMAGE_VEHICLE: 'DAMAGE_VEHICLE',
    DAMAGE_VEHICLE_OWNER: 'DAMAGE_VEHICLE_OWNER',
    DAMAGE_INSURANCES: 'DAMAGE_INSURANCES',
    DAMAGE_REPORT: 'DAMAGE_REPORT',
    DAMAGE_ACCIDENT_REPORT: 'DAMAGE_ACCIDENT_REPORT',
    DAMAGE_TASK_BULLET_LIST: 'DAMAGE_TASK_BULLET_LIST',
    COMMISSION_LAWFIRM: 'COMMISSION_LAWFIRM',
    COMMISSION_EXPERT: 'COMMISSION_EXPERT',
    DAMAGE_ACTIVITY_LOG: 'DAMAGE_ACTIVITY_LOG',
    DAMAGE_CASE_NOTES: 'DAMAGE_CASE_NOTES',
    TASK_COMMENTS: 'TASK_COMMENTS',
    TASK_UPLOADER: 'TASK_UPLOADER',
    UNKNOWN: 'UNKNOWN',
    DAMAGE_MANAGER_INVOICES_LIST: 'DAMAGE_MANAGER_INVOICES_LIST',
    DAMAGE_MANAGER_CREATE_INVOICE: 'DAMAGE_MANAGER_CREATE_INVOICE',
    WORKFLOW_PANEL_TASK_COMMENTS: 'WORKFLOW_PANEL_TASK_COMMENTS',
    WORKFLOW_PANEL_TASK_UPLOADER: 'DocumentUploadExternal',
    WORKFLOW_PANEL_FORM_AKTEN_STATUS: 'WORKFLOW_PANEL_FORM_AKTEN_STATUS',
    WORKFLOW_PANEL_FORM_LIABILITY_REQUEST: 'WORKFLOW_PANEL_FORM_LIABILITY_REQUEST',
    WORKFLOW_PANEL_FORM_RECEIPT_CONFIRMATION: 'WORKFLOW_PANEL_FORM_RECEIPT_CONFIRMATION',
    WORKFLOW_PANEL_FORM_INSURANCE_INFORMATION: 'WORKFLOW_PANEL_FORM_INSURANCE_INFORMATION',
    WORKFLOW_PANEL_FORM_INVOICES: 'WORKFLOW_PANEL_FORM_INVOICES',
    WORKFLOW_PANEL_FORM_REPAIR_CLEARANCE: 'WORKFLOW_PANEL_FORM_REPAIR_CLEARANCE',
    DAMAGE_AK_RECEIPT_CONFIRMATION: 'DAMAGE_AK_RECEIPT_CONFIRMATION',
    DAMAGE_AK_LIABILITY_REQUEST: 'DAMAGE_AK_LIABILITY_REQUEST',
    DAMAGE_AK_AKTEN_STATUS: 'DAMAGE_AK_AKTEN_STATUS',
    DAMAGE_AK_INVOICES: 'DAMAGE_AK_INVOICES',
    DAMAGE_AK_REPAIR_CLEARANCE: 'DAMAGE_AK_REPAIR_CLEARANCE',
    DAMAGE_SHARE: 'DAMAGE_SHARE',
    DAMAGE_REPAIR_AUTHORIZATION_DISPLAY: 'DAMAGE_REPAIR_AUTHORIZATION_DISPLAY',
    CONTROLLING_VACANT_POSTIONS_HAFTPFLICHT: 'CONTROLLING_VACANT_POSTIONS_HAFTPFLICHT',
    CONTROLLING_VACANT_POSTIONS_KASKO: 'CONTROLLING_VACANT_POSTIONS_KASKO',
    CONTROLLING_VACANT_POSTIONS_GESAMT: 'CONTROLLING_VACANT_POSTIONS_GESAMT',
    CONTROLLING_VACANT_POSTIONS_DAMAGES_TABLE: 'CONTROLLING_VACANT_POSTIONS_DAMAGES_TABLE',
    CONTROLLING_STATISTICS_DAMAGE_COUNT: 'CONTROLLING_STATISTICS_DAMAGE_COUNT',
    CONTROLLING_GLOBAL_VACANT_POSITIONS: 'CONTROLLING_GLOBAL_VACANT_POSITIONS',
    CONTROLLING_GLOBAL_DAMAGES_BAR_CHART: 'CONTROLLING_GLOBAL_DAMAGES_BAR_CHART',
    BRANCH_SPECIFIC_CONTROLLING_VACANT_POSTIONS_HAFTPFLICHT: 'BRANCH_SPECIFIC_CONTROLLING_VACANT_POSTIONS_HAFTPFLICHT',
    BRANCH_SPECIFIC_CONTROLLING_VACANT_POSTIONS_KASKO: 'BRANCH_SPECIFIC_CONTROLLING_VACANT_POSTIONS_KASKO',
    BRANCH_SPECIFIC_CONTROLLING_VACANT_POSTIONS_GESAMT: 'BRANCH_SPECIFIC_CONTROLLING_VACANT_POSTIONS_GESAMT',
    BRANCH_SPECIFIC_CONTROLLING_VACANT_POSTIONS_DAMAGES_TABLE: 'BRANCH_SPECIFIC_CONTROLLING_VACANT_POSTIONS_DAMAGES_TABLE',
    BRANCH_SPECIFIC_CONTROLLING_STATISTICS_DAMAGE_COUNT: 'BRANCH_SPECIFIC_CONTROLLING_STATISTICS_DAMAGE_COUNT',

    //refactored task form widgets
    TASK_DAMAGE_BASIC_DATA_FORM: 'DamageBasicData',
    TASK_DAMAGE_VEHICLE_DATA_FORM: 'VehicleData',
    TASK_DAMAGE_UPDATE_CUSTOMER: 'CustomerData',

    TASK_DAMAGE_LIABILITY_INSURANCE_DATA: 'LiabilityInsuranceData',
    TASK_DAMAGE_LIABILITY_OTHER_VEHICLE_DRIVER_DATA: 'LiabilityOtherVehicleDriverData',
    TASK_DAMAGE_COMPREHENSIVE_INSURANCE_DATA: 'ComprehensiveData',
    TASK_DAMAGE_POLICE_DATA: 'LiabilityPoliceRecordData',
    TASK_DAMAGE_WITNESS_DATA: 'LiabilityWitnessData',
    TASK_DAMAGE_ACCIDENT_REPORT_DATA: 'LiabilityAccidentReportData',
    TASK_DAMAGE_OPPONENT_DRIVER_DATA: 'LiabilityOtherVehicleDriverData',
    TASK_DAMAGE_OPPONENT_KEEPER_DATA: 'LiabilityOtherVehicleKeeperData',
    TASK_DAMAGE_OPPONENT_VEHICLE_DATA: 'LiabilityOtherVehicleData',
    TASK_DAMAGE_VEHICLE_PROPRIETOR_DATA: 'VehicleProprietorData',
    TASK_DAMAGE_COMBINED_LIABILITY_DATA: 'LiabilityData',
    TASK_DAMAGE_REPAIR_SCHEDULE: 'RepairSchedule',
    TASK_DAMAGE_REPAIR_STARTED_AT: 'DamageRepairStartedAtData',

    TASK_DAMAGE_UPLOADER: 'DocumentUpload',
    TASK_DAMAGE_REPAIR_AUTHORIZATION_FORM: 'RepairAuthorization',

    TASK_DAMAGE_CREATE_INVOICE: 'InvoiceCreate',
    TASK_DAMAGE_INVOICES: 'InvoiceList',
}

export const WIDGET_CONTEXTS = {
    DEFAULT_CONTEXT: 'DEFAULT_CONTEXT',
    DASHBOARD: 'WIDGET_CONTEXT_DASHBOARD',
    CONTROLLING_VACANT_POSTIONS: 'CONTROLLING_VACANT_POSTIONS',
    CONTROLLING_STATISTICS: 'CONTROLLING_STATISTICS',
    CONTROLLING_GLOBAL_STATISTICS: 'CONTROLLING_GLOBAL_STATISTICS',
    CONTROLLING_GLOBAL_DAMAGES: 'CONTROLLING_GLOBAL_DAMAGES',
    BRANCH_SPECIFIC_DASHBOARD: 'WIDGET_CONTEXT_BRANCH_SPECIFIC_DASHBOARD',
    BRANCH_SPECIFIC_CONTROLLING_VACANT_POSTIONS: 'WIDGET_CONTEXT_BRANCH_SPECIFIC_CONTROLLING_VACANT_POSTIONS',
    BRANCH_SPECIFIC_CONTROLLING_STATISTICS: 'WIDGET_CONTEXT_BRANCH_SPECIFIC_CONTROLLING_STATISTICS',
    DAMAGE_MANAGER_OVERVIEW: 'DAMAGE_MANAGER_OVERVIEW',
    DAMAGE_MANAGER_OVERVIEW_SIDEBAR: 'DAMAGE_MANAGER_OVERVIEW_SIDEBAR',
    DAMAGE_MANAGER_TASKS_SIDEBAR: 'DAMAGE_MANAGER_TASKS_SIDEBAR',
    DAMAGE_MANAGER_INVOICES: 'DAMAGE_MANAGER_INVOICES',
    DAMAGE_MANAGER_ANWALT_KOMMUNIKATION: 'DAMAGE_MANAGER_ANWALT_KOMMUNIKATION',
    DAMAGE_MANAGER_ANWALT_KOMMUNIKATION_INVOICES: 'DAMAGE_MANAGER_ANWALT_KOMMUNIKATION_INVOICES',
    WORKFLOW_PANEL_TASKS_SIDEBAR: 'WORKFLOW_PANEL_TASKS_SIDEBAR',
    WORKFLOW_PANEL_FORM_SIDEBAR: 'WORKFLOW_PANEL_FORM_SIDEBAR',
    WORKFLOW_PANEL_FORM: 'WORKFLOW_PANEL_FORM',
}