import { call, put } from 'redux-saga/effects'
import { showToast } from 'application/redux/actions/notifications'
import { api } from 'application/api'
import { showWaitingScreen, hideWaitingScreen } from 'application/redux/actions/waiting_screen'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { populateSharedLawfirm } from 'application/redux/actions/pages/damage_manager'
import { COMMISSION_STATE } from 'application/constants'
import { lawfirmSwitch } from 'application/redux/saga/sagas/common/lawfirm_switch'

export function* dialogCommisionLawfirmSaga(action) {
    const { damageId } = action.metaData.match.params
    yield put(showWaitingScreen('Assigning lawyer'))

    const lawfirm = action.payload.lawfirm
    const files = action.payload.files

    let documentIds = []

    if (files.length > 0) {
        for (const file of files) {
            const documentResponse = yield resourceCall(
                api.gateway.attachments.postAttachment,
                {
                    damageId: damageId
                },
                {
                    file: file.metaData
                }
            )

            documentIds.push(documentResponse.id)
        }
    }

    yield call(lawfirmSwitch, damageId, lawfirm.name, lawfirm.webakteId, documentIds)


    yield put(populateSharedLawfirm(true, {
        ...lawfirm,
        state: COMMISSION_STATE.PENDING
    }))
    yield put(showToast(action.metaData.successMsg))
    yield put(hideWaitingScreen())
}