import { connect } from 'react-redux'

import {SidebarDamageManagerComponent} from './component'

const mapStateToProps = (state) => {
    return {
        vehicle: state.pages.damageManager.shared.damage.vehicle,
        damageLoaded: state.pages.damageManager.shared.damageLoaded,
        damageLoading: state.pages.damageManager.shared.damageLoading,
        damageId: state.pages.damageManager.shared.damage.id,
        hasLawfirm: state.pages.damageManager.shared.hasLawfirm,
        lawfirmLoaded: state.pages.damageManager.shared.lawfirmLoaded,
        branchId: state.pages.damageManager.shared.damage.branchId,
        branchInfo: state.pages.damageManager.shared.branchInfo,
    }
}

export const SidebarDamageManager = connect(
    mapStateToProps
)(SidebarDamageManagerComponent)