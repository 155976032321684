import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from 'application/components/controls/action_button'
import { validationMethods } from 'application/common'
import { TextInput } from 'application/components/controls/form'
import { FlexBox } from 'application/components/fragments/flex_box'
import { YesNoSelect } from 'application/components/controls/form/yes_no_select'
import { useStatefulForm } from 'application/components/hooks'
import { TwoColumns } from 'application/components/forms/_common'
import { AccidentReportDescriptionSelectGroup } from 'application/components/building_blocks/accident_report_description_selectgroup'
import { ACCIDENT_REPORT_DESCRIPTION } from 'application/constants'
import { PushBottom16 } from 'application/components/pages/_common'
import { LimitedCharsTextArea } from 'application/components/controls/form/limited_chars/limited_chars_text_area'




const DamageAccidentReportForm = (
    {
        formData = {}, 
        damageId, 
        onSave
    }
) => {
    const {t} = useTranslation()

    const defaultValues = {
        location: {
            description: formData?.accidentReport?.location?.description || null,
        },
        circumstances: formData?.accidentReport?.circumstances || null,
        damageToPersons: formData?.accidentReport?.damageToPersons,
        description: {
            type: formData?.accidentReport?.description?.type || ACCIDENT_REPORT_DESCRIPTION.REAR_END_COLLISION,
            other: formData?.accidentReport?.description?.other ||  ''
        }
    }

    const {
        register, 
        errors, 
        handleSubmit, 
        getValues, 
        setValue,
        formStateValues,
        updateFormState,
        watch
    } = useStatefulForm({ defaultValues })

    const onSubmitSuccess = () => { 
        onSave(
            damageId, 
            getValues()
        )
    }
    
    return <React.Fragment>
        <TwoColumns
            columnLeft={
                <TextInput 
                    label={t('Accident location')}
                    name='location.description'
                    register={register}
                    validate={{notEmpty: validationMethods.notEmpty}}
                    error={errors.location?.description}
                />
            }
            columnRight={
                <YesNoSelect 
                    register={register}
                    setValue={setValue}
                    initialValue={formStateValues.damageToPersons}
                    name='damageToPersons'
                    label={t('Damage to person')}
                    onChange={updateFormState}
                />
            }          
        />
        <AccidentReportDescriptionSelectGroup
            register={register}
            setValue={setValue}
            onChange={updateFormState}
            errors={errors}
            name='description'
            typeName='type'
            otherName='other'
            typeValue={formStateValues.description.type}
            otherValue={formStateValues.description.other}
        />
        <PushBottom16 />
        <LimitedCharsTextArea
            watch={watch}
            label={t('Accident description (for lawfirm), (max 140 chars)')} 
            name='circumstances'
            register={register}
            errors={errors}
            additionalValidationRules={
                {notEmpty: validationMethods.notEmpty}
            }
            limit={ACCIDENT_REPORT_DESCRIPTION.OTHER_LIMIT}
        />         
        <FlexBox justifyContent='flex-end'>
            <ActionButton 
                buttonText={t('Save accident data')} 
                onButtonClick={handleSubmit(onSubmitSuccess)}
            />
        </FlexBox>
    </React.Fragment>
}

export { DamageAccidentReportForm }