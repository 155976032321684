import { getModules } from 'application/redux/selectors'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'

export const useModules = () => {
    const {
        accountModules,
        branchModules,
        loaded,
        loading
    } = useSelector(getModules)

    const isAccountModuleApplied = useCallback((moduleName) => {
        if(!loaded || loading){
            return false
        }
        return accountModules.find(m => m.name === moduleName)
    }, [accountModules, loaded, loading])

    const isBranchModuleApplied = useCallback((moduleName, branchId) => {
        if(!loaded || loading){
            return false
        }

        if(!branchModules.find(branch => branch.branchId === branchId)){
            return false
        }

        return branchModules.find(branch => branch.branchId === branchId).modules.find(m => m.name === moduleName)
    }, [branchModules, loaded, loading])

    const isBranchModuleAppliedInAnyBranch = useCallback((moduleName) => {
        if(!loaded || loading){
            return false
        }
        return branchModules.some(branch => branch.modules.find(module => module.name === moduleName))
    }, [branchModules, loaded, loading])

    const getBranchIdsForModule = useCallback((moduleName) => {
        return branchModules.reduce((accumulator, currentBranch) => {
            if(currentBranch.modules.find(module => module.name === moduleName)){
                accumulator.push(currentBranch.id)
            }
            return accumulator
        }, [])
    }, [branchModules])


    const getModuleSettings = useCallback((moduleName, branchId) => {
        if(!loaded || loading){
            return null
        }

        if(!branchModules.find(branch => branch.branchId === branchId)){
            return null
        }
        return branchModules.find(branch => branch.branchId === branchId).modules.find(m => m.name === moduleName)?.settings || null

    }, [branchModules, loaded, loading])

    return {
        modulesLoaded: loaded,
        modulesLoading: loading,
        isAccountModuleApplied,
        isBranchModuleApplied,
        isBranchModuleAppliedInAnyBranch,
        getBranchIdsForModule,
        getModuleSettings
    }
}