import styled from 'styled-components'


export const MaintainanceModeWrapper = styled.div`
    display: flex;
    max-width: 1024px;
    padding: 60px 20px 0;
    flex-direction: column;
    margin: 0 auto;
    align-items: flex-start;
    justify-content: space-between;
`

export const LanguageSwitchWrapper = styled.div`
    background-color: rgb(217, 217, 217);
    margin: 0 -10px;
    width: calc(100% + 20px);
    height: 40px;
`