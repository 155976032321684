import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export const StyledNoMessageInfo = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 40px;
    font-size: ${props => props.theme.fontSize.medium18};
    color: ${props => props.theme.color.anthracite};
`

export const NoMessageInfo = () => {
    const {t} = useTranslation()
    return <StyledNoMessageInfo className='no-message-info'>
        {t('No message selected')}
    </StyledNoMessageInfo>
}