import React from 'react'
import styled from 'styled-components'

export { Sidebar } from './sidebar'

export const VerticalContainer = styled.div`
    display: flex;
    flex-flow: column;
    height: 100%;
`

//whole content
export const Container = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    flex: 1 1 auto;
    overflow: ${props => props.$overflow ? props.$overflow : 'hidden'};
`

//left column
export const Main = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 0 16px;
    padding-left: ${props => props.paddingLeft !== undefined ? props.paddingLeft : '16px'};
    padding-right: ${props => props.paddingRight !== undefined ? props.paddingRight : '16px'};
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    & .sticky{
        background: ${props => props.theme.color.white};
        box-shadow: 0 6px 4px -4px ${props => props.theme.color.gray20};
        z-index: 2;
    }
`

//Headlinebar
export const MainHeader = styled.div`
    display: flex;
    flex-direction: row;
    color: ${props => props.theme.color.primary};
    font-size: ${props => props.theme.fontSize.headline1};
    font-weight: ${props => props.theme.fontSize.headLine1};
    padding: 12px 16px 0;
    align-items: center;
    justify-content: space-between;
`
//Table Dashboard
export const MainContent = styled.div`
background: blue;
    display: flex;
    flex: 1 1 auto;
    margin-top: 32px;
`

//<PushBottom48 /> can be used as filterbar placeholder:
export const PushBottom48 = styled.div` 
    margin-bottom: 48px;
`
export const PushBottom64 = styled.div` 
    margin-bottom: 64px;
`
export const PushBottom32 = styled.div`
    margin-bottom: 32px;
`
export const PushBottom24 = styled.div`
    margin-bottom: 24px;
`
export const PushBottom16 = styled.div`
    margin-bottom: 16px;
`
export const PushBottom8 = styled.div`
    margin-bottom: 8px;
`
export const PushBottom10 = styled.div`
    margin-bottom: 10px;
`
export const Spacer = styled.div`
    flex: ${props => props.basis !== undefined ? `0 0 ${props.basis}` : '1 1 auto'};
    width: ${props => props.width ? props.width : 'auto'};
    border-bottom: ${
    props => props.dotted 
        ? `1px dotted ${
            props.borderColor !== undefined 
                ? props.borderColor 
                : props.theme.color.gray20}` 
        : '0'
};
    margin: ${props => props.cssMargin ? props.cssMargin : '0'};
`

export const SubPage = styled.div`
    display: flex;
    flex-grow : 1;
    flex-direction: column;
    padding: 0;
    padding-top: ${props => props.paddingTop};
    overflow-y: auto;
    overflow-x: hidden;
    flex-flow: column;
`

export const BranchName = styled.div`
    color: ${ props => props.theme.color.primary};
`

export const PageAllBranchesHeadline = styled.div`
    color: ${ props => props.theme.color.anthracite};
`

export const ErrorWrapper = styled.div`
    padding: 64px 16px;
    text-align: center;
    font-size: ${props => props.theme.fontSize.headline1};
`

//to be used for sub pages under a tab navigation
export const SubPageLayout = ({ children, paddingLeft = '0', paddingRight = '0', paddingTop = '16px' }) => {
    return <SubPage paddingTop={paddingTop}>
        <Main 
            paddingLeft={paddingLeft} 
            paddingRight={paddingRight}
        >
            {children}
        </Main>
    </SubPage>
}

export const VisibilitySwitch = styled.div`
    visibility: ${props => props.hidden ? 'hidden' : 'visible'};
    display: ${props => props.hidden ? 'none' : props.displayType ? props.displayType : 'flex'};
    width: inherit;
    height: inherit;
    flex: 0 0 100%;
`