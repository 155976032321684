import { fetchRequestBuilder, fetchRequestBuilderPin, HTTP_METHOD } from 'application/api/request_builders'
import { apiCall } from 'application/api/helpers'

export const updateDamageLawfirmWebakte = (params) => (
    {
        authenticated: (payload) => {
            const request = fetchRequestBuilder(`damages/${params.damageId}/communications/lawfirm/webakte`, HTTP_METHOD.PUT, payload)
            return apiCall(request)
        },
        sharedResource: () => null,
        kickstart: (payload) => {
            const request = fetchRequestBuilderPin(`external/qr/${params.sharedResourceId}/damages/${params.damageId}/communications/lawfirm/webakte`, params.pin, HTTP_METHOD.PUT, payload)
            return apiCall(request)
        }
    }
)