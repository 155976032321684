import * as Pages from 'application/components/pages'
import { MODULE_NAME, ROUTES, SHOW_MAINTAINANCE_PAGE } from 'application/constants'
import { Navigate, Route, Routes } from 'react-router-dom'
import { AuthRedirect } from './auth_redirect'
import { ModuleRedirect } from './module_redirect'

export const RoutesComponent = () => {
    if(SHOW_MAINTAINANCE_PAGE){
        return <Routes>
            <Route path='*' element={<Pages.External.MaintananceModePage/>} />
        </Routes>
    }

    return <Routes>
        <Route  path={ROUTES.EXT_LOGIN} element={<Pages.External.WelcomeLogin/>} />
        <Route  path={ROUTES.SESSION_EXPIRED} element={<Pages.Errors.Internal.SessionExpiredPage />} />
        <Route  path={ROUTES.NO_PERMISSION} element={<Pages.Errors.Internal.NoPermissionsPage/>} />
        <Route  path={ROUTES.NO_ACCOUNTS} element={<Pages.Errors.Internal.NoAccountsPage/>} />
        <Route  path={ROUTES.SSO_CALLBACK} element={<Pages.External.SSOCallback/>} />
        <Route  path={ROUTES.SSO_SILENT_RENEW} element={<Pages.External.SSOSilentRenew/>} />
        <Route  path={ROUTES.LOAD} element={<Pages.External.Load/>} />
        <Route  path={ROUTES.LEGACY_DASHBOARD} element={<Navigate to={ROUTES.PREFERRED_ACCOUNT_PRELOADER} />} />
        <Route  path={ROUTES.PREFERRED_ACCOUNT_PRELOADER} element={<AuthRedirect><Pages.Internal.PreferredAccountPreloader/></AuthRedirect>} />
        <Route  path={ROUTES.CHOOSE_ACCOUNT} element={ <AuthRedirect><Pages.Internal.ChooseAccount /></AuthRedirect>} />
        <Route  path={ROUTES.ACCOUNT_PRELOADER} element={ <AuthRedirect><Pages.Internal.AccountPreloader /></AuthRedirect>} />
        <Route  path={ROUTES.DASHBOARD} element={ <AuthRedirect><Pages.Internal.Branches.AllBranches.Dashboard /></AuthRedirect>} />
        <Route  path={ROUTES.DAMAGES_OVERVIEW} element={ <AuthRedirect><Pages.Internal.Branches.AllBranches.DamagesOverview /></AuthRedirect>} />
        <Route  path={ROUTES.DAMAGES_OVERVIEW_MY_DAMAGES} element={ <AuthRedirect><Pages.Internal.Branches.AllBranches.DamagesOverview /></AuthRedirect>} />
        <Route  path={ROUTES.DAMAGES_OVERVIEW_ALL_DAMAGES} element={ <AuthRedirect><Pages.Internal.Branches.AllBranches.DamagesOverview /></AuthRedirect>} />
        <Route  path={ROUTES.DAMAGES_OVERVIEW_ARCHIVED_DAMAGES} element={ <AuthRedirect><Pages.Internal.Branches.AllBranches.DamagesOverview /></AuthRedirect>} />
        <Route  path={ROUTES.TASKS_OVERVIEW} element={ <AuthRedirect><Pages.Internal.Branches.AllBranches.TasksOverview /></AuthRedirect>} />
        <Route  path={ROUTES.TASKS_OVERVIEW_MY_TASKS} element={ <AuthRedirect><Pages.Internal.Branches.AllBranches.TasksOverview /></AuthRedirect>} />
        <Route  path={ROUTES.TASKS_OVERVIEW_ALL_TASKS} element={ <AuthRedirect><Pages.Internal.Branches.AllBranches.TasksOverview /></AuthRedirect>} />
        <Route  path={ROUTES.ALL_BRANCHES_INBOX} element={ <AuthRedirect><Pages.Internal.Branches.AllBranches.Inbox /></AuthRedirect>} />
        <Route  path={ROUTES.INBOX_PRESELECTED} element={ <AuthRedirect><Pages.Internal.Branches.AllBranches.Inbox /></AuthRedirect>} />
        <Route  path={ROUTES.ACTIVITY_LOG} element={ <AuthRedirect><Pages.Internal.Branches.AllBranches.ActivityLog /></AuthRedirect>} />
        <Route  path={ROUTES.BRANCH_SPECIFIC_ACTIVITY_LOG} element={ <AuthRedirect><Pages.Internal.Branches.CurrentBranch.ActivityLog /></AuthRedirect>} />
        <Route  path={ROUTES.CONTROLLING} element={ <AuthRedirect><Pages.Internal.Branches.AllBranches.Controlling /></AuthRedirect>} />
        <Route  path={ROUTES.CONTROLLING_INVOICES} element={ <AuthRedirect><Pages.Internal.Branches.AllBranches.Controlling /></AuthRedirect>} />
        <Route  path={ROUTES.CONTROLLING_STATISTICS} element={ <AuthRedirect><Pages.Internal.Branches.AllBranches.Controlling /></AuthRedirect>} />
        <Route  path={ROUTES.CONTROLLING_DAMAGES} element={ <AuthRedirect><Pages.Internal.Branches.AllBranches.Controlling /></AuthRedirect>} />
        <Route  path={ROUTES.BRANCH_SPECIFIC_DASHBOARD} element={ <AuthRedirect><Pages.Internal.Branches.CurrentBranch.Dashboard /></AuthRedirect>} />
        <Route  path={ROUTES.BRANCH_SPECIFIC_DAMAGES_OVERVIEW} element={ <AuthRedirect><Pages.Internal.Branches.CurrentBranch.DamagesOverview /></AuthRedirect>} />
        <Route  path={ROUTES.BRANCH_SPECIFIC_DAMAGES_OVERVIEW_MY_DAMAGES} element={ <AuthRedirect><Pages.Internal.Branches.CurrentBranch.DamagesOverview /></AuthRedirect>} />
        <Route  path={ROUTES.BRANCH_SPECIFIC_DAMAGES_OVERVIEW_ALL_DAMAGES} element={ <AuthRedirect><Pages.Internal.Branches.CurrentBranch.DamagesOverview /></AuthRedirect>} />
        <Route  path={ROUTES.BRANCH_SPECIFIC_DAMAGES_OVERVIEW_ARCHIVED_DAMAGES} element={ <AuthRedirect><Pages.Internal.Branches.CurrentBranch.DamagesOverview /></AuthRedirect>} />
        <Route  path={ROUTES.BRANCH_SPECIFIC_TASKS_OVERVIEW} element={ <AuthRedirect><Pages.Internal.Branches.CurrentBranch.TasksOverview /></AuthRedirect>} />
        <Route  path={ROUTES.BRANCH_SPECIFIC_TASKS_OVERVIEW_MY_TASKS} element={ <AuthRedirect><Pages.Internal.Branches.CurrentBranch.TasksOverview /></AuthRedirect>} />
        <Route  path={ROUTES.BRANCH_SPECIFIC_TASKS_OVERVIEW_ALL_TASKS} element={ <AuthRedirect><Pages.Internal.Branches.CurrentBranch.TasksOverview /></AuthRedirect>} />
        <Route  path={ROUTES.BRANCH_SPECIFIC_INBOX} element={ <AuthRedirect><Pages.Internal.Branches.CurrentBranch.Inbox /></AuthRedirect>} />
        <Route  path={ROUTES.BRANCH_SPECIFIC_INBOX_PRESELECTED} element={ <AuthRedirect><Pages.Internal.Branches.CurrentBranch.Inbox /></AuthRedirect>} />
        <Route  path={ROUTES.BRANCH_SPECIFIC_CONTROLLING} element={ <AuthRedirect><Pages.Internal.Branches.CurrentBranch.Controlling /></AuthRedirect>} />
        <Route  path={ROUTES.BRANCH_SPECIFIC_CONTROLLING_INVOICES} element={ <AuthRedirect><Pages.Internal.Branches.CurrentBranch.Controlling /></AuthRedirect>} />
        <Route  path={ROUTES.BRANCH_SPECIFIC_CONTROLLING_STATISTICS} element={ <AuthRedirect><Pages.Internal.Branches.CurrentBranch.Controlling /></AuthRedirect>} />
        <Route  path={ROUTES.DAMAGE_MANAGER} element={ <AuthRedirect><Pages.Internal.DamageManager /></AuthRedirect>} />
        <Route  path={ROUTES.DAMAGE_MANAGER_OVERVIEW} element={ <AuthRedirect><Pages.Internal.DamageManager /></AuthRedirect>} />
        <Route  path={ROUTES.DAMAGE_MANAGER_TASKS} element={ <AuthRedirect><Pages.Internal.DamageManager /></AuthRedirect>} />
        <Route  path={ROUTES.DAMAGE_MANAGER_TASKS_PRESELECTED} element={ <AuthRedirect><Pages.Internal.DamageManager /></AuthRedirect>} />
        <Route  path={ROUTES.DAMAGE_MANAGER_DOCUMENTS} element={ <AuthRedirect><Pages.Internal.DamageManager /></AuthRedirect>} />
        <Route  path={ROUTES.DAMAGE_MANAGER_LAWYER_COMMUNICATION} element={ <AuthRedirect><Pages.Internal.DamageManager /></AuthRedirect>} />
        <Route  path={ROUTES.DAMAGE_MANAGER_INVOICES} element={ <AuthRedirect><Pages.Internal.DamageManager /></AuthRedirect>} />
        <Route  path={ROUTES.DAMAGE_MANAGER_INBOX} element={ <AuthRedirect><Pages.Internal.DamageManager /></AuthRedirect>} />
        <Route  path={ROUTES.DAMAGE_MANAGER_INBOX_PRESELECTED} element={ <AuthRedirect><Pages.Internal.DamageManager /></AuthRedirect>} />

        <Route  path={ROUTES.ADMINISTRATION_USERS} element={ <AuthRedirect><Pages.Internal.Users /></AuthRedirect>} />
        <Route  path={ROUTES.ADMINISTRATION_SETTINGS} element={ <AuthRedirect><Pages.Internal.Settings /></AuthRedirect>} />
        <Route  path={ROUTES.ADMINISTRATION_SETTINGS_CATEGORIES} element={ <AuthRedirect><Pages.Internal.Settings /></AuthRedirect>} />
        <Route  path={ROUTES.ADMINISTRATION_SETTINGS_DAMAGE_STATES} element={ <AuthRedirect><Pages.Internal.Settings /></AuthRedirect>} />
        <Route  path={ROUTES.ADMINISTRATION_SETTINGS_ACCOUNT_DATA} element={ <AuthRedirect><Pages.Internal.Settings /></AuthRedirect>} />
        <Route  path={ROUTES.ADMINISTRATION_SETTINGS_ACCOUNT_LOGO} element={ <AuthRedirect><Pages.Internal.Settings /></AuthRedirect>} />
        <Route  path={ROUTES.ADMINISTRATION_SETTINGS_UI_SETTINGS} element={ <AuthRedirect><Pages.Internal.Settings /></AuthRedirect>} />

        <Route  path={ROUTES.ADMINISTRATION_SETTINGS_COMPREHENSIVE_DAMAGE_TYPES} element={ <AuthRedirect><Pages.Internal.Settings /></AuthRedirect>} />

        <Route  path={ROUTES.ADMINISTRATION_SETTINGS_CONTRACTORS_UI} element={ <AuthRedirect><Pages.Internal.Settings /></AuthRedirect>} />

        <Route  path={ROUTES.CUSTOMERS} element={ <AuthRedirect><Pages.Internal.CustomersOverview /></AuthRedirect>} />
        <Route  path={ROUTES.CUSTOMERS_PRIVATE} element={ <AuthRedirect><Pages.Internal.CustomersOverview /></AuthRedirect>} />
        <Route  path={ROUTES.CUSTOMERS_BUSINESS} element={ <AuthRedirect><Pages.Internal.CustomersOverview /></AuthRedirect>} />
        <Route  path={ROUTES.CUSTOMERS_BULK_IMPORT} element={ <AuthRedirect><ModuleRedirect requiredModule={MODULE_NAME.BATCH_CUSTOMER_IMPORT}><Pages.Internal.CustomersOverview /></ModuleRedirect></AuthRedirect>} />

        <Route  path={ROUTES.CHANGELOG} element={ <AuthRedirect><Pages.Internal.Changelog /></AuthRedirect>} />
        <Route  path={ROUTES.BRANCH_MANAGEMENT_GROUP_MEMBERS} element={ <AuthRedirect><Pages.Internal.BranchManagementGroupMembers /></AuthRedirect>} />
        <Route  path={ROUTES.BRANCH_MANAGEMENT} element={ <AuthRedirect><Pages.Internal.BranchManagement /></AuthRedirect>} />
        <Route  path={ROUTES.DAMAGE_NOT_FOUND}  element={<AuthRedirect><Pages.Errors.Internal.DamageNotFoundPage /></AuthRedirect>} />
        <Route  path={ROUTES.EXT_REGISTRATION_CALLBACK} element={<Pages.External.RegistrationCallback/>} />
        <Route  path={ROUTES.EXT_REGISTRATION} element={<Pages.External.Registration/>} />
        <Route  path={ROUTES.EXT_ACCOUNT_REGISTRATION_CALLBACK} element={<Pages.External.AccountRegistrationCallback/>} />
        <Route  path={ROUTES.EXT_ACCOUNT_REGISTRATION} element={<Pages.External.AccountRegistration/>} />
        <Route  path={ROUTES.SHARED_RESOURCE_INVALID} element={<Pages.Errors.External.SharedResourceInvalid/>} />
        <Route  path={ROUTES.EXT_SHARED_RESOURCE_LOGIN} element={<Pages.External.SharedResourceLogin/>} />
        <Route  path={ROUTES.EXT_SHARED_RESOURCE_SWITCH} element={<Pages.External.SharedResourceTypeSwitch/>} />
        <Route  path={ROUTES.EXT_CREATE_DAMAGE} element={<Pages.External.CreateDamageExternal/>} />
        <Route  path={ROUTES.EXT_KICKSTART} element={<Pages.External.KickstartExternal/>} />

        {/* <Route  path={ROUTES.EXT_WORKFLOW_PANEL} element={<Pages.External.Lawfirm/>} />
        <Route  path={ROUTES.EXT_WORKFLOW_PANEL_WITH_SUBROUTES} element={<Pages.External.Lawfirm/>}/>
        <Route  path={ROUTES.EXT_WORKFLOW_PANEL_WITH_SUBROUTES_ID} element={<Pages.External.Lawfirm/>} /> */}

        <Route  path={ROUTES.EXT_WORKFLOW_PANEL} element={<Pages.External.Lawfirm/>} />
        <Route  path={ROUTES.EXT_WORKFLOW_PANEL_FORM} element={<Pages.External.Lawfirm/>} />
        <Route  path={ROUTES.EXT_WORKFLOW_PANEL_TASKS} element={<Pages.External.Lawfirm/>} />
        <Route  path={ROUTES.EXT_WORKFLOW_PANEL_TASKS} element={<Pages.External.Lawfirm/>} />
        <Route  path={ROUTES.EXT_WORKFLOW_PANEL_TASKS_PRESELECTED} element={<Pages.External.Lawfirm/>} />
        <Route  path={ROUTES.EXT_WORKFLOW_PANEL_MESSAGE} element={<Pages.External.Lawfirm/>} />
        <Route  path={ROUTES.EXT_WORKFLOW_PANEL_MESSAGE_PRESELECTED} element={<Pages.External.Lawfirm/>} />
        <Route  path={ROUTES.EXT_WORKFLOW_PANEL_ACTIVITY_LOG} element={<Pages.External.Lawfirm/>} />
        <Route  path={ROUTES.EXT_WORKFLOW_PANEL_DOCUMENTS} element={<Pages.External.Lawfirm/>} />

        <Route  path={ROUTES.EXT_NOT_FOUND} element={<Pages.Errors.External.NotFoundPage/>} />
        <Route  path={ROUTES.GENERIC_ERROR} element={<Pages.Errors.External.GenericError/>} />
        <Route  element={Pages.Errors.External.NotFoundPage} />
    </Routes>
}