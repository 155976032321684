import React from 'react'

import { InsuranceInputGroup } from 'application/modules/dynamic-form/controls/insurance_input_group'
import { TextInput } from 'application/modules/dynamic-form/controls/text_input'
import { _get } from './util'
import { useTranslation } from 'react-i18next'
import { validationMethods } from 'application/common'

export const InsuranceFields = ({ description, formHook }) => {
    const {t} = useTranslation()
    const {
        register,
        setValue,
        errors,
        formStateValues,
        setFormStateValues,
        reset,
        updateFormState,
    } = formHook

    return <div style={description._config?.css}>
        <InsuranceInputGroup
            register={register}
            setValue={setValue}
            formStateValues={formStateValues}
            updateFormState={updateFormState}
            errors={errors}
            insuranceSelectInputName={description.nameSelect}
            insuranceTextName = {description.nameText}
            insuranceSelectInputErrors={_get(errors, description.nameSelect)}
            insuranceTextErrors={_get(errors, description.nameText)}
            setFormStateValues={setFormStateValues}
            reset={reset}
            formTitle=''
            menuPlacement='top'
            validate={{notEmpty: validationMethods.notEmpty}}
        />
        <TextInput
            label={t('Insurance certificate number')}
            name='liabilityData.liabilityInsurance.certificateNumber'
            register={register}
            error={_get(errors, 'liabilityData.liabilityInsurance.certificateNumber')}
            onChange={updateFormState}
        />
    </div>
}
