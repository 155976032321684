import { ROUTES } from 'application/constants'
import React, {useEffect} from 'react'
import { Navigate } from 'react-router-dom'
import {DamageManagerComponent} from './component'
import {PagePreloader} from 'application/components/building_blocks/page_preloader'
import { useTranslation } from 'react-i18next'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'
import { useParams } from 'react-router-dom'




export const DamageManagerEffects = (props) => {
    const {t} = useTranslation()

    const { 
        onLoadDamage,
        damage = null,
        damageLoaded = false,
        damageLoading = false,
        accessibleBranchesLoaded
    } = props

    const { damageId } = useParams()
    const loadedDamageId = damage?.id || ''
    const clickedOnNewDamage = loadedDamageId !== damageId 

    const {
        internalAccountLink
    } = useApplicationLinks()

    useEffect(
        () => {
            if(clickedOnNewDamage && accessibleBranchesLoaded){
                onLoadDamage(damageId)
            }
             
        }, [clickedOnNewDamage, damageId, onLoadDamage, accessibleBranchesLoaded]
    )

    if (damageLoaded && !damageLoading) {
        if (damage === null) {
            return <Navigate 
                to={
                    internalAccountLink(
                        ROUTES.DAMAGE_NOT_FOUND
                    )
                }
            />  
        }
        return <DamageManagerComponent {...props}>
            {props.children}
        </DamageManagerComponent>
    }

    return <PagePreloader 
        text={t('Damagedata is loading...')} 
    />
}