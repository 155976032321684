import React from 'react'
import uniqid from 'uniqid'
import { ValidationErrorMessage, RequiredFieldMarker, FormElementWrapper } from 'application/modules/dynamic-form/controls/_common'
import { TextInputPureComponent } from './pure_component'
import { useRegister } from 'application/modules/dynamic-form/hooks/use_register'

export const TextInput = ({
    label,
    name,
    register,
    onKeyDown = () => {},
    onKeyDownEnter = () => {},
    validate = {},
    error,
    hideValidationMessage = false,
    defaultValue='',
    inputType='text',
    errorMessageOverride='',
    onChange=()=>{},
    cssWidth = '100%',
    cssMargin='0',
    autoFocus=false,
    disabled = false,
    readOnly = false,
    placeholder = ''
} ) => {
    const htmlId = uniqid()

    const {
        onRegisterChange,
        registerProps
    } = useRegister(register, name, validate, onChange)

    return <FormElementWrapper
        cssWidth={cssWidth}
        cssMargin={cssMargin}
        disabled={disabled}
    >
        {inputType !== 'hidden' && (
            <label
                className='label'
                htmlFor={htmlId}
            >
                {label}
                <RequiredFieldMarker
                    validate={validate}
                />
            </label>
        )}
        <TextInputPureComponent
            autoFocus={autoFocus}
            onKeyDown={onKeyDown}
            onKeyDownEnter={onKeyDownEnter}
            htmlId={htmlId}
            defaultValue={defaultValue}
            inputType={inputType}
            onChange={onRegisterChange}
            disabled={disabled}
            readOnly={readOnly}
            placeholder={placeholder}
            {...registerProps}
        />
        <ValidationErrorMessage
            hideValidationMessage={hideValidationMessage}
            error={error}
            errorMessageOverride={errorMessageOverride}
        />
    </FormElementWrapper>
}