import  * as BRANCH_MANAGEMENT from './branch_management'
export {ACTIONS} from './actions'
export {WIDGET_TYPES, WIDGET_CONTEXTS} from './widgets'
export {USER_PERMISSONS, PERMISSION_SCOPE} from './permissions'
export {ROUTES, ROUTE_PARAMETERS} from './routes'
export {STORAGE} from './storage'
export {NOTIFICATION} from './notifications'
export {DIALOG, DIALOG_AUTO_LOADER_PARAMS} from './dialogs'
export {TASK_STATES, TASK_PRIORITIES} from './task'
export {DAMAGE_TYPE, REPAIR_AUTHORIZATION_TYPE} from './damage'
export {ICONS} from './icons'
export {IMAGES} from './images'
export {STATISTICS} from './controlling'
export {ACTIVITY_TYPE, ACTIVITY_RELATED_ENTITY_TYPES} from './activities'
export {INFOBOX} from './infobox'
export {MASS_OPERATIONS} from './mass_operations'
export {BRANCH_MANAGEMENT}
export {UPDATE_CONSTRAINTS} from './update_constraints'
export {SHARED_RESOURCE_TYPE} from './shared_resource'
export {INVOICE_TYPE} from './invoice'
export {CASE_STATUS, COMMISSION_STATUS, LIABILITY_INQUIRY_INFO, LIABILITY_INQUIRY_STATUS} from './lawfirm_communication'
export {ROLE} from './role'
export {REALTIME_NOTIFICATION_TYPE, REALTIME_NOTIFICATION_CHANNEL} from './realtime_notifications'
export {EXPERT_TYPE} from './expert_type'
export {COMMISSION_STATE} from './commission'
export {FILTERS} from './filters'
export {SETTINGS} from './settings'
export {ACCIDENT_REPORT_DESCRIPTION} from './accident_report_description'
export {LIFECYCLE_STATES} from './damage_states'
export {DATE_INPUT_TYPES} from './date_input_types'
export {MODULE_NAME, MODULE_SCOPE} from './modules'
export {CUSTOMER_ORIGIN} from './customer_origin'
export {DASHBOARD_CONFIGURATION} from './dashboard_configuration'
export {MESSAGE_ORIGIN} from './message_origin'

export const I18N = {
    LANGUAGES: ['de-DE', 'en-UK'],
    LANGUAGE_DETECTION_OPTIONS: {
        // order and from where user language should be detected
        order: ['localStorage', 'navigator'],

        // keys or params to lookup language from
        lookupLocalStorage: 'i18nextLng',

        // cache user language on
        caches: ['localStorage'],
        excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

        // optional expire and domain for set cookie
        cookieMinutes: 10,
        cookieDomain: 'myDomain',

        // optional htmlTag with lang attribute, the default is:
        htmlTag: document.documentElement
    }
}

export const PDF_JS_WORKER_URL = `${window.location.origin}/scripts/pdf.worker.min.js?v=212`
export const ADVANCED_ERROR_DISPLAY = parseInt(process.env.REACT_APP_ADVANCED_ERROR_DISPLAY) === 1 ?? false
export const SHOW_MAINTAINANCE_PAGE = parseInt(process.env.REACT_APP_SHOW_MAINTAINANCE_PAGE) === 1 ?? false
export {PROVIDED_SERVICES} from './provided_services'
export {CASEFILE} from './case_file'
export { BREAKPOINTS } from './breakpoints'