import { ActionButton } from 'application/components/controls/action_button'
import { RepairSchedulePartialForm } from 'application/components/forms/_partial_forms'
import { FlexBox } from 'application/components/fragments/flex_box'
import { useStatefulForm } from 'application/components/hooks'
import { useTranslation } from 'react-i18next'

const DamageRepairScheduleForm = (
    {
        formData = {}, 
        damageId, 
        onSave
    }
) => {
    const {t} = useTranslation()

    const defaultValues = {
        sparePartsOrderedAt: null,
        sparePartsReceivedAt: null,
        shippedToBodyShopAt: null,
        shippedToPaintShopAt: null,
        notes: '',
        appraisalReceivedAt: null,
        inspectedAt: null,
        ...formData
    }

    const {
        register, 
        errors,
        handleSubmit, 
        getValues, 
        setValue, 
        control, 
        formStateValues, 
        updateFormState,
        trigger,
        watch
    } = useStatefulForm({defaultValues})

    

    const onSubmitSuccess = () => { 
        onSave(damageId, getValues())
    }

    return <FlexBox flexDirection='column'>
        <RepairSchedulePartialForm
            watch={watch}
            register={register}
            errors={errors}
            setValue={setValue}
            formStateValues={formStateValues}
            updateFormState={updateFormState}
            control={control}
            trigger={trigger}
        />
        <FlexBox justifyContent='flex-end'>
            <ActionButton 
                buttonText={t('Save repair schedule')} 
                onButtonClick={handleSubmit(onSubmitSuccess)} 
            />
        </FlexBox>
    </FlexBox>

}

export { DamageRepairScheduleForm }

