import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

const MessageDetailsRowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    padding-right: 12px;
`

const MessageDetailsWrapper = styled.div`
    background-color: ${props => props.theme.color.white};
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    padding: 0 16px 60px;
    flex-grow: 1;
`

const StyledLink = styled(NavLink)`
    color: ${props => props.theme.color.primary};
    display: flex;
    align-items: center;
    text-decoration: none;
`

const MessageSender = styled.p`
    margin: 0;
    color: ${(props) => props.theme.color.black};
    font-size: ${(props) => props.theme.fontSize.medium18};
    font-weight: ${(props) => props.theme.fontWeight.medium18};
`

const MessageReceiver = styled.p`
    display: inline;
    color: ${(props) => props.theme.color.gray70};
    font-size: ${(props) => props.theme.fontSize.medium};
    font-weight: ${(props) => props.theme.fontWeight.medium};
`

const MessageTime = styled.p`
    margin: 5px 0;
    color: ${(props) => props.theme.color.gray70};
    font-size: ${(props) => props.theme.fontSize.small};
    font-weight: ${(props) => props.theme.fontWeight.small};
`

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: 12px 16px 0;
`
const SubHeaderWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    color: ${(props) => props.theme.color.gray70};
    font-size: ${(props) => props.theme.fontSize.small};
    font-weight: ${(props) => props.theme.fontWeight.small};
    padding: 0 24px 0;
    min-height: 16px;
`

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    margin-bottom: 8px;
    padding: 0 16px 0;
    flex-wrap: wrap;
`

const HeaderLeft = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: center;
`

const HeaderRight = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    flex-basis: 30%;
`

const MessageBodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 8px;
    padding: 24px;
    background: ${(props) => props.theme.color.gray5};
`

const MessageBody = styled.div`
    color: ${(props) => props.theme.color.gray70};
    font-size: ${(props) => props.theme.fontSize.medium};
    font-weight: ${(props) => props.theme.fontWeight.medium18};
    line-height: 1.5;
    white-space: pre-wrap;
`

const MessageAttachmentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 8px;
    padding: 16px;
    gap: 8px;
`

const MessageAttachment = styled.div`
    color: ${(props) => props.theme.color.gray20};
    font-size: ${(props) => props.theme.fontSize.medium18};
    font-weight: ${(props) => props.theme.fontWeight.medium18};
    border: 1px solid ${(props) => props.theme.color.gray20};
    border-radius: 3px;
    background: ${(props) => props.theme.color.white};
`

export {
    ButtonWrapper, HeaderLeft,
    HeaderRight, HeaderWrapper, MessageAttachment, MessageAttachmentWrapper, MessageBody, MessageBodyWrapper, MessageDetailsRowWrapper, MessageDetailsWrapper, MessageReceiver, MessageSender, MessageTime, StyledLink, SubHeaderWrapper
}

