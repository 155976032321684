import React from 'react'
import { PermissionRedirectStrategy } from 'application/components/controls/permission_redirect_stragegy'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'
import { ROUTES, USER_PERMISSONS } from 'application/constants'



export const RedirectStrategy = () => {
    const {
        internalAccountLink
    } = useApplicationLinks()
    
    return <PermissionRedirectStrategy
        strategy={[
            {
                href: internalAccountLink(
                    ROUTES.ADMINISTRATION_SETTINGS_ACCOUNT_DATA
                ),
                permissions: [
                    USER_PERMISSONS.ACCOUNT.UPDATE
                ]
            },
            {
                href: internalAccountLink(
                    ROUTES.ADMINISTRATION_SETTINGS_ACCOUNT_LOGO
                ),
                permissions: [
                    USER_PERMISSONS.ACCOUNT.UPDATE
                ]
            },
            {
                href: internalAccountLink(
                    ROUTES.ADMINISTRATION_SETTINGS_CATEGORIES
                ),
                permissions: [
                    USER_PERMISSONS.DOCUMENT_CATEGORY.CREATE,
                    USER_PERMISSONS.DOCUMENT_CATEGORY.UPDATE,
                    USER_PERMISSONS.DOCUMENT_CATEGORY.DELETE
                ]
            },
            {
                href: internalAccountLink( 
                    ROUTES.ADMINISTRATION_SETTINGS_DAMAGE_STATES
                ),
                permissions: [
                    USER_PERMISSONS.DAMAGE_STATE.CREATE,
                    USER_PERMISSONS.DAMAGE_STATE.UPDATE,
                    USER_PERMISSONS.DAMAGE_STATE.DELETE
                ]
            },
            {
                href: internalAccountLink( 
                    ROUTES.ADMINISTRATION_SETTINGS_COMPREHENSIVE_DAMAGE_TYPES
                ),
                permissions: [
                    USER_PERMISSONS.COMPREHENSIVE_DAMAGE_TYPE.CREATE,
                    USER_PERMISSONS.COMPREHENSIVE_DAMAGE_TYPE.UPDATE,
                    USER_PERMISSONS.COMPREHENSIVE_DAMAGE_TYPE.DELETE
                ]
            },            
            {
                href: internalAccountLink( 
                    ROUTES.ADMINISTRATION_SETTINGS_CONTRACTORS_UI
                ),
                permissions: [
                    USER_PERMISSONS.BRANCH.UPDATE,
                    USER_PERMISSONS.BILLING.UPDATE_BRANCH_CONTRACTORS
                ]
            },
            {
                href: internalAccountLink(
                    ROUTES.ADMINISTRATION_SETTINGS_UI_SETTINGS
                ),
                permissions: [] //every user has at least the permission to edit his own user ui settings
            }
        ]}
    />
}