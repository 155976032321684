import React from 'react'
import {PROVIDED_SERVICES} from 'application/constants'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { CheckBoxGroupWithFreetextField } from 'application/components/controls/form'




const ProvidedServicesCheckboxGroup = (
    {
        register,
        errors,
        onChange,
        trigger,
        setValue,
        watch,
        name='providedServices',
        limit=80
    }
) => {

    const {t} = useTranslation()

    const options = [
        {
            label: t(PROVIDED_SERVICES.FLATRATE_DAMAGE),
            value: PROVIDED_SERVICES.FLATRATE_DAMAGE
        },
        {
            label: t(PROVIDED_SERVICES.RENTAL_CAR),
            value: PROVIDED_SERVICES.RENTAL_CAR
        },
        {
            label: t(PROVIDED_SERVICES.ACCIDENT_VEHICLE_TOW),
            value: PROVIDED_SERVICES.ACCIDENT_VEHICLE_TOW
        },
        {
            label: t(PROVIDED_SERVICES.MERCANTILE_DEPRECATION),
            value: PROVIDED_SERVICES.MERCANTILE_DEPRECATION
        }
    ]

    return  <CheckBoxGroupWithFreetextField
        options = {options}
        register = {register}
        errors = {errors}
        checkBoxGroupName = {name}
        onChange={onChange}
        watch={watch}
        trigger={trigger}
        setValue={setValue}
        freeTextName='providedServicesOther'
        freeTextConstant={PROVIDED_SERVICES.OTHER}
        limit={limit}
    />
}


ProvidedServicesCheckboxGroup.propTypes = {
    register: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    watch: PropTypes.func.isRequired,
    trigger: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    name: PropTypes.string,
    limit: PropTypes.number
}

export {ProvidedServicesCheckboxGroup}