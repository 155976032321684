import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DatePicker } from 'application/components/controls/form'
import { validationMethods } from 'application/common'
import { DialogMainContentScrollable, DialogSidebarScrollAble } from 'application/components/fragments/dialog'
import { TextArea } from 'application/components/controls/form'
import { FooterWithSpacer } from 'application/components/dialogs/_common/footer_with_spacer'
import {withRouter} from 'application/components/higher_order_components/with_router'
import { CurrencyInput } from 'application/components/controls/form/currency_input'
import { FlexDividerVertical } from 'application/components/fragments/flex_divider'
import { InvoiceInfo, InvoiceItems } from 'application/components/building_blocks/invoice_meta'
import { PushBottom32 } from 'application/components/pages/_common'
import { FlexBox } from 'application/components/fragments/flex_box'
import { PartialForms } from 'application/components/forms'
import { useStatefulForm } from 'application/components/hooks/use_stateful_form'
import { YesNoSelect } from 'application/components/controls/form/yes_no_select'
import { withDialogDataLoader } from 'application/components/building_blocks/dialog_data_loader'
import { withCategoriesLoader } from 'application/components/higher_order_components'
import { compose } from 'redux'
import { CURRENCY_INPUT } from 'application/constants/currency'



const CreatePayment = (
    {
        abort,
        setAbortBlocked,
        setDataChanged,
        onCreatePayment,
        dialog,
        categories
    }
) => {
    const { t } = useTranslation()

    const defaultValues = {
        reduction: false,
        amount: CURRENCY_INPUT.ZERO_VALUE_STRING.FLOAT,
        payedAt: null,
        description: '',
        reductionReasons: {
            selected: 0,
            checkBoxes: null
        }
    }

    const defaultCategoryId = categories.find(c => c.isDefault)?.id || null

    const {
        register,
        errors,
        handleSubmit,
        getValues,
        control,
        setValue,
        formStateValues,
        updateFormState,
        trigger,
        watch
    } = useStatefulForm({ defaultValues })

    const dialogData = dialog.payload
    const { payments, parentId } = dialogData


    const onDataChange = () => {
        setAbortBlocked(true)
        setDataChanged(true)
        updateFormState()
    }

    const [uploadedFiles, setUploadedFiles] = useState([])


    const onSubmitSuccess = () => {
        const metaData = {
            damageId: parentId,
            invoiceId: dialogData.id,
            categoryId: defaultCategoryId,
            dialogToOpenData: dialogData?.dialogToOpenData || null,
            shouldOpenDialog: dialogData?.shouldOpenDialog || false
        }

        const payload = {
            ...getValues(),
            uploadedFiles
        }

        onCreatePayment(
            dialog.name,
            payload,
            metaData
        )
    }

    return <React.Fragment>
        <FlexBox
            flexDirection='column'
            flexBasis='100%'
        >
            <FlexBox>
                <DialogMainContentScrollable>
                    <FlexBox flexDirection='row'>
                        <FlexBox flexBasis='49%'>
                            <CurrencyInput
                                name='amount'
                                register={register}
                                error={errors.amount}
                                label={t('Amount in €')}
                            />
                        </FlexBox>
                        <FlexBox
                            flexBasis='49%'
                            marginLeft='2%'
                        >
                            <DatePicker
                                label={t('Payment date')}
                                name='payedAt'
                                validate={
                                    {
                                        notEmpty: validationMethods.notEmpty
                                    }
                                }
                                error={errors.payedAt}
                                onDateChange={onDataChange}
                                control={control}
                                disabledDays={
                                    {
                                        before: new Date(dialogData.issuedAt),
                                        after: new Date()
                                    }
                                }
                                register={register}
                                defaultValue={formStateValues.payedAt}
                                setValue={setValue}
                                trigger={trigger}
                            />
                        </FlexBox>
                    </FlexBox>
                    <YesNoSelect
                        register={register}
                        setValue={setValue}
                        initialValue={formStateValues.reduction}
                        name='reduction'
                        label={t('With reduction')}
                        onChange={updateFormState}
                    />
                    <PushBottom32 />
                    {
                        formStateValues.reduction
                            ? <PartialForms.PaymentReductionPartialForm
                                register={register}
                                errors={errors}
                                files={uploadedFiles}
                                watch={watch}
                                setFiles={setUploadedFiles}
                                onDataChange={onDataChange}
                                checkBoxGroupName='reductionReasons'
                                freeTextName='description'
                                setValue={setValue}
                                trigger={trigger}
                            />
                            : <TextArea
                                label={t('Description')}
                                name='description'
                                register={register}
                                validate={null}
                                error={errors.description}
                                onChange={onDataChange}
                            />
                    }
                </DialogMainContentScrollable>
                <FlexDividerVertical />
                <DialogSidebarScrollAble cssFlex='1 1 22%'>
                    <FlexBox
                        flexDirection='column'
                    >
                        <InvoiceInfo invoiceData={dialogData} />
                        <PushBottom32 />
                        <InvoiceItems
                            invoiceData={dialogData}
                            payments={payments}
                        />
                    </FlexBox>
                </DialogSidebarScrollAble>
            </FlexBox>
            <FooterWithSpacer
                onAbort={abort}
                onSubmit={handleSubmit(onSubmitSuccess)}
                submitText='Add payment'
            />
        </FlexBox>
    </React.Fragment>
}

export const CreatePaymentComponent = compose(
    withDialogDataLoader,
    withRouter,
    withCategoriesLoader
)(CreatePayment)