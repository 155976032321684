import { LanguageSwitchHoc } from 'application/components/building_blocks/language_switch/hoc'
import { LanguageSwitchWrapper, MaintainanceModeWrapper } from 'application/components/pages/external/maintainance_mode/fragments'
import { GlobalStyle } from 'application/style'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IMAGES } from 'application/constants'
import { Image } from 'application/components/controls/image'
import { Headline1, Paragraph } from 'application/components/fragments/typography'
import { PushBottom24 } from 'application/components/pages/_common'

export const MaintananceModePage = () => {
    const {t} = useTranslation()
    return <React.Fragment>
        <GlobalStyle />
        <MaintainanceModeWrapper>
            <div>
                <Image data={IMAGES.LOGO_72} />
                <PushBottom24 />
                <Headline1>{t('Maintanance Mode')}</Headline1>
                <PushBottom24 />
                <Paragraph>
                    {t('We are currently performing maintenance on our platform.')}
                </Paragraph>
                <Paragraph>
                    {t('Please try again in a few minutes.')}
                </Paragraph>
            </div>
            <LanguageSwitchWrapper>
                <LanguageSwitchHoc switchCondition={true}/>
            </LanguageSwitchWrapper>
        </MaintainanceModeWrapper>
    </React.Fragment>
}