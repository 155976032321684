import { fetchRequestBuilder, fetchRequestBuilderPin, HTTP_METHOD } from 'application/api/request_builders'
import { apiCall } from 'application/api/helpers'

export const getBranchContractors = (params) => {
    return {
        authenticated: () => {
            const request = fetchRequestBuilder(
                `billingbranches/${params.branchId}`, 
                HTTP_METHOD.GET
            )
            return apiCall(request)
        },
        sharedResource: () => null,    
        kickstart:  () => {
            const request = fetchRequestBuilderPin(
                `external/qr/${params.sharedResourceId}/availableContractors`, 
                params.pin, 
                HTTP_METHOD.GET
            )
            return apiCall(request, [500])
        }
    }
}

