import React from 'react'
import uniqid from 'uniqid'
import { ValidationErrorMessage, RequiredFieldMarker } from 'application/components/controls/form/_common'
import { DisabledDatePicker, Row, StyledDatePicker, HiddenTextField } from './fragments'
import { DATE_INPUT_TYPES } from 'application/constants'
import { useDateTimeResolver } from './use_date_time_resolver'
import { useRegister } from 'application/components/hooks/use_register'
import { validationMethods } from 'application/common'

const DatePickerComponent = React.forwardRef((
    { 
        onBlur = () => {}, 
        defaultValue, 
        name, 
        onDateChange,
        isReadOnly = false,
        htmlId,
        disabledDays,
        register,
        validate=null,
        dateInputType=DATE_INPUT_TYPES.DATE,
        setValue,
        trigger
    },
    ref
) => {
    const {
        defaultValueResolver,
        targetValueResolver,
        range
    } = useDateTimeResolver(dateInputType, disabledDays)

    const {
        onRegisterChange,
        registerProps
    } = useRegister(register, name, validate)

    const hasNotEmptyProperty = validate !== null && typeof validate !== 'function' 
        ? validate.hasOwnProperty('notEmpty') 
        : false

    return <React.Fragment>
        <HiddenTextField 
            type='text' 
            {...registerProps}
        />
        <input 
            type={dateInputType}
            onChange={(e) => {
                setValue(name, targetValueResolver(e.target.value))
                trigger(name)
                onDateChange(targetValueResolver(e.target.value))
                onRegisterChange(e)
            }}
            defaultValue={defaultValueResolver(defaultValue)}
            readOnly={isReadOnly}
            id={htmlId} 
            onBlur={onBlur}
            ref={ref}
            {...range}
            required={hasNotEmptyProperty}
        />
        
    </React.Fragment>
})

export const DatePicker =  React.forwardRef((
    {
        label = '', 
        name, 
        control, 
        validate = {}, 
        error, 
        onDateChange = () => {}, 
        hideValidationMessage = false, 
        hideValidationBox = false,
        defaultValue = null, 
        errorMessageOverride='',
        isReadOnly = false,
        disabled = false,
        disabledDays = {},
        register,
        updateFormState=()=>{},
        setValue=()=>{},
        trigger=()=>{}
    },
    ref
) => {
    const htmlId = uniqid()

    const resolvedDisabledDays = {
        before: new Date(null), //resolves to Date 1970-01-01T00:00:00.000Z
        ...disabledDays
    }

    const resolvedValidate = {
        ...validate,
        mustBeAfter1970: validationMethods.mustBeAfter1970
    }

    return <StyledDatePicker>
        {
            label !== '' && <label 
                className='label' 
                htmlFor={htmlId}
            >
                {label}
                <RequiredFieldMarker 
                    validate={validate}
                />
            </label>
        }



        <Row>
            {
                disabled 
                
                    ? <DisabledDatePicker>
                        <span></span>
                    </DisabledDatePicker>


                    : <DatePickerComponent 
                        register={register}
                        name={name}
                        control={control}
                        onDateChange={(val)=>{
                            onDateChange(val)
                            updateFormState()
                        }}
                        isReadOnly={isReadOnly}
                        htmlId={htmlId}
                        disabledDays={resolvedDisabledDays}
                        validate={resolvedValidate}
                        defaultValue={defaultValue}
                        ref={ref}
                        setValue={setValue}
                        trigger={trigger}
                    />
            }

            
        </Row>


        <ValidationErrorMessage 
            hideValidationMessage={hideValidationMessage} 
            hideValidationBox={hideValidationBox}
            error={error} 
            errorMessageOverride={errorMessageOverride} 
        />
    </StyledDatePicker>
})