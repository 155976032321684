import { notNull } from 'application/common/data_helpers'
import { CustomerNameDamage } from 'application/components/controls/customer_name_damage'
import { FlexBox } from 'application/components/fragments/flex_box'
import { getAllBranches } from 'application/redux/selectors'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'


export const CustomerOwnerBranchColumn = ({damage}) => {
    const {
        allBranches
    } = useSelector(getAllBranches)

    const branch = allBranches.length > 0 
        ? allBranches.find(
            branch => branch.id === damage.branch.id
        )
        : null

    return <FlexBox flexDirection='column'>
        <CustomerNameDamage damage={damage} />
        <span>
            {
                notNull(branch) 
                    ? branch.name 
                    :   ''
            }
        </span>
    </FlexBox>
}

CustomerOwnerBranchColumn.propTypes = {
    damage: PropTypes.object.isRequired
}
