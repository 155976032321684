import { validationMethods } from 'application/common'
import { exists } from 'application/common/data_helpers'
import { useTranslation } from 'react-i18next'

/**
 * @param {string} name
 * @param {string} label
 * @param {function} watch  - provided by react hook form
 * @param {number} limit
 */
export const useLimitedCharsValidation = (
    name,
    watch,
    label = '',
    limit = 20,
    showNegativeRemainingChars = true
) => {
    const {t} = useTranslation()
    const limitedCharsValidationRules = { 
        limitedChars: validationMethods.limitedChars(limit) 
    }
    const valueStringLength = exists(watch(name)) 
        ? watch(name).length 
        : 0
    const diff = limit - valueStringLength

    const remainingChars =  limit > valueStringLength
        ? diff
        :  showNegativeRemainingChars
            ? diff
            : 0

    const limitedFeedbackLabel = `${t(label)} (${remainingChars} ${t('Characters remain')})`

    return {
        limitedCharsValidationRules,
        remainingChars,
        limitedFeedbackLabel
    }
}