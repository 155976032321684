import { fetchRequestBuilder, fetchRequestBuilderPin, HTTP_METHOD } from 'application/api/request_builders'
import { apiCall } from 'application/api/helpers'

export const getModules = (params) => {
    return {
        authenticated: () => {
            const request = fetchRequestBuilder('modules', HTTP_METHOD.GET)
            return apiCall(request)
        },
        sharedResource: (payload) => {
            const request = fetchRequestBuilderPin(
                `external/${params.sharedResourceType}/${params.sharedResourceId}/modules`, 
                params.pin, 
                HTTP_METHOD.GET, 
                payload
            )
            return apiCall(request)
        },
        kickstart: (payload) => {
            const request = fetchRequestBuilderPin(
                `external/qr/${params.sharedResourceId}/modules`, 
                params.pin, 
                HTTP_METHOD.GET, 
                payload
            )
            return apiCall(request)
        }
    }
}