import { FontIcon } from '@ec/ui-controls-react'
import { MessageListWrapper, WrapperLink } from 'application/components/building_blocks/inbox_refactored/list/fragments'
import { RoutedPagination } from 'application/components/building_blocks/routed_pagination'
import { PureCheckBox } from 'application/components/controls/form/checkboxes/check_box'
import { SingleMessage } from 'application/components/controls/single_message'
import { FlexBox } from 'application/components/fragments/flex_box'
import { useQuery } from 'application/components/hooks'
import { PushBottom16 } from 'application/components/pages/_common'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const MessageList = (
    {
        messages, 
        messagesLoaded,
        messagesTotal,
        selectedMessageId,
        getMessageLink,
        baseUrlParams,
        onUnreadMessageFilterChange,
        getLinkBase = () => null,
        checkIfOutgoing
    }
) => {
    const {t} = useTranslation()
    const query = useQuery()
    const showOnlyUnreadMessagesDefault = query.getStringParam(baseUrlParams.ONLY_UNREAD_MESSAGES, '') !== ''
    
    return <React.Fragment>
        <FlexBox>
            <MessageListWrapper>
                <PureCheckBox 
                    label={t('Only unread messages')}
                    onChange={onUnreadMessageFilterChange}
                    checked={showOnlyUnreadMessagesDefault}
                />
                <PushBottom16 />
                {
                    messagesLoaded && messages.length === 0 && <FlexBox 
                        flexDirection='column' 
                        cssRules='overflow:hidden;'
                        paddingTop='40px'
                    >
                        <FlexBox 
                            marginRight='2%' 
                            marginLeft='2%' 
                            justifyContent='center' 
                        >
                            <FontIcon 
                                icon='attach_email' 
                                fontSize='44px'
                            />
                        </FlexBox>
                        <FlexBox 
                            marginRight='2%' 
                            marginLeft='2%' 
                            justifyContent='center' 
                            alignItems='center'
                        >
                            {t('Inbox is empty')}
                        </FlexBox>
                    </FlexBox>
                }
                {
                    messages.map((m) => {
                        return <WrapperLink
                            key={m.id}
                            to={getMessageLink(m)}
                        >
                            <SingleMessage
                                message={m}
                                isSelected={m.id === selectedMessageId}
                                checkIfOutgoing={checkIfOutgoing}
                            />
                        </WrapperLink>
                    })
                }
                <FlexBox justifyContent='center'>
                    <RoutedPagination
                        totalSize={messagesTotal}
                        itemsPerPage={10}
                        parameterNames={{
                            offset: baseUrlParams.OFFSET,
                            limit: baseUrlParams.LIMIT
                        }}
                        getLinkBase={getLinkBase}
                    />
                </FlexBox>
            </MessageListWrapper>
        </FlexBox>
    </React.Fragment>
}

MessageList.propTypes = {
    selectedMessageId: PropTypes.string,
    messages: PropTypes.array,
}