import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { REASONS_FOR_PAYMENT_REDUCTION } from 'application/constants/payment'
import { validationMethods } from 'application/common'
import { CheckBoxGroup } from '../checkbox_group'
import { exists } from 'application/common/data_helpers'
import { LimitedCharsTextInput } from '../../limited_chars/limited_chars_text_input'






const CheckBoxGroupWithFreetextField = (
    {
        options,
        register,
        errors, 
        setValue,
        trigger,
        watch,
        onDataChange = () => {},
        checkBoxGroupName='checkboxGroup',
        freeTextName='freetextField',
        freeTextConstant=REASONS_FOR_PAYMENT_REDUCTION.OTHER,
        initialFreeTextValue='',
        limit=50
    }
) => {
    const {t} = useTranslation()
    const hasFreetext = exists(initialFreeTextValue)
    const optionsToRender = [
        ...options, 
        {
            label: t(freeTextConstant),
            value: freeTextConstant,
            isInitialChecked: hasFreetext
        }
    ]
    const [freetextFieldSelected, setFreetextFieldSelected] = useState(hasFreetext)

    const handleOnChange = (e, isChecked) => {
        if(e.target.value === freeTextConstant){
            setFreetextFieldSelected(isChecked)
        }
        onDataChange()
    }


    return <React.Fragment>
        <CheckBoxGroup
            options = {optionsToRender}
            register = {register}
            errors = {errors}
            name = {checkBoxGroupName}
            onChange={handleOnChange}
            setValue={setValue}
            trigger={trigger}
        />
        {
            freetextFieldSelected && <LimitedCharsTextInput
                label = {t('Free designation')}
                name = {freeTextName}
                register = {register}
                additionalValidationRules = {{
                    notEmpty: validationMethods.notEmpty
                }}
                limit={limit}
                errors = {errors}
                watch={watch}
                onChange = {onDataChange} 
                defaultValue={initialFreeTextValue}
            /> 
        }
    </React.Fragment>
}

CheckBoxGroupWithFreetextField.propTypes = {
    options: PropTypes.array.isRequired,
    register: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    setValue: PropTypes.func.isRequired,
    watch: PropTypes.func.isRequired,
    trigger: PropTypes.func.isRequired,    
    onDataChange: PropTypes.func,
    checkBoxGroupName: PropTypes.string,
    freeTextName: PropTypes.string,
    freeTextConstant: PropTypes.string,
    initialFreeTextValue: PropTypes.string
}

export { CheckBoxGroupWithFreetextField } 