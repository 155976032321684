import React, {useState} from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from 'application/components/controls/action_button'
import { PhoneNumberInput, TextInput } from 'application/components/controls/form'
import { FlexBox }  from 'application/components/fragments/flex_box'
import { TwoColumns } from 'application/components/forms/_common'
import { YesNoSelect } from 'application/components/controls/form/yes_no_select'
import { validationMethods } from 'application/common'
import { FormHeadline } from 'application/components/fragments/typography'
import { useStatefulForm } from 'application/components/hooks'
import { AddressDataPartialForm } from '../_partial_forms'
import { mapKeeperAsDriver, mapKeeperToCompany} from 'application/api/data_mappers/export/damage'




const DamageOpponentKeeperAndDriverForm = (
    {
        formData = {}, 
        damageId, 
        onSave
    }
) => {
    const {t} = useTranslation()
    
    const keeperIsCompany = formData?.vehicle?.keeper?.isCompany || false 

    const vehicleKeeperIsDriver = keeperIsCompany
        ? false 
        : formData?.vehicleKeeperIsDriver || false

    const [driverFormVisible, setDriverFormVisible] = useState(keeperIsCompany || !vehicleKeeperIsDriver)

    const defaultValues = {
        keeper: {
            firstName: formData?.vehicle?.keeper?.firstName || null,
            lastName: formData?.vehicle?.keeper?.lastName || null,
            isCompany: keeperIsCompany,
            companyName: formData?.vehicle?.keeper?.companyName || null,
            contact: {
                phoneNumber: formData?.vehicle?.keeper?.contact?.phoneNumber || ''
            },
            address: {
                line1: formData?.vehicle?.keeper?.address?.line1 || null,
                postalCode: formData?.vehicle?.keeper?.address?.postalCode || null,
                locality: formData?.vehicle?.keeper?.address?.locality || null,
            }
        },
        driver: {
            firstName: formData?.driver?.firstName || null,
            lastName: formData?.driver?.lastName || null,
            isKeeper: vehicleKeeperIsDriver,
            isCompany: false,
            companyName: null,
            address: {
                line1: formData?.driver?.address?.line1 || null,
                postalCode: formData?.driver?.address?.postalCode || null,
                locality: formData?.driver?.address?.locality || null,
            }
        }
    }
    
    
    const {
        register, 
        errors, 
        handleSubmit, 
        getValues, 
        setValue,
        formStateValues,
        updateFormState,
        control
    } = useStatefulForm({ defaultValues })


    const onSubmitSuccess = () => {
        const formResult = {
            ...getValues,
            ...formStateValues
        }

        const mappedKeeper = formResult.keeper.isCompany 
            ? mapKeeperToCompany(formResult.keeper)
            :formResult.keeper

        const mappedDriver = formResult.driver.isKeeper 
            ? mapKeeperAsDriver(formResult.keeper)
            : formResult.driver

        const damageData = {
            keeper: mappedKeeper,
            driver: mappedDriver
        }

        onSave(
            damageId, 
            damageData
        )
    }

    return <FlexBox flexDirection='column'>
        <TwoColumns 
            columnLeft={
                <YesNoSelect 
                    register={register}
                    setValue={setValue}
                    initialValue={formStateValues.keeper.isCompany ?? false}
                    name='keeper.isCompany'
                    label={t('Keeper is company')}
                    onChange={
                        (option) => {
                            if(option.value){
                                setValue('driver.isKeeper', false)
                                setDriverFormVisible(true)
                            }
                            updateFormState()
                        }
                    }
                />
            }
            columnRight={
                <YesNoSelect 
                    register={register}
                    setValue={setValue}
                    initialValue={formStateValues.driver.isKeeper ?? false}
                    name='driver.isKeeper'
                    label={t('Keeper is driver')}
                    disabled={formStateValues.keeper.isCompany}
                    onChange={(option)=> {
                        updateFormState()
                        setDriverFormVisible(!option.value)
                    }}
                />
            }
        />

        <FormHeadline>
            { 
                formStateValues.driver.isKeeper
                    ? t('Vehicle keeper & driver') 
                    : t('Vehicle keeper')
            }
        </FormHeadline>
        {
            formStateValues.keeper.isCompany 
                ?
                <TextInput 
                    label={t('Company name')}
                    name='keeper.companyName'
                    register={register}
                    validate={{notEmpty: validationMethods.notEmpty}}
                    error={errors.keeper?.companyName}
                    onChange={updateFormState}
                />
                : 
                <React.Fragment>
                    <TwoColumns
                        columnLeft={
                            <TextInput 
                                label={t('First name')}
                                name='keeper.firstName'
                                register={register}
                                validate={{notEmpty: validationMethods.notEmpty}}
                                error={errors.keeper?.firstName}
                                onChange={updateFormState}
                            />
                        }
                        columnRight={
                            <TextInput 
                                label={t('Last name')}
                                name='keeper.lastName'
                                register={register}
                                validate={{notEmpty: validationMethods.notEmpty}}
                                error={errors.keeper?.lastName}
                                onChange={updateFormState}
                            />
                        }
                    />
                </React.Fragment>
        }
        <AddressDataPartialForm
            register={register}
            errorNameStreet={errors.keeper?.address?.line1}
            errorNamePostalCode={errors.keeper?.address?.postalCode}
            errorNameCity={errors.keeper?.address?.locality}
            onChange={updateFormState}
            nameStreet = 'keeper.address.line1'
            namePostalCode = 'keeper.address.postalCode'
            nameCity = 'keeper.address.locality'
            setValue={setValue}
        />
        <PhoneNumberInput
            defaultValue={defaultValues.keeper.contact.phoneNumber}
            validate={null}
            control={control}
            label={t('Phone number')}
            name='keeper.contact.phoneNumber'
            register={register}
            error={errors.keeper?.contact?.phoneNumber}
            onChange={updateFormState}
            setValue={setValue}
            allowLandLine={true}
        />

        {
            driverFormVisible && <React.Fragment>
                <FormHeadline>
                    {t('Driver')}
                </FormHeadline>
                <TwoColumns
                    columnLeft={
                        <TextInput 
                            label={t('First name')}
                            name='driver.firstName'
                            register={register}
                            validate={{notEmpty: validationMethods.notEmpty}}
                            error={errors.driver?.firstName}
                            onChange={updateFormState}
                        />
                    }
                    columnRight={
                        <TextInput 
                            label={t('Last name')}
                            name='driver.lastName'
                            register={register}
                            validate={{notEmpty: validationMethods.notEmpty}}
                            error={errors.driver?.lastName}
                            onChange={updateFormState}
                        />
                    }
                />
                <AddressDataPartialForm 
                    register={register}
                    errors={errors}
                    onChange={updateFormState}
                    nameStreet={'driver.address.line1'}
                    namePostalCode={'driver.address.postalCode'}
                    nameCity={'driver.address.locality'}
                    setValue={setValue}
                /> 
            </React.Fragment>
        } 

        
            
        <FlexBox justifyContent='flex-end'>
            <ActionButton 
                buttonText={t('Save opponent keeper & driver data')} 
                onButtonClick={handleSubmit(onSubmitSuccess)} 
            />
        </FlexBox>
    </FlexBox>
}

export { DamageOpponentKeeperAndDriverForm }