import React from 'react'
import { PushBottom16, PushBottom24, PushBottom8 } from 'application/components/pages/_common'
import { FlexBox } from 'application/components/fragments/flex_box'
import { Headline2 } from 'application/components/fragments/typography'
import {Setting} from './setting'
import { useModules } from 'application/components/hooks/use_modules'

export const SettingsSection = (
    {
        settingsDefinitions,
        submitButton,
        updateSettings,
        getSetting,
        settingsType,
        headline = ''
    }
) => {
    const {
        isAccountModuleApplied,
        isBranchModuleAppliedInAnyBranch
    } = useModules()

    return <React.Fragment>
        <PushBottom24 />
        <Headline2>{headline}</Headline2>
        <PushBottom16 />
        {
            settingsDefinitions
                .filter(d => d.type === settingsType)
                .filter(d => d.editable)
                .filter(d => d.requiredModules.every(m => isAccountModuleApplied(m) || isBranchModuleAppliedInAnyBranch(m)))
                .map(
                    (d, i) => {
                        return <Setting
                            key={i}
                            updateSettings={updateSettings}
                            getSetting={getSetting}
                            definition={d}
                        />
                    }
                )
        }
        <PushBottom8 />
        <FlexBox flexDirection='row'>
            {submitButton}
        </FlexBox>
    </React.Fragment>
}