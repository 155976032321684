import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { ButtonLink } from 'application/components/building_blocks/main_navigation_sidebar/_common/button_link'
import { ForCurrentBranchPermissions } from 'application/components/context/permissions'
import PropTypes from 'prop-types'
import { IconButtonWithLabel } from 'application/components/controls/icon_button_with_label'
import { ROUTES, USER_PERMISSONS } from 'application/constants'
import { addWildCard } from 'application/common/route_helpers'
import { useMatch } from 'react-router-dom'
import { notNull } from 'application/common/data_helpers'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'



export const MenuButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;

    & .open-branch-specific-dialog-button {
        border: 1px solid ${props => props.theme.color.white};
        background: ${props => props.theme.color.primary};
        color: ${props => props.theme.color.white};
        padding: 8px;
    }

    & .open-branch-specific-dialog-button i {
        color: ${props => props.theme.color.white};
    }

    & .open-branch-specific-dialog-button:hover {
        background: ${props => props.theme.color.primaryHover};
    }
`


export const BranchSpecificAccordionMenuComponent = (
    {
        onOpenChangeBranchDialog,
        primaryColor,
        primaryHoverColor,
        currentBranch,
        hasMultipleBranches
    }
) => {
    const { t } = useTranslation()

    const {
        branchLink
    } = useApplicationLinks()

    const matchDamageOverview = notNull(
        useMatch({
            path: addWildCard(ROUTES.BRANCH_SPECIFIC_DAMAGES_OVERVIEW)
        })
    )
    const matchDashBoard = notNull(
        useMatch(ROUTES.BRANCH_SPECIFIC_DASHBOARD)
    )
    const matchTasksOverview = notNull(
        useMatch({
            path: addWildCard(ROUTES.BRANCH_SPECIFIC_TASKS_OVERVIEW)
        })
    )
    const matchInbox = notNull(
        useMatch({
            path: addWildCard(ROUTES.BRANCH_SPECIFIC_INBOX)
        })
    )
    const matchControlling = notNull(
        useMatch({
            path: addWildCard(ROUTES.BRANCH_SPECIFIC_CONTROLLING)
        })
    )
    const matchActivityLog = notNull(
        useMatch(ROUTES.BRANCH_SPECIFIC_ACTIVITY_LOG)
    )

    if (currentBranch === null) {
        return null
    }
    const branchId = currentBranch.id

    return <React.Fragment>
        <ButtonLink
            label={'Overview'}
            pathDestination={
                branchLink(
                    ROUTES.BRANCH_SPECIFIC_DASHBOARD,
                    branchId
                )
            }
            isActive={matchDashBoard}
            iconKey={'widgets'}
            primaryColor={primaryColor}
            primaryHoverColor={primaryHoverColor}
        />
        <ForCurrentBranchPermissions
            permissions={[USER_PERMISSONS.DAMAGE.READ]}
            allNeeded={false}
        >
            <ButtonLink
                label={'Damages'}
                pathDestination={
                    branchLink(
                        ROUTES.BRANCH_SPECIFIC_DAMAGES_OVERVIEW,
                        branchId
                    )
                }
                isActive={matchDamageOverview}
                iconKey={'directions_car'}
                primaryColor={primaryColor}
                primaryHoverColor={primaryHoverColor}
            />
        </ForCurrentBranchPermissions>
        <ForCurrentBranchPermissions
            permissions={[USER_PERMISSONS.TASK.READ]}
            allNeeded={false}
        >
            <ButtonLink
                label={'Tasks'}
                pathDestination={
                    branchLink(
                        ROUTES.BRANCH_SPECIFIC_TASKS_OVERVIEW,
                        branchId
                    )
                }
                isActive={matchTasksOverview}
                iconKey={'description'}
                primaryColor={primaryColor}
                primaryHoverColor={primaryHoverColor}
            />
        </ForCurrentBranchPermissions>
        <ForCurrentBranchPermissions
            permissions={[USER_PERMISSONS.MESSAGE.READ]}
            allNeeded={false}
        >
            <ButtonLink
                label={'Messages'}
                pathDestination={
                    branchLink(
                        ROUTES.BRANCH_SPECIFIC_INBOX,
                        branchId
                    )
                }
                isActive={matchInbox}
                iconKey={'mail_outline'}
                primaryColor={primaryColor}
                primaryHoverColor={primaryHoverColor}
            />
        </ForCurrentBranchPermissions>
        <ForCurrentBranchPermissions
            permissions={[USER_PERMISSONS.CONTROLLING.READ]}
        >
            <ButtonLink
                label={'Controlling'}
                pathDestination={
                    branchLink(
                        ROUTES.BRANCH_SPECIFIC_CONTROLLING,
                        branchId
                    )
                }
                isActive={matchControlling}
                iconKey={'bar_chart'}
                primaryColor={primaryColor}
                primaryHoverColor={primaryHoverColor}
            />
        </ForCurrentBranchPermissions>
        <ButtonLink
            label={'Activities'}
            pathDestination={
                branchLink(
                    ROUTES.BRANCH_SPECIFIC_ACTIVITY_LOG,
                    branchId
                )
            }
            isActive={matchActivityLog}
            iconKey={'list'}
            primaryColor={primaryColor}
            primaryHoverColor={primaryHoverColor}
        />
        {
            hasMultipleBranches && <MenuButtonWrapper>
                <IconButtonWithLabel
                    className={'open-branch-specific-dialog-button'}
                    iconKey='swap_horiz'
                    iconSize='18px'
                    label={t('Change branch')}
                    onButtonClick={onOpenChangeBranchDialog}
                    hoverBackground={primaryHoverColor}
                />
            </MenuButtonWrapper>
        }
    </React.Fragment>
}

BranchSpecificAccordionMenuComponent.propTypes = {
    primaryColor: PropTypes.string.isRequired,
    primaryHoverColor: PropTypes.string.isRequired,
    onOpenChangeBranchDialog: PropTypes.func,
    currentBranch: PropTypes.object,
    hasMultipleBranches: PropTypes.bool
}