import React from 'react'
import {LayoutMain}  from './layout_main'
import * as Common from 'application/components/pages/_common'
import {PageHeadline} from 'application/components/building_blocks/page_headline'
import { useTranslation } from 'react-i18next'
import { exists } from 'application/common/data_helpers'
import { PageTitle } from 'application/components/controls/page_title'
import { withBranches } from 'application/components/higher_order_components'

export const LayoutAllBranches = withBranches((
    {
        headlineGeneric,
        headlineSpecific,
        sidebar = null,
        children,
        headlineChildren = null,
        titleSegments=[],
        mainPaddingLeft='0',
        mainPaddingRight='0',
        branches,
        overflow = 'hidden'
    }
) => {
    const {t} = useTranslation()
    const generic = !exists(headlineGeneric) ? t('All branches') : headlineGeneric

    const hGeneric = branches.length > 1 ? generic : headlineSpecific
    const hSpecific = branches.length > 1 ? headlineSpecific : null

    return <LayoutMain>
        <PageTitle segments={titleSegments} />
        <Common.Container className='commonContainer' $overflow={overflow}>
            <Common.Main
                className='commonMain' 
                paddingLeft={mainPaddingLeft}
                paddingRight={mainPaddingRight}
            >
    
                <PageHeadline 
                    headlineGeneric={hGeneric}
                    headlineSpecific={hSpecific}
                >
                    {
                        headlineChildren
                    }
                </PageHeadline>
                {
                    children
                }
            </Common.Main>
            {
                sidebar
            }
        </Common.Container>
    </LayoutMain>
})