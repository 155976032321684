import { isDefined } from 'application/common/data_helpers'
import { FILTERS, DAMAGE_TYPE, PROVIDED_SERVICES, ACCIDENT_REPORT_DESCRIPTION } from 'application/constants'

export const costCoverageValuesMapping = (
    damage,
    accessibleBranches,
    customer,
    victimInsuranceData,
    opponentInsuranceData,
    expertFound,
    victimVehicle,
    accidentReportDescription = [],
    providedServices = [],
    accidentReportDescriptionOther = '',
    signatures = null,
    providedServicesOther=''
) => {
    const hasAccidentReportType = (typeAsString) => {
            return accidentReportDescription.includes(typeAsString)
        },

        formatDate = (dateISOString) => {
            return isDefined(dateISOString) 
                ? new Date(dateISOString).toLocaleDateString()
                : '' 
        },

        damageRelatedBranch = accessibleBranches.find(
            accessibleBranch => accessibleBranch.id === damage.branchId
        ),

        incidentDate = damage.incidentDate instanceof Date 
            ? damage.incidentDate.toLocaleDateString() 
            : new Date(damage.incidentDate).toLocaleDateString(),

        incidentDateAndTime = `${incidentDate}`,
        partialCompCover = victimInsuranceData?.insurance?.coverage === FILTERS.VALUES.DAMAGES.COVERAGE.PARTIAL,
        fullCompCover = victimInsuranceData?.insurance?.coverage === FILTERS.VALUES.DAMAGES.COVERAGE.FULL,
        coverAmount = (partialCompCover || fullCompCover) ? victimInsuranceData.insurance.deductable : '',
        victimInsuranceName = victimInsuranceData?.insurance?.company?.name || '',
        isLiability = damage.type === DAMAGE_TYPE.LIABILITY

    return {
        incidentDate: incidentDateAndTime, //schadendatum
        insurance:{ //versicherung abhängig von schadentyp
            name: victimInsuranceName,
            fax: '' //empty
        },
        repairShop: { //filialstammdaten
            address: {
                companyName: damageRelatedBranch.name,
                street: `${damageRelatedBranch.address?.line1} ${damageRelatedBranch.address?.line2}`,
                postalCode: damageRelatedBranch.address?.postalCode || '',
                locality: damageRelatedBranch.address?.locality || '',
            },
            contact: {
                fax: '', //empty
                //add phone number / damage e-mail address
                email: damageRelatedBranch.contact?.email || '',
                phoneNumber: damageRelatedBranch.contact?.phoneNumber || '',
            }
        },
        vehicle:{
            keeper: { //geschädigter
                name: customer.isCompany 
                    ? customer.companyName
                    : `${customer.firstName || ''} ${customer.lastName || ''}`
                ,
                address: {
                    street: customer?.address?.line1 || '',
                    postalCode: customer?.address?.postalCode || '',
                    locality: customer?.address?.locality || '',
                },
                dayPhone: '', //empty
                privatPhone: customer?.mobilePhoneNumber || '', //mobile number,
                isEntitledToTaxDeduction: customer?.isEntitledToTaxDeduction ?? false
            },
            comprehensive: {
                partially: partialCompCover,
                partiallyAmount: partialCompCover ?  coverAmount : '', //deductible
                fully: fullCompCover,
                fullyAmount: fullCompCover ?  coverAmount : '', //deductible
            },
            insurance: { //kaskoversicherung des geschädigten
                name: damage.type === DAMAGE_TYPE.COMPREHENSIVE ? victimInsuranceName : ''
            },
            manufacturer: damage.vehicle?.make || '',
            type: damage.vehicle?.model || '',
            licensePlate: damage.vehicle?.licensePlate || '',
            km: victimVehicle?.milage || '', //aktueller tachostand -> ToDo add to vehicle domain and forms
            firstRegistrationDate: formatDate(victimVehicle?.firstRegisteredAt),  //datum erstzulassung -> ToDo add to vehicle domain and forms
            inRepairSince: formatDate(damage?.repairStartedAt), // "in der werkstatt seit" (ist in dem fall autohaus == werkstatt) -> ToDo add to damage domain and forms
            commissioningExpert: expertFound,
        },
        opponentVehicle: {
            keeper: {
                name: isLiability 
                    ? opponentInsuranceData?.vehicle?.keeper?.isCompany || false
                        ? opponentInsuranceData?.vehicle?.keeper?.companyName || ''
                        : `${opponentInsuranceData?.vehicle?.keeper?.firstName || ''} ${opponentInsuranceData?.vehicle?.keeper?.lastName || ''}` 
                    : ''
                ,
                address: {
                    street: isLiability
                        ? opponentInsuranceData?.vehicle?.keeper?.address?.line1 || '' 
                        : ''
                    ,
                    postalCode: isLiability 
                        ? opponentInsuranceData?.vehicle?.keeper?.address?.postalCode || ''
                        : ''
                    ,
                    locality: isLiability
                        ? opponentInsuranceData?.vehicle?.keeper?.address?.locality || ''
                        : ''
                    ,
                },
                dayPhone: '', //empty
                privatPhone: opponentInsuranceData?.vehicle?.keeper?.contact?.phoneNumber ?? '', //empty
                isDeductible: false  
            },
            insurance: {
                name: isLiability 
                    ? opponentInsuranceData?.insurance?.company?.name ||  ''
                    : '',
                address: { //empty
                    street: '', //empty
                    postalCode: '', //empty
                    locality: '' //empty
                },
                contact: {
                    phone: '' //empty
                }
            },
            claimNumber: isLiability 
                ?  opponentInsuranceData?.claim?.number || '' 
                : '',
            licensePlate: isLiability 
                ?  opponentInsuranceData?.vehicle?.licensePlate || ''
                : ''
        },
        damage: {
            //ToDo:
            //Liste von Unfallbeschreibungen incl 'sonstiges' (backend + frontend)
            //Unfallhergang umbenennen in 'ausführlicher Unfallhergang'
            collision: hasAccidentReportType(ACCIDENT_REPORT_DESCRIPTION.REAR_END_COLLISION),
            rightOfWayInfringement: hasAccidentReportType(ACCIDENT_REPORT_DESCRIPTION.RIGHT_OF_WAY_VIOLATION),
            laneChange: hasAccidentReportType(ACCIDENT_REPORT_DESCRIPTION.LANE_CHANGE),
            overTake: hasAccidentReportType(ACCIDENT_REPORT_DESCRIPTION.OVERTAKING),
            parkedCarDemolished: hasAccidentReportType(ACCIDENT_REPORT_DESCRIPTION.DAMAGE_TO_PARKED_VEHICLE),
            offTheRoadway: hasAccidentReportType(ACCIDENT_REPORT_DESCRIPTION.LEAVING_THE_LANE),
            other: {
                isOther: hasAccidentReportType(ACCIDENT_REPORT_DESCRIPTION.OTHER),
                description: hasAccidentReportType(ACCIDENT_REPORT_DESCRIPTION.OTHER) ? accidentReportDescriptionOther : ''
            },
            paymentOrder: {
                flatRateDamage: providedServices.includes(PROVIDED_SERVICES.FLATRATE_DAMAGE), 
                rentalCar: providedServices.includes(PROVIDED_SERVICES.RENTAL_CAR), 
                towing: providedServices.includes(PROVIDED_SERVICES.ACCIDENT_VEHICLE_TOW), 
                mercantileDepreciation: providedServices.includes(PROVIDED_SERVICES.MERCANTILE_DEPRECATION),
                other: {
                    isOther: providedServices.includes(PROVIDED_SERVICES.OTHER),
                    description: providedServices.includes(PROVIDED_SERVICES.OTHER) ? providedServicesOther : '',
                }
            },
            demand: { //B.3 Offenlegung...
                date: new Date().toLocaleDateString() //zeitpunkt der erzeugung der RKÜ
            },
            confirmationText: ''
        }, 
        police: {
            fileNumber: opponentInsuranceData?.policeRecord?.fileNumber || '',
            department: opponentInsuranceData?.policeRecord?.precinct || '',
            reported: opponentInsuranceData?.policeRecord?.fileNumber || opponentInsuranceData?.policeRecord?.precinct //true, wenn entweder Dienststelle oder Aktenzeichen gesetzt sind
        },
        policyholder: { //Rückantwort der Versicherung -> kann raus
            adhesion: {
                full: false,
                other: {
                    isOther: false,
                    value: ''
                },
                unknown: false
            }
        },
        signatures,
        today: new Date().toLocaleDateString()
    }
}