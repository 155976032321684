import { put, takeLeading } from 'redux-saga/effects'
import { ACTIONS } from 'application/constants'
import { api } from 'application/api'
import { handleError } from 'application/redux/saga/errors'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { markMessageAsUnread } from 'application/redux/actions/common/messages'

function* markMessageUnreadSaga(action) {
    try {
        yield resourceCall(api.gateway.messages.deleteMessageReadStatus, action)
        yield put(markMessageAsUnread(action.messageId))
    } catch (e) {
        yield handleError(e, action)
    }
}

export function* markMessageUnreadWatcher() {
    yield takeLeading(ACTIONS.COMMON_SET_MESSAGE_UNREAD_REQUEST, markMessageUnreadSaga)
}