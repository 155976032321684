import { put, takeLeading } from 'redux-saga/effects'
import { ACTIONS } from 'application/constants'
import { api } from 'application/api'
import { handleError } from 'application/redux/saga/errors'
import { showToast } from 'application/redux/actions/notifications'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { opponentKeeperDataMapper, opponentDriverDataMapper} from 'application/api/data_mappers/export/damage'
import { removePropertiesWithEmptyStrings } from 'application/common/object_helpers'



function* updateOpponentKeeperSaga(action) {
    try {
        const payload = opponentKeeperDataMapper(action.damageData)
        yield resourceCall(
            api.gateway.damage.updateOpponentKeeper, 
            {
                damageId: action.damageId
            }, 
            payload
        )
        yield put(showToast('Opponent keeper data saved'))
    } catch (e) {
        yield handleError(e, action)
    }
}


function* updateOpponentKeeperAndDriverSaga(action) {
    try {
        const { 
            keeper,
            driver
        } = action.damageData



        yield resourceCall(
            api.gateway.damage.updateOpponentKeeper, 
            {
                damageId: action.damageId
            }, 
            removePropertiesWithEmptyStrings(keeper)
        )

        yield resourceCall(
            api.gateway.damage.updateOpponentDriver,
            {
                damageId: action.damageId
            }, 
            removePropertiesWithEmptyStrings(driver)
        )
        
        yield put(showToast('Opponent keeper data saved'))
    } catch (e) {
        yield handleError(e, action)
    }
}





function* updateOpponentDriverSaga(action) {
    try {
        const driverPayload = opponentDriverDataMapper(action.damageData)
        yield resourceCall(
            api.gateway.damage.updateOpponentDriver,
            {
                damageId: action.damageId
            }, 
            driverPayload
        )
        yield put(showToast('Opponent driver data saved'))
    } catch (e) {
        yield handleError(e, action)
    }
}

function* updateOpponentVehicleSaga(action) {
    try {
        yield resourceCall(
            api.gateway.damage.updateOpponentVehicle, 
            {
                damageId: action.damageId
            },
            action.damageData
        )
        yield put(showToast('Opponent vehicle data saved'))
    } catch (e) {
        yield handleError(e, action)
    }
}

export function* updateOpponentWatcher() {
    yield takeLeading(ACTIONS.DAMAGE_MANAGER_UPDATE_OPPONENT_KEEPER, updateOpponentKeeperSaga)
    yield takeLeading(ACTIONS.DAMAGE_MANAGER_UPDATE_OPPONENT_DRIVER, updateOpponentDriverSaga)
    yield takeLeading(ACTIONS.DAMAGE_MANAGER_UPDATE_OPPONENT_KEEPER_AND_DRIVER, updateOpponentKeeperAndDriverSaga)
    yield takeLeading(ACTIONS.DAMAGE_MANAGER_UPDATE_OPPONENT_VEHICLE, updateOpponentVehicleSaga)
}