import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'application/components/higher_order_components/with_router'
import { DamageManagerInboxComponent } from './component'
import { ROUTE_PARAMETERS, ROUTES } from 'application/constants'
import { useQuery } from 'application/components/hooks'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'
import { buildQueryString } from 'application/common/route_helpers'
import { useNavigate } from 'react-router-dom'

const DamageManagerInboxEffectsComponent = (props) => {
    const { getMessages, getMessageDetails, match, inbox } = props
    const { damageId, messageId } = match.params
    const navigate = useNavigate()
    const query = useQuery()
    const offset = query.getParsedParam(ROUTE_PARAMETERS.DAMAGE_MANAGER.INBOX.OFFSET, 0)
    const limit = query.getParsedParam(ROUTE_PARAMETERS.DAMAGE_MANAGER.INBOX.LIMIT, 10)
    const onlyUnreadMessages = query.getParsedParam(ROUTE_PARAMETERS.DAMAGE_MANAGER.INBOX.ONLY_UNREAD_MESSAGES, 0) === 1

    useEffect(() => {
        getMessages(damageId, offset, limit, onlyUnreadMessages)
    }, [getMessages, inbox.deletedMessages, damageId, limit, offset, onlyUnreadMessages])

    useEffect(() => {
        if(messageId && damageId){
            getMessageDetails(damageId, messageId)
        }
    }, [damageId, getMessageDetails, messageId])
 
    //handle the unread message filter change
    const {
        damageManagerLink
    } = useApplicationLinks()
    
    const handleUnreadMessageFilterChange = (onlyUnread) => {
        const queryString = buildQueryString(onlyUnread 
            ? [{
                key: ROUTE_PARAMETERS.DAMAGE_MANAGER.INBOX.ONLY_UNREAD_MESSAGES,
                value: 1
            }] : [])
                
        const newUrl = damageManagerLink(ROUTES.DAMAGE_MANAGER_INBOX, damageId) + queryString
        navigate(newUrl)
    }

    return <DamageManagerInboxComponent
        {...props} 
        onUnreadMessageFilterChange={handleUnreadMessageFilterChange}
    />
}

DamageManagerInboxEffectsComponent.propTypes = {
    getMessages: PropTypes.func.isRequired,
}

export const DamageManagerInboxEffects = withRouter(DamageManagerInboxEffectsComponent)