import { WIDGET_TYPES } from 'application/constants'

import {
    ControllingVacantPositions,
    ControllingVacantPositionsDamagesTable,
    ControllingVacantPositionsKasko,
    ControllingVacantPositionsLiability
} from './controlling/vacant_positions'

import { ControllingStatisticsDamageCount } from './controlling/statistics_damage_count'

import {
    CommissionExpert,
    CommissionLawfirm,
    DamageAKAktenStatus,
    DamageAKInvoices,
    DamageAKLiabilityRequest,
    DamageAKReceiptConfirmation,
    DamageAKRepairClearance,
    DamageAccidentReport,
    DamageActivityLog,
    DamageCaseNotes,
    DamageInsurances,
    DamageRepairAuthorizationDisplay,
    DamageReport,
    DamageShareWidget,
    DamageTaskBulletList,
    DamageVehicle,
    DamageVehicleOwner
} from './damage'

import {
    LastRepairAuthorizations,
    LastTasks,
    TableDamagesLastCreated
} from './last'

import {
    DamageRepairStartedAtForm,
    TaskComments,
    TaskDamageAccidentReportForm,
    TaskDamageBasicDataForm,
    TaskDamageCombinedLiabilityForms,
    TaskDamageComprehensiveInsuranceData,
    TaskDamageCreateInvoice,
    TaskDamageLiabilityInsuranceForm,
    TaskDamageOpponentDriverForm,
    TaskDamageOpponentKeeperForm,
    TaskDamageOpponentVehicleForm,
    TaskDamagePoliceDataForm,
    TaskDamageRepairAuthorizationForm,
    TaskDamageUpdateCustomer,
    TaskDamageVehicleDataForm,
    TaskDamageVehicleProprietorForm,
    TaskDamageWitnessDataForm,
    TaskUploaderWidged
} from './task'

import {
    LawfirmAktenStatus,
    LawfirmInsuranceInformation,
    LawfirmInvoices,
    LawfirmLiabilityRequest,
    LawfirmReceiptConfirmation,
    LawfirmRepairClearance,
    LawfirmTaskComments
} from './lawfirm'

import { ControllingGlobalDamagesBarChart } from 'application/components/widgets/controlling/global_damages_bar_chart'
import { TaskDamageRepairSchedule } from 'application/components/widgets/task/damage/repair_schedule'
import { ControllingGlobalVacantPositions } from './controlling/global_vacant_positions_statistics'
import { DefaultWidget } from './default_widget'


export const getWidgetByType = (type, isInternal = true) => {
    switch (type) {
    case WIDGET_TYPES.LAST_TASKS:
        return LastTasks   
    case WIDGET_TYPES.LAST_DAMAGES:
    case WIDGET_TYPES.BRANCH_SPECIFIC_LAST_DAMAGES:
        return TableDamagesLastCreated  
    case WIDGET_TYPES.LAST_REPAIR_AUTHORIZATIONS:
    case WIDGET_TYPES.BRANCH_SPECIFIC_LAST_REPAIR_AUTHORIZATIONS:
        return LastRepairAuthorizations  
    case WIDGET_TYPES.DAMAGE_ACCIDENT_REPORT:
        return DamageAccidentReport
    case WIDGET_TYPES.DAMAGE_INSURANCES:
        return DamageInsurances
    case WIDGET_TYPES.DAMAGE_REPORT:
        return DamageReport
    case WIDGET_TYPES.DAMAGE_VEHICLE:
        return DamageVehicle
    case WIDGET_TYPES.DAMAGE_VEHICLE_OWNER:
        return DamageVehicleOwner
    case WIDGET_TYPES.DAMAGE_ACTIVITY_LOG:
        return DamageActivityLog
    case WIDGET_TYPES.COMMISSION_LAWFIRM:
        return CommissionLawfirm
    case WIDGET_TYPES.COMMISSION_EXPERT:
        return CommissionExpert
    case WIDGET_TYPES.DAMAGE_TASK_BULLET_LIST:
        return DamageTaskBulletList    
    case WIDGET_TYPES.TASK_COMMENTS:
        return TaskComments 
    case WIDGET_TYPES.TASK_UPLOADER:
    case WIDGET_TYPES.TASK_DAMAGE_UPLOADER:
        return TaskUploaderWidged
    case WIDGET_TYPES.TASK_DAMAGE_ACCIDENT_REPORT_DATA:
        return TaskDamageAccidentReportForm
    case WIDGET_TYPES.TASK_DAMAGE_WITNESS_DATA:
        return TaskDamageWitnessDataForm
    case WIDGET_TYPES.TASK_DAMAGE_REPAIR_AUTHORIZATION_FORM:
        return TaskDamageRepairAuthorizationForm
    case WIDGET_TYPES.DAMAGE_CASE_NOTES:
        return DamageCaseNotes
    case WIDGET_TYPES.WORKFLOW_PANEL_TASK_COMMENTS:
        return LawfirmTaskComments
    case WIDGET_TYPES.WORKFLOW_PANEL_TASK_UPLOADER:
        return TaskUploaderWidged
    case WIDGET_TYPES.WORKFLOW_PANEL_FORM_AKTEN_STATUS:
        return LawfirmAktenStatus
    case WIDGET_TYPES.WORKFLOW_PANEL_FORM_RECEIPT_CONFIRMATION:
        return LawfirmReceiptConfirmation
    case WIDGET_TYPES.WORKFLOW_PANEL_FORM_INSURANCE_INFORMATION:
        return LawfirmInsuranceInformation
    case WIDGET_TYPES.WORKFLOW_PANEL_FORM_LIABILITY_REQUEST:
        return LawfirmLiabilityRequest
    case WIDGET_TYPES.WORKFLOW_PANEL_FORM_REPAIR_CLEARANCE:
        return LawfirmRepairClearance
    case WIDGET_TYPES.WORKFLOW_PANEL_FORM_INVOICES:
        return LawfirmInvoices
    case WIDGET_TYPES.DAMAGE_AK_RECEIPT_CONFIRMATION:
        return DamageAKReceiptConfirmation
    case WIDGET_TYPES.DAMAGE_AK_LIABILITY_REQUEST:
        return DamageAKLiabilityRequest
    case WIDGET_TYPES.DAMAGE_AK_AKTEN_STATUS:
        return DamageAKAktenStatus
    case WIDGET_TYPES.TASK_DAMAGE_INVOICES:
    case WIDGET_TYPES.DAMAGE_AK_INVOICES:
    case WIDGET_TYPES.DAMAGE_MANAGER_INVOICES_LIST:
        return DamageAKInvoices
    case WIDGET_TYPES.TASK_DAMAGE_CREATE_INVOICE:
    case WIDGET_TYPES.DAMAGE_MANAGER_CREATE_INVOICE:
        return TaskDamageCreateInvoice
    case WIDGET_TYPES.DAMAGE_AK_REPAIR_CLEARANCE:
        return DamageAKRepairClearance
    case WIDGET_TYPES.DAMAGE_SHARE:
        return DamageShareWidget
    case WIDGET_TYPES.CONTROLLING_VACANT_POSTIONS_GESAMT:
    case WIDGET_TYPES.BRANCH_SPECIFIC_CONTROLLING_VACANT_POSTIONS_GESAMT:
        return ControllingVacantPositions
    case WIDGET_TYPES.CONTROLLING_VACANT_POSTIONS_HAFTPFLICHT:
    case WIDGET_TYPES.BRANCH_SPECIFIC_CONTROLLING_VACANT_POSTIONS_HAFTPFLICHT:
        return ControllingVacantPositionsLiability
    case WIDGET_TYPES.CONTROLLING_VACANT_POSTIONS_KASKO:
    case WIDGET_TYPES.BRANCH_SPECIFIC_CONTROLLING_VACANT_POSTIONS_KASKO:
        return ControllingVacantPositionsKasko
    case WIDGET_TYPES.CONTROLLING_VACANT_POSTIONS_DAMAGES_TABLE:
    case WIDGET_TYPES.BRANCH_SPECIFIC_CONTROLLING_VACANT_POSTIONS_DAMAGES_TABLE:
        return ControllingVacantPositionsDamagesTable
    case WIDGET_TYPES.CONTROLLING_STATISTICS_DAMAGE_COUNT:
    case WIDGET_TYPES.BRANCH_SPECIFIC_CONTROLLING_STATISTICS_DAMAGE_COUNT:
        return ControllingStatisticsDamageCount
    case WIDGET_TYPES.CONTROLLING_GLOBAL_VACANT_POSITIONS:
        return ControllingGlobalVacantPositions
    case WIDGET_TYPES.CONTROLLING_GLOBAL_DAMAGES_BAR_CHART:
        return ControllingGlobalDamagesBarChart
    case WIDGET_TYPES.TASK_DAMAGE_BASIC_DATA_FORM:
        return TaskDamageBasicDataForm
    case WIDGET_TYPES.TASK_DAMAGE_VEHICLE_DATA_FORM:
        return TaskDamageVehicleDataForm
    case WIDGET_TYPES.TASK_DAMAGE_UPDATE_CUSTOMER:
        return TaskDamageUpdateCustomer
    case WIDGET_TYPES.TASK_DAMAGE_COMPREHENSIVE_INSURANCE_DATA:
        return TaskDamageComprehensiveInsuranceData
    case WIDGET_TYPES.TASK_DAMAGE_POLICE_DATA:
        return TaskDamagePoliceDataForm
    case WIDGET_TYPES.TASK_DAMAGE_OPPONENT_DRIVER_DATA: 
        return TaskDamageOpponentDriverForm
    case WIDGET_TYPES.TASK_DAMAGE_OPPONENT_VEHICLE_DATA:
        return TaskDamageOpponentVehicleForm
    case WIDGET_TYPES.TASK_DAMAGE_OPPONENT_KEEPER_DATA:
        return TaskDamageOpponentKeeperForm
    case WIDGET_TYPES.TASK_DAMAGE_LIABILITY_INSURANCE_DATA:
        return TaskDamageLiabilityInsuranceForm
    case WIDGET_TYPES.TASK_DAMAGE_VEHICLE_PROPRIETOR_DATA:
        return TaskDamageVehicleProprietorForm
    case WIDGET_TYPES.TASK_DAMAGE_COMBINED_LIABILITY_DATA:
        return TaskDamageCombinedLiabilityForms
    case WIDGET_TYPES.TASK_DAMAGE_REPAIR_STARTED_AT:
        return DamageRepairStartedAtForm
    case WIDGET_TYPES.DAMAGE_REPAIR_AUTHORIZATION_DISPLAY:
        return DamageRepairAuthorizationDisplay
    case WIDGET_TYPES.TASK_DAMAGE_REPAIR_SCHEDULE:
        return TaskDamageRepairSchedule
    default:
        return DefaultWidget
    }
}