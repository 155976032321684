export const downloadFile = (url, fileName) => {
    var a = document.createElement('a')
    a.href = url
    a.download = fileName
    document.body.appendChild(a) 
    a.click()    
    a.remove()
}

export const downloadFilePost = (url, fileName, hiddenFields) => {
    var f = document.createElement('form')
    f.setAttribute('method','post')
    f.setAttribute('action', url)
    f.setAttribute('target', '_blank')
    f.setAttribute('enctype', 'application/x-www-form-urlencoded')

    // var name = document.createElement('input') 
    // name.setAttribute('type','hidden')
    // name.setAttribute('name', 'fileName')
    // name.setAttribute('value', fileName)

    var s = document.createElement('input') //input element, Submit button
    s.setAttribute('type','submit')
    s.setAttribute('value','Submit')

    for (let i = 0; i < hiddenFields.length; i++) {
        let h = document.createElement('input') 
        h.setAttribute('type', 'hidden')
        h.setAttribute('name', hiddenFields[i].key)
        h.setAttribute('value', hiddenFields[i].value)
        f.appendChild(h)
    }

    f.appendChild(s)
    document.body.appendChild(f) 

    f.submit()
    f.remove()
}

export const getDownloadUrl = (blob) => {
    return window.URL.createObjectURL(blob)
}