import { ACTIONS } from 'application/constants'

export const getDamageManagerMessagesListRequest = (damageId, offset, limit, onlyUnreadMessages) => {
    return {
        type: ACTIONS.INBOX_DAMAGE_MANAGER_GET_MESSAGES_REQUEST,
        damageId, offset, limit, onlyUnreadMessages
    }
}

export const populateDamageManagerMessagesList = (messages, messagesTotal) => {
    return {
        type: ACTIONS.INBOX_DAMAGE_MANAGER_POPULATE_MESSAGES,
        messages, messagesTotal
    }
}

export const updateDamageManagerDeletedMessages = (messageId) => {
    return {
        type: ACTIONS.INBOX_DAMAGE_MANAGER_UPDATE_DELETED_MESSAGES,
        messageId
    }
}

export const resetDamageManagerMessageDetails = () => {
    return {
        type: ACTIONS.INBOX_DAMAGE_MANAGER_RESET_MESSAGE_DETAILS
    }
}

export const getDamageManagerMessageByIdRequest = (damageId, messageId) => {
    return {
        type: ACTIONS.INBOX_DAMAGE_MANAGER_GET_MESSAGE_BY_ID,
        damageId, messageId
    }
}


export const populateDamageManagerMessageDetails = (messageDetails) => ({
    type: ACTIONS.INBOX_DAMAGE_MANAGER_POPULATE_MESSAGE_DETAILS,
    messageDetails,
})
