import { FontIcon } from '@ec/ui-controls-react'
import { exists } from 'application/common/data_helpers'
import { DateComponent } from 'application/components/controls/date'
import { MessageSubject } from 'application/components/controls/message_subject'
import { FlexBox } from 'application/components/fragments/flex_box'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import * as Fragments from './fragments'



export const SingleMessage = withTheme(({ 
    message, 
    isSelected, 
    checkIfOutgoing = () => false,
    theme
}) => {
    const {t} = useTranslation()
    const documentsCount = message.documents?.total || 0
    const isRead = (exists(message.readStatus) || message.markedAsRead) && !message.markedAsUnread
    const isOutgoing = checkIfOutgoing(message)
    
    return <Fragments.MessageWrapper $isSelected={isSelected} $isRead={isRead || isOutgoing}>
        <Fragments.MessageSubjectAndAttachmentWrapper attachment={documentsCount > 0} >
            <FlexBox alignItems='center' cssRules='gap: 4px;cursor: pointer;'>
                {
                    isOutgoing 
                        ? <FontIcon isClickable={true} title={t('Outgoing')} icon='outgoing_mail' fontSize={theme.fontSize.medium} color={theme.color.warning}/> 
                        : isRead
                            ? <FlexBox width='16px'/>
                            : <FontIcon isClickable={true} title={t('Not read')} icon='circle' fontSize={theme.fontSize.medium} color={theme.color.success}/>
                }
                <MessageSubject subject={message.subject} isRead={isRead || isOutgoing}/>
            </FlexBox>
            <Fragments.MessageAttachment>
                {documentsCount > 0 && <React.Fragment><FontIcon icon='attach_file' fontSize={theme.fontSize.medium}/> {documentsCount} </React.Fragment>}
            </Fragments.MessageAttachment>
        </Fragments.MessageSubjectAndAttachmentWrapper>
        <Fragments.MessageSenderAndTimeWrapper>
            <Fragments.MessageSender>
                {message.sender.name}
            </Fragments.MessageSender>
            <Fragments.MessageTime>
                <DateComponent date={message.createdAt} />
            </Fragments.MessageTime>
        </Fragments.MessageSenderAndTimeWrapper>
    </Fragments.MessageWrapper>
})