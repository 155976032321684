import { api } from 'application/api'
import { ACTIONS, MESSAGE_ORIGIN } from 'application/constants'
import { updateDeletedMessages } from 'application/redux/actions/pages/inbox'
import { handleError } from 'application/redux/saga/errors'
import { 
    // call, 
    put, 
    takeEvery
} from 'redux-saga/effects'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { updateBranchDeletedMessages } from 'application/redux/actions/pages/inbox/branch_inbox'
import { populateDamageManagerMessageDetails, updateDamageManagerDeletedMessages } from 'application/redux/actions/pages/damage_manager/inbox'
import { updateWfpDeletedMessages } from 'application/redux/actions/pages/workflow_panel/inbox'






function* getDamageManagerMessageDetailsRequestSaga(action) {
    try {

        const response = yield resourceCall(
            api.gateway.messages.getDamageManagerMessageDetailsById, 
            {
                damageId: action.damageId, 
                messageId: action.messageId
            }
        )
        yield put(
            populateDamageManagerMessageDetails(response)
        )

        //only mark as read if it's not already read before
        //don't mark as read if the message is from ESY360
        if(!response.readStatus && response.origin !== MESSAGE_ORIGIN.ESY360){
            yield resourceCall(
                api.gateway.messages.putMessageReadStatus, 
                {
                    damageId: action.damageId, 
                    messageId: action.messageId
                }
            )
        }
    } catch (e) {
        if (e.response.status === 404){
            yield put(updateDeletedMessages(action.messageId))
            yield put(updateBranchDeletedMessages(action.messageId))
            yield put(updateDamageManagerDeletedMessages(action.messageId))
            yield put(updateWfpDeletedMessages(action.messageId))
        }
        handleError(e, action)
    }
}

export function* damageManagerMessageDetailsWatcher() {
    yield takeEvery(
        ACTIONS.INBOX_DAMAGE_MANAGER_GET_MESSAGE_BY_ID, 
        getDamageManagerMessageDetailsRequestSaga
    )
}