import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'application/components/controls/hyperlink'
import * as Fragments from 'application/components/controls/notification_card/meta_content/fragments'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'

/**
     * {
    "notificationType": "ContactInfoUpdatedNotification",
    "payload": {
        "subject": {
            "id": "173438717705494272",
            "parent": null
        },
        "damage": {
            "id": "173438717705494272",
            "vehicle": {
                "licensePlate": "KICK-START-3"
            }
        },
        "branchId": "137937948040495105",
        "oldContactInfo": {
            "name": "test ",
            "phoneNumber": "+491733179318"
        },
        "newContactInfo": {
            "name": "test sadf",
            "phoneNumber": "+491733179318"
        },
        "actor": {
            "userId": null,
            "fullName": "Schuessler Lukas"
        },
        "updatedAt": "2023-04-12T10:56:54.9996276Z"
    }
}
     *
     */

export const ContactInfoUpdatedMetaContent = (
    {
        notification,
        onLinkClick,
        inline
    }
) => {
    const {t} = useTranslation()
    const {payload} = notification

    const {
        internalAccountLink
    } = useApplicationLinks()

    const damageLink = internalAccountLink(
        `/damages/${payload.damage.id}`
    )
    const {
        name,
        phoneNumber
    } = payload.newContactInfo

    return <Fragments.MetaContentContainer inline={inline}>
        <Fragments.ActivityDescription
            label='Damage'
            value={payload.damage.vehicle.licensePlate}
        />
        <Fragments.ActivityDescription
            label='Contact person'
            value={name}
        />
        <Fragments.ActivityDescription
            label='Phone number (mobile or landline)'
            value={phoneNumber}
        />
        <Fragments.ActivityLink inline={inline}>
            <Link
                onClick={onLinkClick}
                to={damageLink}
            >
                {t('jump to damage')}
            </Link>
        </Fragments.ActivityLink>
    </Fragments.MetaContentContainer>
}