import { put, takeLatest } from 'redux-saga/effects'
import { api } from 'application/api'
import { ACTIONS } from 'application/constants'
import { handleError } from 'application/redux/saga/errors'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'
import { populateLawfirms } from 'application/redux/actions/lawfirms'
import { CONTRACTOR_TYPE } from 'application/constants/contractors'

function* getLawfirmsSaga(action) {
    try {        
        const contractorsResponse = yield resourceCall(
            api.gateway.contractors.getBranchContractors,
            {
                branchId: action.branchId
            }
        )
        
        const webakteAccounts = contractorsResponse.allowedContractors.filter(c => c.type === CONTRACTOR_TYPE.LAWYER).map(c => ({
            ...c,
            webakteId: c.externalId ?? c.id
        }))
         
        yield put(
            populateLawfirms(
                webakteAccounts
            )
        )
    } catch (e) {
        yield handleError(e)
    }
}

export function* getLawfirmsWatcher() {
    yield takeLatest(
        ACTIONS.GET_LAWFIRMS_FOR_ACCOUNT_REQUEST, 
        getLawfirmsSaga
    )
}