import React, {useEffect} from 'react'
import { useWatch } from 'react-hook-form'
import { FORM } from './util'


const SwitchFilter = ({ observed, children }) => {
    // filter out only children with a matching prop
    const matchedSection =  children.find(child => {
        return child.props.observed === observed
    })
    return matchedSection?.props?.children || null
}

export const Switch = ({ description, formHook, buildElement }) => {
    const {
        control,
        unregister
    } = formHook

    const observe = useWatch({
        control: control,
        name: description.observe
    })

    const mappedObserved = observe === null || observe === undefined ? false : observe

    useEffect(() => {
        // Unregister fields that are "not observed" or unmounted to avoid their validation checks
        description.sections.forEach(section => {
            if (section.observed !== mappedObserved) {
                section.descriptions.forEach(innerDesc => {
                    if (innerDesc.type === FORM.INSURANCE_FIELDS) {
                        unregister(innerDesc.nameText)
                        unregister(innerDesc.nameSelect)
                    } else {
                        unregister(innerDesc.name)
                    }
                })
            }
        })
    }, [mappedObserved, description.sections, unregister])

    return <SwitchFilter observed={mappedObserved}>
        {description.sections.map((section) => {
            return <div key={`item-${section.observed}`} observed={section.observed} style={description._config?.css}>
                {section.descriptions.map(innerDesc => buildElement(innerDesc, formHook))}
            </div>
        })}
    </SwitchFilter>
}
