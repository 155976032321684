import React from 'react'
import { ValidationErrorMessage } from 'application/components/controls/form/_common'
import { FormElementWrapper } from '../../_common'
import { PureCheckBox } from './pure_component'

export const CheckBox = (
    { 
        label, 
        name, 
        register, 
        validate = {}, 
        error, 
        hideValidationMessage = false, 
        checked=false, 
        errorMessageOverride='', 
        onChange=()=>{},
        setValue=()=>{}
    }
) => {

    const {
        onChange: onRegisterChange, //intercept react-hook-form register onChange method to combine it with our own
        ...registerProps
    } = register(name, {validate})
    
    return <FormElementWrapper>
        <PureCheckBox 
            checked={checked} 
            onChange={(val => {
                setValue(name, val)
                onChange(val)
                onRegisterChange(val)
            })} 
            label={label}
            {...registerProps}
        />
        <ValidationErrorMessage 
            hideValidationMessage={hideValidationMessage} 
            error={error} 
            errorMessageOverride={errorMessageOverride} 
        />
    </FormElementWrapper>
}