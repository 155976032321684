import { api } from 'application/api'
import { resourceCall } from 'application/redux/saga/helper/resource_call_decorator'

export function* kickstartUploadFilesSubSaga (files, createDamageResponse, lawfirm) {
    let documentIds = []

    if(files.length > 0){
        for(const file of files){
            const documentResponse = yield resourceCall(
                api.gateway.attachments.postAttachment, 
                {
                    damageId: createDamageResponse.id
                },
                {
                    file: file.metaData
                }
            )

            documentIds.push(documentResponse.id)
        }
    }else{
        yield
    }

    return documentIds
}