import { compose } from 'redux'
import { connect } from 'react-redux'
import { EditKickstartDefaultLawfirmsComponent } from './component'
import { withBranches } from 'application/components/higher_order_components'

const mapStateToProps = state => ({
    branchContractorsList: state.contractors.branchContractorsList
})

export const EditKickstartDefaultLawfirms =  compose(
    connect(
        mapStateToProps
    ),
    withBranches
)(EditKickstartDefaultLawfirmsComponent)
