import { connect } from 'react-redux'
import { MassOperationsDocumentSelect as MoDocumentComponent } from './component'
import { openDialogByName } from 'application/redux/actions/dialogs'
import { downloadAttachmentsAsZip } from 'application/redux/actions/attachments'


const mapStateToProps = (state) => {
    return {
        categories: state.common.categories,
        permissions: state.auth.permissions,
        damage: state.pages.damageManager.shared.damage,
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onOpenMoDialog: (
            dialogName,
            initiallyLoaded,
            payload,
            dialogTitle
        ) => {

            dispatch(
                openDialogByName(
                    dialogName,
                    initiallyLoaded,
                    payload,
                    dialogTitle
                )
            )
        },
        downloadAttachments: (
            damageId,
            documentIds,
            fileName
        ) => dispatch(
            downloadAttachmentsAsZip(
                documentIds,
                damageId,
                fileName
            )
        ),
    }
}

export const MassOperationsDocumentSelect = connect(
    mapStateToProps,
    mapDispatchToProps
)(MoDocumentComponent) 