import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Fragments from 'application/components/controls/notification_card/meta_content/fragments'
import { Link } from 'application/components/controls/hyperlink'
import { useApplicationLinks } from 'application/components/hooks/use_application_links'



export const LiabilityInquiryUpdatedMetaContent = (
    {
        notification,
        inline,

        onLinkClick
    }
) => {
    const { t } = useTranslation()

    const {
        newLiabilityInquiry,
        damage
    } = notification.payload

    const {
        status = '',
        info = ''
    } = newLiabilityInquiry

    const {
        internalAccountLink
    } = useApplicationLinks()

    const damageLink = internalAccountLink(
        `/damages/${damage.id}`
    )

    return <Fragments.MetaContentContainer inline={inline}>
        <Fragments.ActivityDescription
            label='Damage'
            value={damage.vehicle.licensePlate}
        />
        {
            status !== '' && <Fragments.ActivityDescription
                label='Liability type'
                value={t(status)}
            />
        }
        {
            info !== '' && <Fragments.ActivityDescription
                label='Liability request type'
                value={t(info)}
            />
        }
        <Fragments.ActivityLink inline={inline}>
            <Link
                onClick={onLinkClick}
                to={damageLink}
            >
                {t('jump to damage')}
            </Link>
        </Fragments.ActivityLink>
    </Fragments.MetaContentContainer>
}