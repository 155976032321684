import { MessageList } from 'application/components/building_blocks/inbox_refactored/list'
import { NoMessageInfo } from 'application/components/building_blocks/inbox_refactored/no_message_info'
import { FlexBox } from 'application/components/fragments/flex_box'

export const Inbox = ({ 
    messages,
    messagesLoaded,
    baseUrlParams,
    messagesTotal,
    onPaginationChange,
    getMessageLink,
    selectedMessageId,
    getLinkBase = () => null,
    showDetails = true,
    showNoMessageInfo = false,
    onUnreadMessageFilterChange,
    checkIfOutgoing,
    children //message details go here
}) => {
    return <FlexBox
        alignItems='flex-start'
    >
        <FlexBox 
            flexGrow='0' 
            flexShrink='0' 
            flexBasis='360px'
        >
            <MessageList
                messages={messages}
                selectedMessageId={selectedMessageId}
                baseUrlParams={baseUrlParams}
                messagesTotal={messagesTotal}
                onPaginationChange={onPaginationChange}
                getMessageLink={getMessageLink}
                messagesLoaded={messagesLoaded}
                onUnreadMessageFilterChange={onUnreadMessageFilterChange}
                getLinkBase={getLinkBase}
                checkIfOutgoing={checkIfOutgoing}
            />
        </FlexBox>
        <FlexBox> 
            {
                showNoMessageInfo && <NoMessageInfo />
            }
            {
                showDetails && children
            }
        </FlexBox>
    </FlexBox>

}