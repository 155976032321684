import { ACTIONS } from 'application/constants'
import { defaultStore } from 'application/redux/reducers/default_store'

export const sharedReducer = (state = defaultStore.pages.damageManager.shared, action) => {
    switch (action.type) {
    case ACTIONS.RESET_ACCOUNT_RELATED_DATA: {
        return defaultStore.pages.damageManager.shared
    }
    case ACTIONS.DAMAGE_MANAGER_REQUEST_DAMAGE: {
        return {
            ...state,
            damageLoaded: false,
            damageLoading: true,
        }
    }
    case ACTIONS.DAMAGE_MANAGER_POPULATE_DAMAGE: {
        return {
            ...state,
            damage: action.damage,
            branchInfo: action.branchInfo,
            damageLoaded: true,
            damageLoading: false,
        }
    }
    case ACTIONS.DAMAGE_MANAGER_SHARED_RESET_DAMAGE: {
        return {
            ...state,
            damage: null,
            damageLoaded: false,
            damageLoading: false,
        }
    }
    case ACTIONS.DAMAGE_MANAGER_POPULATE_SHARED_LAWFIRM: {
        return {
            ...state,
            hasLawfirm: action.hasLawfirm,
            lawfirmLoaded: true,
            lawfirm: action.lawfirm
        }
    }

    case ACTIONS.DAMAGE_MANAGER_SHARED_POPULATE_VEHICLE:
        return {
            ...state,
            damage: {
                ...state.damage,
                vehicle: {
                    ...state.damage.vehicle,
                    ...action.vehicleData
                }
            }
        }
    case ACTIONS.DAMAGE_MANAGER_POPULATE_EXPERT: {
        return {
            ...state,
            expertFound: action.expertFound
        }
    }
    case ACTIONS.DAMAGE_MANAGER_SHARED_SYNC_VEHICLE_KEEPER: {
        return {
            ...state,
            damage: {
                ...state.damage,
                vehicle: {
                    ...state.damage.vehicle,
                    keeper: action.vehicleKeeper
                }
            }
        }
    }
    case ACTIONS.DAMAGE_MANAGER_SYNC_DAMAGE_ASSIGNEE: {
        return {
            ...state,
            damage: {
                ...state.damage,
                assignee: {
                    ...state.damage.assignee,
                    userId: action.userId,
                    firstName: action.firstName,
                    lastName: action.lastName
                }
            }
        }
    }
    default:
        return state
    }
}