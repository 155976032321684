import { ACTIONS } from 'application/constants'

export const getThumbnailById = (id, attachmentName, metaData) => ({
    type: ACTIONS.ATTACHMENTS_GET_THUMBNAIL_REQUEST,
    id,
    attachmentName,
    metaData
})

export const getFullAttachmentById = (id, attachmentName, metaData) => ({
    type: ACTIONS.ATTACHMENTS_GET_FULL_ATTACHMENT_REQUEST,
    id,
    attachmentName,
    metaData
})

export const getAttachmentMeta = (id, attachmentName, metaData) => ({
    type: ACTIONS.ATTACHMENTS_GET_META_REQUEST,
    id,
    attachmentName,
    metaData
})

export const populateThumbnail = (id, name, data, loadingFailed = false) => ({
    type: ACTIONS.ATTACHMENTS_POPULATE_THUMBNAIL,
    id,
    name,
    data,
    loadingFailed
})

export const populateFullAttachment = (id, name, data) => ({
    type: ACTIONS.ATTACHMENTS_POPULATE_FULL_ATTACHMENT,
    id,
    name,
    data
})

export const populateAttachmentMeta = (id, name, meta, isLoaded=false) => ({
    type: ACTIONS.ATTACHMENTS_POPULATE_META,
    id,
    name,
    meta,
    isLoaded
})

export const downloadAttachment = (id, attachmentName, damageId) => ({
    type: ACTIONS.DOWNLOAD_ATTACHMENT_BY_ID_REQUEST,
    id,
    attachmentName,
    damageId
})

export const downloadAttachmentsAsZip = (documentIds, damageId, fileName) => ({
    type: ACTIONS.DOWNLOAD_ATTACHMENT_AS_ZIP_REQUEST,
    documentIds,
    damageId,
    fileName
})

export const downloadDamageDocuments = (damageId, attachmentName) => ({
    type: ACTIONS.DOCUMENTS_DOWNLOAD_DAMAGE_DOCUMENTS,
    damageId,
    attachmentName
})

export const downloadTaskDocuments = (damageId, taskId) => ({
    type: ACTIONS.DOCUMENTS_DOWNLOAD_TASK_DOCUMENTS,
    damageId,
    taskId
})

export const deleteAttachment = (attachmentId) => ({
    type: ACTIONS.ATTACHMENTS_DELETE_ATTACHMENT,
    attachmentId
})